import type { Key } from 'react-aria-components';
import { Tabs as AriaTabs } from 'react-aria-components';
import type { ReactElement, ReactNode } from 'react';
import cx from 'clsx';
import styles from './tabs.strict-module.css';

export interface TabsProps {
  children?: ReactNode;
  changeTab?: (key: Key) => void;
  activeId?: string;
  defaultActiveId?: string;
  className?: string;
}

export function Tabs({
  children,
  activeId,
  defaultActiveId,
  changeTab,
  className,
}: TabsProps): ReactElement {
  return (
    <AriaTabs
      className={cx(styles.tabs, 'body-1', className)}
      defaultSelectedKey={defaultActiveId}
      onSelectionChange={changeTab}
      selectedKey={activeId}
    >
      {children}
    </AriaTabs>
  );
}
