import { useIntl } from 'react-intl';

export function useFormatBytes(size: number): string {
  const { formatMessage } = useIntl();
  let divider: number;
  let unit: string;
  if (size < 1e6) {
    divider = 1e3;
    unit = formatMessage({ id: 'kilobyte' });
  } else {
    divider = 1e6;
    unit = formatMessage({ id: 'megabyte' });
  }

  return `${Math.floor((size / divider) * 100) / 100} ${unit}`;
}
