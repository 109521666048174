import { useToast } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { SCA_ENFORCEMENT_STATUS, ScaEnforcementModal } from '../components';
import { useEnforcementStatus, useMutationSendSms } from '../api/sca-enforcement';
import type { ErrorComponentProps } from './sensitive-action';

export function ScaEnforcementErrorUI({
  onClose,
  errorDetails,
  onSensitiveAction,
}: ErrorComponentProps): JSX.Element {
  const { mutateAsync: sendSms } = useMutationSendSms();
  const { formatMessage } = useIntl();
  const { showToast } = useToast();
  const { data: scaEnforcementStatus } = useEnforcementStatus({
    token: errorDetails.scaEnforcementToken as string,
    onSensitiveAction,
  });
  const handleSms = async (): Promise<void> => {
    await sendSms(
      { token: errorDetails.scaEnforcementToken as string },
      {
        onSuccess: () => {
          showToast({
            text: formatMessage({ id: 'sca.enforcement.sms-toast-success' }),
            type: 'success',
          });
        },
      }
    );
  };

  return (
    <ScaEnforcementModal
      errorDetails={errorDetails}
      onClose={onClose}
      onSms={handleSms}
      status={scaEnforcementStatus?.sca_enrollment_status || SCA_ENFORCEMENT_STATUS.NOT_STARTED}
    />
  );
}
