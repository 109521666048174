import cx from 'clsx';
import type { ReactElement } from 'react';
import { forwardRef } from 'react';
import type { CalendarProps as AriaCalendarProps, DateValue } from 'react-aria-components';
import {
  Calendar as AriaCalendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
} from 'react-aria-components';
import { IconChevronLeftOutlined, IconChevronRightOutlined } from '@repo/monochrome-icons';
import { Button } from '../../button';
import styles from './calendar.strict-module.css';
import { CalendarClearValue } from './clear';
import { CalendarHeadingSelect } from './heading';

interface CalendarProps<T extends DateValue> extends AriaCalendarProps<T> {
  maxYear?: DateValue['year'];
  minYear?: DateValue['year'];
}

export function Calendar<T extends DateValue>({
  minYear,
  maxYear,
  ...props
}: CalendarProps<T>): ReactElement {
  const Heading = forwardRef(CalendarHeadingSelect);

  return (
    <AriaCalendar className={styles.calendar} {...props} data-test-calendar>
      <header className={styles.header}>
        <Button iconOnly size="small" slot="previous" variant="tertiary">
          <IconChevronLeftOutlined className={styles['icon-chevron']} />
        </Button>
        <Heading
          className={cx('body-1', styles.heading)}
          data-test-header
          maxYear={maxYear}
          minYear={minYear}
        />
        <Button iconOnly size="small" slot="next" variant="tertiary">
          <IconChevronRightOutlined className={styles['icon-chevron']} />
        </Button>
      </header>
      <CalendarGrid className={styles.grid} weekdayStyle="short">
        <CalendarGridHeader>
          {day => (
            <CalendarHeaderCell className={cx('caption', styles['header-cell'])}>
              {day}
            </CalendarHeaderCell>
          )}
        </CalendarGridHeader>
        <CalendarGridBody>
          {date => (
            <CalendarCell className={cx('overlay', 'body-1', styles['body-cell'])} date={date} />
          )}
        </CalendarGridBody>
      </CalendarGrid>
      <CalendarClearValue />
    </AriaCalendar>
  );
}
