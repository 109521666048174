import type { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import cx from 'clsx';
import { IconLockedFilled } from '@repo/monochrome-icons';
import styles from './browser-preview.strict-module.css';

interface BrowserPreviewProps extends PropsWithChildren {
  address: string;
  scale?: number;
}

export function BrowserPreview({ children, address, scale = 1 }: BrowserPreviewProps): ReactNode {
  return (
    <div className={styles.container} style={{ '--scale': scale } as CSSProperties}>
      <div className={styles.header}>
        <div className={styles.circles}>
          <div className={cx(styles.circle, styles.red)} />
          <div className={cx(styles.circle, styles.orange)} />
          <div className={cx(styles.circle, styles.green)} />
        </div>
        <div className={styles.address}>
          <IconLockedFilled />
          <p>{address}</p>
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
