import { Sca } from '../popup';

export const SCA_ENFORCEMENT_STATUS = {
  NOT_STARTED: 'not_started',
  PENDING: 'pending',
  FAILURE: 'failure',
  SUCCESS: 'success',
} as const;

export type ScaEnforcementStatus =
  (typeof SCA_ENFORCEMENT_STATUS)[keyof typeof SCA_ENFORCEMENT_STATUS];

interface ScaEnforcementModalProps {
  status: ScaEnforcementStatus;
  errorDetails: Record<string, unknown>;
  onClose: () => void;
  onSms: () => void;
}

export function ScaEnforcementModal({
  status,
  errorDetails,
  onClose,
  onSms,
}: ScaEnforcementModalProps): React.ReactNode {
  switch (status) {
    case SCA_ENFORCEMENT_STATUS.FAILURE:
      return <Sca.Refused actionType="login" onClose={onClose} onConfirm={onClose} />;
    case SCA_ENFORCEMENT_STATUS.NOT_STARTED:
    case SCA_ENFORCEMENT_STATUS.PENDING:
    case SCA_ENFORCEMENT_STATUS.SUCCESS:
      return (
        <Sca.EnforceQRCode
          onClose={onClose}
          onSms={onSms}
          qrCodeUrl={errorDetails.deeplinkUrl as string}
        />
      );
  }
}
