import type { HTMLAttributes, ReactElement, ReactNode } from 'react';
import cx from 'clsx';
import styles from './choice-chip.strict-module.css';

interface ChoiceChipProps extends Omit<HTMLAttributes<HTMLElement>, 'onChange'> {
  checkedValues?: string[];
  children?: ReactNode;
  isChecked?: boolean;
  isDisabled?: boolean;
  name?: string;
  onChange?: (value: string) => void;
  type?: string;
  value: string;
}

export function ChoiceChip({
  checkedValues,
  children,
  className,
  isChecked,
  isDisabled,
  name,
  onChange,
  type,
  value,
  ...props
}: ChoiceChipProps): ReactElement {
  const isChoiceChecked = isChecked || checkedValues?.includes(value);

  return (
    <div className={cx(styles['choice-chip'], className)} data-test-choice-chip={value} {...props}>
      <input
        checked={isChoiceChecked}
        className={cx('sr-only', styles['choice-chip--input'])}
        data-test-choice-chip-input
        data-testid="choice-chip-input"
        disabled={isDisabled}
        id={value}
        name={name}
        onChange={() => onChange?.(value)}
        type={type || 'radio'}
        value={value}
      />
      <label
        className={cx('body-1', styles['choice-chip--label'])}
        data-test-choice-chip-label
        data-testid="choice-chip-label"
        htmlFor={value}
      >
        {children}
      </label>
    </div>
  );
}
