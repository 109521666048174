import type { BasketItem } from '../../../types/payment-link';
import type { BasketTotals } from '../types';

export const calculateBasketTotals = (items: BasketItem[]): BasketTotals => {
  return items.reduce(
    (acc, item, index) => {
      const unitPrice = parseFloat(item.unitPrice.value);
      const quantity = item.quantity;
      const vatRate = parseFloat(item.vatRate);

      const itemTotalExclVat = unitPrice * quantity;
      const itemVat = itemTotalExclVat * vatRate;
      const itemTotalInclVat = itemTotalExclVat + itemVat;

      return {
        totalExcludingVat: acc.totalExcludingVat + itemTotalExclVat,
        totalVat: acc.totalVat + itemVat,
        totalIncludingVat: acc.totalIncludingVat + itemTotalInclVat,
        itemTotals: {
          ...acc.itemTotals,
          [index]: itemTotalExclVat,
        },
      };
    },
    {
      totalExcludingVat: 0,
      totalVat: 0,
      totalIncludingVat: 0,
      itemTotals: {},
    }
  );
};
