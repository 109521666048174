import type { SensitiveActionsType } from '../types/sensitive-actions';
import { useScaAuthStatus } from '../api/sca-auth';
import { SCA_STATUSES, ScaAuthModal } from '../components';
import type { ErrorComponentProps } from './sensitive-action';

export function ScaAuthUI({
  onClose,
  errorDetails,
  onSensitiveAction,
}: ErrorComponentProps): JSX.Element {
  const { data: scaAuthStatus } = useScaAuthStatus({
    token: errorDetails.scaSessionToken as string,
    onSensitiveAction,
  });
  return (
    <ScaAuthModal
      actionType={errorDetails.actionType as SensitiveActionsType}
      onClose={onClose}
      onDenyConfirm={onClose}
      status={scaAuthStatus?.result || SCA_STATUSES.WAITING}
    />
  );
}
