import { useMemo, type ReactNode } from 'react';
import {
  AmountField,
  ComboBox,
  Disclaimer,
  NumberField,
  SelectOption,
  TextAreaField,
  TextField,
} from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { VatRate } from '../../../../../../shared/vat-rate';
import type { VatRateOption } from '../../../../../../shared/vat-rate/vat-rate';
import { getSupportedUnits, valuePercentage } from '../../../../../../utils/payment-links';
import type { BasketItem } from '../../../../../types/payment-link';
import type { FormErrors } from '../selectable-item';
import styles from './edit-item-form.strict-module.css';

const VAT_RATE_PRECISION = 0.01;

interface EditItemFormProps {
  item: BasketItem;
  onUpdate: (item: BasketItem) => void;
  vatRates: number[];
  errors: FormErrors;
}

export function EditItemForm({ item, onUpdate, vatRates, errors }: EditItemFormProps): ReactNode {
  const { formatMessage, locale } = useIntl();
  const unitList = getSupportedUnits(formatMessage, item.quantity, locale);

  const vatRatePercentage = useMemo(
    () => valuePercentage(item.vatRate, locale),
    [item.vatRate, locale]
  );

  const selectedVatOption = useMemo(() => {
    if (!item.vatRate) {
      return undefined;
    }

    const rate = parseFloat(item.vatRate);
    const vatCategory = vatRates.find(
      vatRateCategory => Math.abs(Number(vatRateCategory) - rate * 100) < VAT_RATE_PRECISION
    );

    if (vatCategory !== undefined) {
      return {
        label: '',
        clearable: false,
        value: vatCategory,
      };
    }

    return { label: '', clearable: true, value: -1 };
  }, [item.vatRate, vatRates]);

  const updateVatRate = (vatRate?: VatRateOption): void => {
    if (!vatRate) {
      onUpdate({ ...item, vatRate: '' });
    } else if (vatRate.value >= 0) {
      onUpdate({ ...item, vatRate: String((Number(vatRate.value) / 100).toFixed(3)) });
    } else {
      onUpdate({ ...item, vatRate: String(-1) });
    }
  };

  return (
    <div className={styles['edit-container']} data-test-edit-form>
      <TextField
        data-test-title
        errorMessage={formatMessage({ id: 'payment-link.create.step-1.item.create.name.error' })}
        isInvalid={errors.includes('title')}
        label={formatMessage({ id: 'payment-link.create.step-1.item.create.name.label' })}
        maxLength={120}
        onChange={title => {
          onUpdate({ ...item, title });
        }}
        placeholder={formatMessage({
          id: 'payment-link.create.step-1.item.create.name.placeholder',
        })}
        value={item.title}
      />
      <TextAreaField
        data-test-description
        label={formatMessage({ id: 'payment-link.create.step-1.item.create.details.label' })}
        maxLength={600}
        onChange={description => {
          onUpdate({ ...item, description });
        }}
        placeholder={formatMessage({
          id: 'payment-link.create.step-1.item.create.details.placeholder',
        })}
        value={item.description}
      />
      <div className={styles['price-container']}>
        <div className={styles['quantity-container']}>
          <NumberField
            className={styles['quantity-input']}
            data-test-quantity
            errorMessage={formatMessage({
              id: 'payment-link.create.step-1.item.create.quantity.error',
            })}
            isInvalid={errors.includes('quantity')}
            label={formatMessage({ id: 'payment-link.create.step-1.item.create.quantity.label' })}
            minValue={0}
            onChange={quantity => {
              onUpdate({ ...item, quantity: Number(quantity) });
            }}
            placeholder="0"
            value={item.quantity}
          />
          <ComboBox
            allowsCustomValue
            aria-label={formatMessage({
              id: 'payment-link.create.step-1.item.create.quantity.placeholder',
            })}
            className={styles['unit-select']}
            data-test-unit-select
            defaultInputValue="unit"
            inputValue={item.measureUnit}
            isDisabled={false}
            label=""
            maxLength={20}
            menuTrigger="focus"
            onInputChange={measureUnit => {
              onUpdate({ ...item, measureUnit });
            }}
            placeholder={formatMessage({
              id: 'payment-link.create.step-1.item.create.quantity.placeholder',
            })}
            withArrowButton
          >
            {unitList.map(unit => (
              <SelectOption id={unit.unitCode} key={unit.unitCode}>
                {unit.unitDisplayName}
              </SelectOption>
            ))}
          </ComboBox>
        </div>
        <AmountField
          amount={0}
          className={styles['price-input']}
          currency={item.unitPrice.currency}
          data-test-price
          errorMessage={formatMessage({
            id: 'payment-link.create.step-1.item.create.unit-price.error',
          })}
          isInvalid={errors.includes('unitPrice')}
          label={formatMessage({ id: 'payment-link.create.step-1.item.create.unit-price.label' })}
          onChange={unitPrice => {
            onUpdate({ ...item, unitPrice: { ...item.unitPrice, value: String(unitPrice) } });
          }}
          placeholder="0.00"
          value={Number(item.unitPrice.value)}
        />
      </div>

      <VatRate
        data-test-vat-rate
        errorMessage={formatMessage({ id: 'payment-link.create.step-1.item.create.vat.error' })}
        isInvalid={errors.includes('vatRate')}
        label={formatMessage({ id: 'payment-link.create.step-1.item.create.vat.label' })}
        selectedVatOption={selectedVatOption}
        updateVatOption={updateVatRate}
        vatRate={vatRatePercentage}
        vatRateOnChange={(vatRate: string) => {
          onUpdate({ ...item, vatRate: String((Number(vatRate) / 100).toFixed(3)) });
        }}
        vatRates={vatRates}
      />

      <Disclaimer.Inline data-test-disclaimer level="warning">
        {formatMessage({ id: 'payment-link.create.step-1.item.create.disclaimer' })}
      </Disclaimer.Inline>
    </div>
  );
}
