import type { ReactNode } from 'react';
import { ProgressBar } from '@repo/design-system-kit';
import { useCounter, useInterval } from 'usehooks-ts';
import cx from 'clsx';
import styles from './loading-state.strict-module.css';
import { LoadingStateSteps } from './loading-state-steps';

export interface LoadingStateStep {
  label: string;
  stepInterval?: number;
}

export interface LoadingStateProps {
  isAIBranded?: boolean;
  onLoadingCompletedDelay?: number;
  onLoadingCompleted?: () => void;
  progressBarLabel: string;
  steps: LoadingStateStep[];
  subtitle?: string;
  title?: string;
}

export function LoadingState({
  isAIBranded = false,
  onLoadingCompleted,
  onLoadingCompletedDelay = 500,
  progressBarLabel,
  steps,
  subtitle,
  title,
  ...props
}: LoadingStateProps): ReactNode {
  const { count, increment } = useCounter(0);
  const interval = steps[count]?.stepInterval || 500;

  useInterval(() => {
    if (count < steps.length) {
      increment();
    }
  }, interval);

  useInterval(() => {
    if (count === steps.length && onLoadingCompleted) {
      increment();
      onLoadingCompleted();
    }
  }, onLoadingCompletedDelay);

  return (
    <div className={styles.wrapper} {...props}>
      {title ? <h1 className="title-1 mb-16">{title}</h1> : null}
      {subtitle ? <p className="body-1 mb-32">{subtitle}</p> : null}
      <div className={styles.progressChecks}>
        <p className="title-4">{progressBarLabel}</p>
        <ProgressBar
          className={cx(styles.progressBar, isAIBranded && styles.brandedProgressBar)}
          maxValue={steps.length}
          minValue={0}
          value={count}
        />
        <LoadingStateSteps progress={count} steps={steps} />
      </div>
    </div>
  );
}
