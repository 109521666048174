export function GenericErrorSvg(props: React.SVGProps<SVGSVGElement>): React.ReactNode {
  return (
    <svg fill="none" viewBox="0 0 200 189" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 0h41.02v6.19H0z"
        fill="#D5C8FB"
        stroke="#1D1D1B"
        strokeWidth=".43"
        transform="rotate(-29.98 252.9 -25.43) skewX(.04)"
      />
      <path
        d="M90.63 126.55 45.42 100.7a.13.13 0 0 0-.13 0L.26 127.06a.13.13 0 0 0 0 .22l45.03 25.7c.04.02.09.02.13 0l45.21-26.2a.13.13 0 0 0 0-.23Z"
        fill="#fff"
        stroke="#1D1D1B"
        strokeWidth=".43"
      />
      <path d="m30.32 109.77 45.38 25.82" stroke="#1D1D1B" strokeWidth=".21" />
      <path
        d="m194.14 66.56-45.22-25.85a.13.13 0 0 0-.13 0l-45.03 26.36a.13.13 0 0 0 0 .22l45.03 25.86c.04.02.1.02.13 0l45.22-26.36a.13.13 0 0 0 0-.23Z"
        fill="#fff"
        stroke="#1D1D1B"
        strokeWidth=".43"
      />
      <path
        d="m129.04 52.93 19.62 11.76"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeWidth=".21"
      />
      <path
        clipRule="evenodd"
        d="M51.2 69.01a3.7 3.7 0 0 1-.37-.19c-3.08-1.77-3.04-7.6.1-13.02 3.12-5.42 8.16-8.37 11.24-6.6.19.11.37.24.54.38l17.45 10-11.4 19.58-17.59-10.1.03-.05Z"
        fill="#EFE9FE"
        fillRule="evenodd"
      />
      <path
        d="m51.2 69.01.2.1.1-.2-.21-.1-.09.2Zm-.37-.19-.1.19.1-.19Zm.1-13.02.18.1-.19-.1Zm11.24-6.6.1-.18-.1.18Zm.54.38-.14.17h.01l.02.02.1-.19Zm17.45 10 .19.1.1-.18-.18-.1-.1.18Zm-11.4 19.58-.1.19.18.1.11-.18-.18-.1Zm-17.59-10.1-.18-.1-.1.19.18.1.1-.19Zm.12-.25a3.5 3.5 0 0 1-.35-.17l-.21.37.39.2.17-.4Zm-.35-.17c-1.44-.84-2.2-2.64-2.18-4.96.02-2.32.8-5.1 2.35-7.78l-.37-.21a16.73 16.73 0 0 0-2.4 7.98c-.02 2.4.75 4.4 2.39 5.34l.21-.37Zm.17-12.74A16.32 16.32 0 0 1 56.67 50c2-1.18 3.95-1.43 5.4-.6l.2-.37c-1.63-.94-3.75-.61-5.81.6a16.74 16.74 0 0 0-5.72 6.07l.37.21Zm10.95-6.51c.18.1.35.22.5.36l.28-.33a3.95 3.95 0 0 0-.56-.4l-.22.37Zm.54.38 17.46 10 .2-.38-17.45-10-.21.38Zm17.38 9.7-11.4 19.58.37.22 11.4-19.58-.37-.22Zm-11.1 19.5-17.6-10.1-.21.38 17.59 10.1.21-.37Zm-17.52-9.8.03-.06-.37-.2-.03.05.37.2Z"
        fill="#1D1D1B"
      />
      <path
        clipRule="evenodd"
        d="M57.6 99.1c-.33-.14-.64-.3-.94-.48-9.26-5.34-9.14-22.87.27-39.16 9.4-16.29 24.54-25.16 33.8-19.82l.42.26 53.39 30.58-34.26 58.87L57.6 99.1Z"
        fill="#EFE9FE"
        fillRule="evenodd"
      />
      <path
        d="m57.6 99.1.1-.18h-.02l-.09.19Zm-.94-.48.1-.18-.1.18Zm.27-39.16.18.1-.18-.1Zm33.8-19.82.11-.18-.1.18Zm.42.26-.12.18h.01l.1-.18Zm53.39 30.58.18.1.11-.18-.19-.1-.1.18Zm-34.26 58.87-.1.18.18.1.1-.17-.18-.11ZM57.68 98.9c-.31-.14-.62-.3-.92-.47l-.21.37c.31.18.63.34.95.5l.18-.4Zm-.92-.47c-4.53-2.62-6.8-8.24-6.76-15.3.05-7.04 2.42-15.46 7.11-23.57l-.37-.22c-4.72 8.17-7.11 16.66-7.16 23.8-.05 7.12 2.24 12.93 6.97 15.66l.21-.37Zm.35-38.87c4.7-8.12 10.8-14.38 16.88-17.94 6.1-3.57 12.1-4.42 16.64-1.8l.21-.37c-4.73-2.73-10.91-1.8-17.06 1.8-6.16 3.6-12.32 9.92-17.04 18.1l.37.2Zm33.52-19.74.4.25.23-.37-.42-.25-.21.37Zm.41.25 53.4 30.58.2-.37-53.39-30.58-.2.37Zm53.31 30.3-34.25 58.86.37.22 34.25-58.87-.37-.22Zm-33.96 58.78L57.7 98.92l-.21.37 52.69 30.24.2-.37Z"
        fill="#1D1D1B"
      />
      <path
        d="M67.94 65.81a53.69 53.69 0 0 0-6.66 18.1l34.63 19.95c.24-1.5.56-3.02.98-4.56a56.3 56.3 0 0 1 5.68-13.54c4.9-8.47 11.34-14.93 17.7-18.44a25.92 25.92 0 0 1 5.02-2.14.06.06 0 0 0 .01-.12L90.8 45.19c-7.88 2.3-16.63 9.84-22.86 20.62Z"
        stroke="#1D1D1B"
        strokeWidth=".21"
      />
      <path
        d="M75.75 63.75c5.84-7.52 10.73-10.34 14.96-12.47l28.38 16.69.51-.27c-6.14 3.58-12.3 9.89-17.03 18.06-2.4 4.16-4.2 8.4-5.4 12.5l.02.02-.16.48.01-.03.14-.46-30.24-17.5s2.98-9.5 8.81-17.02Z"
        fill="#D5C8FB"
      />
      <path
        d="M90.7 51.28c-4.22 2.13-9.11 4.95-14.95 12.47-5.83 7.52-8.81 17.03-8.81 17.03m23.77-29.5v-5.96m0 5.96 28.38 16.69.51-.27M61.45 83.88l5.49-3.1m0 0 30.25 17.5-.16.48M119.6 67.7c-6.14 3.58-12.3 9.89-17.03 18.06a56.62 56.62 0 0 0-5.53 12.97M119.6 67.7c.22-.13-.22.12 0 0ZM97.04 98.73v.03m0-.03v.03"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeWidth=".21"
      />
      <ellipse
        cx="19.36"
        cy="34.06"
        fill="#1D1D1B"
        rx="19.36"
        ry="34.06"
        stroke="#1D1D1B"
        strokeWidth=".43"
        transform="rotate(29.98 -49.66 269.01) skewX(-.04)"
      />
      <path
        d="M176.26 127.51c-15.79 27.32-38.63 43.46-54.16 34.5-14.5-8.36-22.02-41.41-6.23-68.73 15.78-27.32 49.9-37.75 62.32-30.58 15.53 8.96 13.85 37.49-1.93 64.81Z"
        fill="#EFE9FE"
        stroke="#1D1D1B"
        strokeWidth=".43"
      />
      <ellipse
        cx="32.47"
        cy="57.13"
        fill="#fff"
        rx="32.47"
        ry="57.13"
        stroke="#1D1D1B"
        strokeWidth=".43"
        transform="rotate(29.98 -12.49 307.22) skewX(-.04)"
      />
      <path
        d="M83.53 90.29a56.81 56.81 0 0 0 7.18-9.87c5-8.63 7.34-17.63 7.05-24.95"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeWidth=".21"
      />
      <ellipse
        cx="23.75"
        cy="45.27"
        fill="#EFE9FE"
        rx="23.75"
        ry="45.27"
        stroke="#1D1D1B"
        strokeWidth=".21"
        transform="rotate(29.98 -39.38 317.67) skewX(-.04)"
      />
      <ellipse
        cx="9.26"
        cy="14.3"
        fill="#F3F3F0"
        rx="9.26"
        ry="14.3"
        stroke="#1D1D1B"
        strokeWidth=".21"
        transform="rotate(29.98 -103.52 327.1) skewX(-.04)"
      />
      <ellipse
        cx="6.24"
        cy="11.33"
        fill="#1D1D1B"
        rx="6.24"
        ry="11.33"
        stroke="#1D1D1B"
        strokeWidth=".43"
        transform="rotate(29.98 -109.6 326.02) skewX(-.04)"
      />
      <path
        clipRule="evenodd"
        d="M146.46 113.3c-.56-.37-.52-1.52.1-2.59.61-1.07 1.59-1.67 2.2-1.38l49.23 28.4-2.32 4-49.23-28.4.02-.02Z"
        fill="#D5C8FB"
        fillRule="evenodd"
      />
      <path
        d="m146.46 113.3.19.11.1-.17-.17-.11-.12.18Zm.1-2.59-.19-.1.19.1Zm2.2-1.38-.1.2.18.08.1-.17-.19-.1Zm0 0 .1-.2-.18-.1-.1.19.18.1Zm49.23 28.4.18.1.1-.18-.18-.11-.1.19Zm-2.32 4-.1.19.18.1.1-.18-.18-.1Zm-49.23-28.4-.18-.1-.1.18.18.11.1-.19Zm.14-.2c-.2-.13-.32-.42-.3-.85.01-.43.17-.95.46-1.46l-.37-.21c-.32.56-.5 1.14-.52 1.65-.01.5.12.97.49 1.22l.24-.35Zm.16-2.31c.3-.51.67-.9 1.03-1.13.37-.23.68-.26.9-.16l.18-.39c-.4-.2-.88-.08-1.3.19-.44.27-.86.72-1.18 1.28l.37.21Zm2.2-1.38-.37-.22.37.22Zm-.29.07 49.23 28.4.21-.37-49.22-28.4-.22.37Zm49.15 28.1-2.31 4.02.36.21 2.32-4-.37-.22Zm-2.02 3.94-49.23-28.4-.21.37 49.22 28.4.22-.37Zm-49.15-28.1.02-.04-.38-.21-.01.03.37.21Z"
        fill="#1D1D1B"
      />
      <path
        clipRule="evenodd"
        d="M184.52 140.07a2.4 2.4 0 0 1-.16-.09c-1.76-1.01-1.69-4.41.15-7.6 1.84-3.18 4.75-4.94 6.5-3.93l.05.03h.03l7.41 4.28-6.52 11.45a.13.13 0 0 1-.18.05l-7.28-4.2Z"
        fill="#EFE9FE"
        fillRule="evenodd"
      />
      <path
        d="m184.52 140.07.1-.19h-.01l-.1.19Zm-.16-.09-.11.19.1-.19Zm.15-7.6-.19-.1.19.1Zm6.5-3.93.12-.18-.11.18Zm.05.03-.1.18.01.01.03.01.06-.2Zm.03 0-.11.2.1-.2Zm7.41 4.28.19.1.1-.18-.18-.1-.1.18Zm-6.52 11.45.19.1-.19-.1Zm-.18.05.11-.19-.1.19Zm-7.2-4.39-.14-.07-.21.37.18.09.18-.39Zm-.14-.07c-.78-.45-1.19-1.46-1.16-2.8a9.74 9.74 0 0 1 1.4-4.5l-.38-.22a10.15 10.15 0 0 0-1.45 4.71c-.03 1.41.4 2.61 1.38 3.18l.21-.37Zm.23-7.3a9.75 9.75 0 0 1 3.22-3.47c1.15-.7 2.22-.84 3-.4l.22-.36c-.98-.57-2.24-.34-3.44.4-1.22.73-2.43 1.99-3.37 3.6l.38.22Zm6.22-3.86.04.02.22-.36-.04-.03-.22.37Zm.09.04h-.02l.21-.38a.34.34 0 0 0-.06-.03l-.13.41Zm-.02 0 7.42 4.27.21-.37-7.42-4.28-.21.37Zm7.34 3.98-6.53 11.45.38.2 6.52-11.44-.37-.21Zm-6.53 11.45a.09.09 0 0 1 .12-.04l-.21.38c.16.09.37.03.47-.13l-.38-.21Zm.12-.04-7.29-4.19-.21.37 7.29 4.2.21-.38Z"
        fill="#1D1D1B"
      />
      <ellipse
        cx="3.67"
        cy="6.66"
        fill="#D5C8FB"
        rx="3.67"
        ry="6.66"
        stroke="#1D1D1B"
        strokeWidth=".43"
        transform="rotate(29.98 -146.83 430.3) skewX(-.04)"
      />
      <path
        d="M102.81 30.52a53.8 53.8 0 0 1-12.28 14.75.06.06 0 0 0 .01.1l34.33 19.84c.12.06.26.05.36-.03a53.87 53.87 0 0 0 12.19-14.67c6.22-10.78 8.38-22.1 6.45-30.07a.06.06 0 0 0-.03-.04L109.39.5c-.05-.03-.1 0-.1.06 1.89 7.97-.28 19.24-6.48 29.96Z"
        fill="#D5C8FB"
        stroke="#1D1D1B"
        strokeWidth=".43"
      />
      <path d="m131.78 22.85-11.15-6.36" stroke="#1D1D1B" strokeLinecap="round" strokeWidth=".21" />
      <path
        clipRule="evenodd"
        d="M39.08 135.98c5.72 0 10.35-2.85 10.35-6.36 0-.13 0-.35.02-.62.02-1.23.08-3.58-.2-4.34-.02-.07-.11-.09-.17-.04l-8.54 7-5.57-3.21 8.83-4.98a.13.13 0 0 0 .06-.1v-3c0-.05-.03-.09-.08-.07-1.16.43-15.05 5.67-15.05 9.04 0 .63.15 1.24.42 1.83l-20.92 8.44a.32.32 0 0 0-.2.3v4.81c0 .23.13.45.32.56l6.13 3.55c.63.37 1.41.35 2.02-.05l20.06-12.97c.8.14 1.65.21 2.52.21Z"
        fill="#D5C8FB"
        fillRule="evenodd"
      />
      <path
        d="M49.45 129h-.22.22Zm-.2-4.34.2-.07-.2.07Zm-.17-.04-.14-.17.14.17Zm-8.54 7-.1.18.13.07.11-.1-.14-.16Zm-5.57-3.21-.1-.19-.33.19.32.18.1-.18Zm8.83-4.98-.11-.18.1.18Zm-.02-3.17-.07-.2.07.2Zm-14.63 10.87.08.2.21-.09-.1-.2-.19.09Zm-20.92 8.44.08.2-.08-.2Zm-.2.3h.22-.22Zm0 4.81h-.21.21Zm.32.56-.1.19.1-.19Zm6.13 3.55-.1.19.1-.19Zm2.02-.05.12.18-.12-.18Zm20.06-12.97.04-.2-.09-.02-.07.04.12.18Zm12.66-6.15c0 1.65-1.1 3.19-2.93 4.32a13.97 13.97 0 0 1-7.2 1.83v.43c2.88 0 5.51-.72 7.42-1.9 1.91-1.17 3.14-2.82 3.14-4.68h-.43Zm.01-.62-.01.62h.43V129l-.42-.01Zm-.18-4.27c.06.17.1.43.14.78.03.34.05.74.06 1.15.02.83 0 1.72-.02 2.34h.43c.01-.6.03-1.5.02-2.35-.01-.42-.03-.83-.06-1.18a3.93 3.93 0 0 0-.16-.88l-.4.14Zm.16.05a.09.09 0 0 1-.07.02.12.12 0 0 1-.09-.07l.4-.14c-.08-.25-.36-.26-.5-.14l.26.33Zm-8.53 7 8.53-7-.27-.33-8.53 7 .27.33Zm-5.82-3.19 5.58 3.2.21-.36-5.58-3.2-.2.36Zm8.83-5.34-8.83 4.97.21.37 8.83-4.97-.21-.37Zm-.04.07c0-.03.01-.06.04-.07l.21.37c.1-.06.17-.18.17-.3h-.42Zm0-3v3h.42v-3h-.42Zm.2.14a.16.16 0 0 1-.15-.03.14.14 0 0 1-.05-.1h.42a.28.28 0 0 0-.1-.22.27.27 0 0 0-.26-.05l.15.4Zm-14.9 8.84c0-.35.18-.75.55-1.2.36-.45.9-.92 1.55-1.4 1.3-.96 3.06-1.94 4.87-2.83 3.6-1.78 7.36-3.2 7.94-3.41l-.15-.4c-.59.22-4.36 1.64-7.98 3.43-1.82.9-3.6 1.88-4.94 2.87a9.1 9.1 0 0 0-1.62 1.47c-.4.48-.65.98-.65 1.47h.43Zm.4 1.74a4.1 4.1 0 0 1-.4-1.74h-.43c0 .66.15 1.3.44 1.92l.39-.18ZM8.3 139.76l20.92-8.43-.16-.4-20.92 8.44.16.4Zm-.06.1c0-.04.02-.08.06-.1l-.16-.4a.54.54 0 0 0-.33.5h.43Zm0 4.82v-4.82h-.43v4.82h.43Zm.21.38a.43.43 0 0 1-.21-.38h-.43c0 .31.16.6.43.75l.21-.37Zm6.13 3.55-6.13-3.55-.21.37 6.12 3.55.22-.37Zm1.8-.05c-.55.35-1.24.37-1.8.05l-.22.37c.7.4 1.57.38 2.25-.06l-.23-.36Zm20.05-12.97L16.4 148.56l.23.36 20.06-12.97-.24-.36Zm2.64.18c-.85 0-1.69-.07-2.48-.2l-.08.41c.82.14 1.68.22 2.56.22v-.43Z"
        fill="#1D1D1B"
      />
      <path
        clipRule="evenodd"
        d="M39.08 132.17c5.72 0 10.35-2.85 10.35-6.36 0-.74-.2-1.44-.57-2.1a.13.13 0 0 0-.17-.05l-8.15 4.46-5.57-2.9 8.84-4.85c.1-.06.08-.2-.02-.23-1.41-.45-3.01-.7-4.7-.7-5.72 0-10.36 2.85-10.36 6.37 0 .76.22 1.5.63 2.18L7.39 139.57a.64.64 0 0 0-.03 1.13l7.11 4.26c.64.38 1.43.36 2.05-.04l20-12.94c.82.12 1.68.2 2.56.2Z"
        fill="#D5C8FB"
        fillRule="evenodd"
      />
      <path
        d="m48.86 123.7.18-.1-.18.1Zm-.17-.04-.1-.19.1.2Zm-8.15 4.46-.1.19.1.05.1-.05-.1-.19Zm-5.57-2.9-.1-.18-.36.2.36.18.1-.2Zm8.84-4.85-.1-.19.1.19Zm-.02-.23-.07.2.07-.2Zm-14.43 7.85.1.19.2-.1-.12-.2-.18.1ZM7.39 139.57l.1.2-.1-.2Zm-.03 1.13-.1.18.1-.19Zm7.11 4.26-.1.18.1-.18Zm2.05-.04.11.18-.11-.18Zm20-12.94.04-.22h-.08l-.07.04.12.17Zm12.7-6.17c0 1.65-1.1 3.19-2.93 4.31a13.97 13.97 0 0 1-7.2 1.84v.42c2.88 0 5.51-.72 7.42-1.9 1.91-1.16 3.14-2.81 3.14-4.67h-.43Zm-.55-2a4 4 0 0 1 .55 2h.43c0-.78-.22-1.52-.6-2.2l-.38.2Zm.12.04a.09.09 0 0 1-.12-.03l.37-.22a.34.34 0 0 0-.46-.13l.21.38Zm-8.14 4.46 8.14-4.46-.2-.38-8.15 4.46.2.38Zm-5.78-2.9 5.58 2.9.2-.38-5.58-2.9-.2.39Zm8.84-5.23-8.85 4.86.21.38 8.84-4.87-.2-.37Zm.01.16c-.07-.03-.08-.13-.01-.16l.2.37a.34.34 0 0 0-.06-.62l-.13.4Zm-4.64-.69c1.68 0 3.25.25 4.64.69l.13-.4a16 16 0 0 0-4.77-.71v.42Zm-10.13 6.16c0-1.66 1.09-3.2 2.93-4.32a13.97 13.97 0 0 1 7.2-1.83v-.43c-2.89 0-5.52.72-7.43 1.9-1.9 1.17-3.13 2.82-3.13 4.68h.43Zm.6 2.07a4.03 4.03 0 0 1-.6-2.07h-.43c0 .8.23 1.58.65 2.29l.37-.22ZM7.48 139.76l21.97-11.58-.2-.38L7.3 139.38l.2.38Zm-.02.75a.43.43 0 0 1 .02-.75l-.2-.38a.86.86 0 0 0-.04 1.5l.22-.37Zm7.11 4.26-7.1-4.26-.23.37 7.11 4.26.22-.37Zm1.82-.03c-.55.36-1.25.37-1.82.03l-.22.37c.7.42 1.59.4 2.27-.04l-.23-.36Zm20-12.94-20 12.94.23.36 20.01-12.94-.23-.36Zm2.68.16c-.87 0-1.71-.07-2.52-.2l-.07.43c.83.13 1.7.2 2.6.2v-.43Z"
        fill="#1D1D1B"
      />
    </svg>
  );
}
