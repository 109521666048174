import { type ComponentPropsWithRef, useCallback } from 'react';
import { type UseEmblaCarouselType } from 'embla-carousel-react';
import { IconArrowLeftOutlined, IconArrowRightOutlined } from '@repo/monochrome-icons';
import cx from 'clsx';
import styles from './arrows.strict-module.css';

interface UsePrevNextButtonsType {
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
}

export function usePrevNextButtons(
  emblaApi: UseEmblaCarouselType[1],
  onSlideChange?: (index: number) => void
): UsePrevNextButtonsType {
  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
    onSlideChange?.(emblaApi.selectedScrollSnap());
  }, [emblaApi, onSlideChange]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
    onSlideChange?.(emblaApi.selectedScrollSnap());
  }, [emblaApi, onSlideChange]);

  return {
    onPrevButtonClick,
    onNextButtonClick,
  };
}

export function PrevButton(props: ComponentPropsWithRef<'button'>): JSX.Element {
  const { ...restProps } = props;
  return (
    <button className={cx(styles.button, styles['left-arrow'])} type="button" {...restProps}>
      <IconArrowLeftOutlined className={styles.arrow} />
    </button>
  );
}

export function NextButton(props: ComponentPropsWithRef<'button'>): JSX.Element {
  const { ...restProps } = props;
  return (
    <button className={cx(styles.button, styles['right-arrow'])} type="button" {...restProps}>
      <IconArrowRightOutlined className={styles.arrow} />
    </button>
  );
}
