import { type ComponentPropsWithoutRef, type ReactNode } from 'react';
import cx from 'clsx';
import { LottiePlayer } from '../../lottie-player';
import styles from './styles.strict-module.css';

export type IllustrationSize = 'small' | 'medium';

export interface IllustrationProps extends Omit<ComponentPropsWithoutRef<'div'>, 'children'> {
  illuPath?: string;
  lottiePath?: string;
  size?: IllustrationSize;
}

function getIllustrationContent({
  illuPath,
  lottiePath,
}: Pick<IllustrationProps, 'illuPath' | 'lottiePath'>): ReactNode {
  if (illuPath) {
    return <img alt="" data-test-q-state-image role="presentation" src={illuPath} />;
  }

  if (lottiePath) {
    return <LottiePlayer data-test-q-state-lottie path={lottiePath} />;
  }

  return null;
}

export function Illustration({
  className = '',
  illuPath,
  lottiePath,
  size = 'medium',
  ...props
}: IllustrationProps): ReactNode {
  return (
    <div
      className={cx(styles.wrapper, styles[`wrapper--${size}`], className)}
      data-test-q-state
      {...props}
    >
      {getIllustrationContent({ illuPath, lottiePath })}
    </div>
  );
}
