import type { ComponentPropsWithRef, ReactNode } from 'react';

export function QontoIntelligenceIcon(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      fill="none"
      height="100%"
      viewBox="0 0 22 22"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill="var(--elevation-default)" height="21" rx="4" width="21" x=".5" y=".5" />
      <rect
        height="21"
        rx="4"
        stroke="url(#qonto_intelligence_button_a)"
        width="21"
        x=".5"
        y=".5"
      />
      <path d="M10.65 4A7.014 7.014 0 0 0 4 10.64 7.015 7.015 0 0 0 10.65 4Z" fill="url(#b)" />
      <path d="M10.65 18A7.014 7.014 0 0 0 4 11.36 7.014 7.014 0 0 0 10.65 18Z" fill="url(#c)" />
      <path d="M18 10.64A7.014 7.014 0 0 0 11.35 4 7.015 7.015 0 0 0 18 10.64Z" fill="url(#d)" />
      <path d="M11.35 18A7.014 7.014 0 0 0 18 11.36 7.014 7.014 0 0 0 11.35 18Z" fill="url(#e)" />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="qonto_intelligence_button_a"
          x1="0"
          x2="22"
          y1="0"
          y2="0"
        >
          <stop stopColor="#9B81F6" />
          <stop offset=".143" stopColor="#C07EF3" />
          <stop offset=".286" stopColor="#DA80E9" />
          <stop offset=".429" stopColor="#EC86DA" />
          <stop offset=".571" stopColor="#F891C8" />
          <stop offset=".714" stopColor="#FE9EB5" />
          <stop offset=".857" stopColor="#FFADA1" />
          <stop offset="1" stopColor="#FFBC90" />
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" id="b" x1="4" x2="18" y1="4" y2="4">
          <stop stopColor="#9B81F6" />
          <stop offset=".143" stopColor="#C07EF3" />
          <stop offset=".286" stopColor="#DA80E9" />
          <stop offset=".429" stopColor="#EC86DA" />
          <stop offset=".571" stopColor="#F891C8" />
          <stop offset=".714" stopColor="#FE9EB5" />
          <stop offset=".857" stopColor="#FFADA1" />
          <stop offset="1" stopColor="#FFBC90" />
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" id="c" x1="4" x2="18" y1="4" y2="4">
          <stop stopColor="#9B81F6" />
          <stop offset=".143" stopColor="#C07EF3" />
          <stop offset=".286" stopColor="#DA80E9" />
          <stop offset=".429" stopColor="#EC86DA" />
          <stop offset=".571" stopColor="#F891C8" />
          <stop offset=".714" stopColor="#FE9EB5" />
          <stop offset=".857" stopColor="#FFADA1" />
          <stop offset="1" stopColor="#FFBC90" />
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" id="d" x1="4" x2="18" y1="4" y2="4">
          <stop stopColor="#9B81F6" />
          <stop offset=".143" stopColor="#C07EF3" />
          <stop offset=".286" stopColor="#DA80E9" />
          <stop offset=".429" stopColor="#EC86DA" />
          <stop offset=".571" stopColor="#F891C8" />
          <stop offset=".714" stopColor="#FE9EB5" />
          <stop offset=".857" stopColor="#FFADA1" />
          <stop offset="1" stopColor="#FFBC90" />
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" id="e" x1="4" x2="18" y1="4" y2="4">
          <stop stopColor="#9B81F6" />
          <stop offset=".143" stopColor="#C07EF3" />
          <stop offset=".286" stopColor="#DA80E9" />
          <stop offset=".429" stopColor="#EC86DA" />
          <stop offset=".571" stopColor="#F891C8" />
          <stop offset=".714" stopColor="#FE9EB5" />
          <stop offset=".857" stopColor="#FFADA1" />
          <stop offset="1" stopColor="#FFBC90" />
        </linearGradient>
      </defs>
    </svg>
  );
}
