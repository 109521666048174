import { Button, LottiePlayer, Popup } from '@repo/design-system-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMemo, useState } from 'react';
import otpConfirmationAnimation from '../../../lotties/otp-confirmation-code.json';
import { CodeVerificationSvg } from '../../../svgs/code-verification';
import { VerificationCode } from '../../inputs';
import type { MfaMethod } from '../../../providers/user-provider';
import styles from './mfa.strict-module.css';

interface ConfirmationProps {
  mfa: {
    method: MfaMethod;
    phoneNumber?: string;
    email?: string;
  };
  onClose?: () => void;
  onSendSMS: () => void;
  onConfirmOtp: ({ otpToken }: { otpToken: string }) => void;
  onCancel: () => void;
}

export function Confirmation({
  onClose,
  onSendSMS,
  onCancel,
  onConfirmOtp,
  mfa,
}: ConfirmationProps): React.ReactNode {
  const [isOpen, setIsOpen] = useState(true);
  const [otp, setOtp] = useState('');
  const { formatMessage } = useIntl();
  const description = useMemo(() => {
    switch (mfa.method) {
      case 'by_sms':
        return formatMessage(
          { id: 'otp.confirmation_subtitle' },
          { phoneOrEmail: mfa.phoneNumber }
        );
      case 'by_email':
        return formatMessage({ id: 'otp.confirmation_subtitle' }, { phoneOrEmail: mfa.email });
      default:
        return formatMessage({ id: 'otp.confirmation_app_subtitle' });
    }
  }, [formatMessage, mfa.email, mfa.method, mfa.phoneNumber]);

  const handleOtpChange = (otpValue: string): void => {
    setOtp(otpValue);
  };

  const handleClose = (): void => {
    setIsOpen(false);
    onClose?.();
  };

  const handleCancel = (): void => {
    handleClose();
    onCancel();
  };

  return (
    <Popup.Root
      aria-label={formatMessage({ id: 'otp.confirmation_title' })}
      isOpen={isOpen}
      role="dialog"
    >
      <Popup.CloseButton onPress={handleClose} />
      <Popup.Header>
        <figure className={styles.illustrationWrap}>
          {mfa.method === 'by_email' ? (
            <CodeVerificationSvg className={styles.illustration} data-img="email" role="img" />
          ) : (
            <LottiePlayer
              animationData={otpConfirmationAnimation}
              className="mb-24"
              data-img="sms-animation"
              loop
              role="img"
            />
          )}
        </figure>
        <Popup.Title>
          <FormattedMessage id="otp.confirmation_title" />
        </Popup.Title>
        <Popup.Subtitle>{description}</Popup.Subtitle>
      </Popup.Header>
      <form
        onSubmit={e => {
          e.preventDefault();
          onConfirmOtp({ otpToken: otp });
        }}
      >
        <Popup.Body>
          <VerificationCode onOtpChange={handleOtpChange} onSendSMS={onSendSMS} />
        </Popup.Body>
        <Popup.Footer>
          <Button onPress={handleCancel} variant="secondary">
            {mfa.method === 'by_email' ? (
              <FormattedMessage id="btn.back" />
            ) : (
              <FormattedMessage id="btn.cancel" />
            )}
          </Button>
          <Button isDisabled={otp.length !== 6} isLoading={false} type="submit" variant="primary">
            <FormattedMessage id="btn.confirm" />
          </Button>
        </Popup.Footer>
      </form>
    </Popup.Root>
  );
}
