import cx from 'clsx';
import { QontoLogoThemedSvg } from '../../svgs/qonto-logo';
import styles from './email-verification.strict-module.css';

interface EmailVerificationGenericProps {
  title: string;
  subtitle: string;
  illustration: React.ReactNode;
  ['data-testid']?: string;
}

export function EmailVerificationGeneric({
  title,
  subtitle,
  illustration,
  'data-testid': dataTestId,
}: EmailVerificationGenericProps): React.ReactNode {
  return (
    <div className={styles.wrapper} data-testid={dataTestId}>
      <header className={styles.header}>
        <figure className={styles.headerLogo}>
          <QontoLogoThemedSvg />
        </figure>
      </header>
      <main className={styles.content}>
        <section className={styles.section}>
          <figure className={styles.illustration}>{illustration}</figure>
          <div className={styles.description}>
            <h1 className="title-2">{title}</h1>
            <p className={cx('body-1', styles.lh24)}>{subtitle}</p>
          </div>
        </section>
      </main>
    </div>
  );
}
