import cx from 'clsx';
import type { HTMLProps, ReactNode } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import type { UniqueIdentifier } from '@dnd-kit/core/dist/types/other';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { IconDragHandleOutlined } from '@repo/monochrome-icons';
import styles from './drag-handle.strict-module.css';

interface DragHandleProps extends Omit<HTMLProps<HTMLDivElement>, 'id'> {
  id: UniqueIdentifier;
}

export function DragHandle({ id, className, ...props }: DragHandleProps): ReactNode {
  const { attributes, listeners, isDragging } = useSortable({ id });

  const handleAttributes = {
    ...attributes,
    'aria-roledescription': undefined,
  };

  const keyboardListeners: SyntheticListenerMap | undefined = listeners?.onKeyDown
    ? {
        onKeyDown: listeners.onKeyDown,
      }
    : {};

  const pointerListeners: SyntheticListenerMap | undefined = listeners?.onPointerDown
    ? {
        onPointerDown: listeners.onPointerDown,
      }
    : {};

  return (
    <div
      // eslint-disable-next-line formatjs/no-literal-string-in-jsx -- We don't have a11y keys for now
      aria-label="Draggable item's handle"
      className={cx(styles.handle, isDragging && styles.dragging, className)}
      {...handleAttributes}
      {...keyboardListeners}
      {...pointerListeners}
      {...props}
    >
      <IconDragHandleOutlined height={16} width={16} />
    </div>
  );
}
