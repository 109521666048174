import { type ReactNode, type ComponentPropsWithoutRef } from 'react';
import cx from 'clsx';
import { Illustration } from './illustration';
import styles from './q-state.strict-module.css';

function QState({
  children,
  className = '',
  ...props
}: ComponentPropsWithoutRef<'div'>): ReactNode {
  return (
    <div className={cx(styles.wrapper, className)} {...props}>
      {children}
    </div>
  );
}

function TextContainer({ children, ...props }: ComponentPropsWithoutRef<'div'>): ReactNode {
  return <div {...props}>{children}</div>;
}

const QStateComponent = Object.assign(QState, {
  Illustration,
  Title: TextContainer,
  Legend: TextContainer,
});

export { QStateComponent as QState };
