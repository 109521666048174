import { useIntl } from 'react-intl';
import { TimeoutSvg } from '../../svgs/timeout';
import { EmailVerificationGeneric } from './generic';

export function Timeout(): React.ReactNode {
  const { formatMessage } = useIntl();
  return (
    <EmailVerificationGeneric
      data-testid="timeout"
      illustration={<TimeoutSvg />}
      subtitle={formatMessage({
        id: 'sca.sensitive-actions.accordion.confirm-device.timeout.subtitle',
      })}
      title={formatMessage({
        id: 'sca.sensitive-actions.accordion.confirm-device.timeout.title',
      })}
    />
  );
}
