/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactElement } from 'react';
import { Tab as AriaTab } from 'react-aria-components';
import cx from 'clsx';
import styles from './tab.strict-module.css';

export interface TabProps {
  id: string;
  icon?: ReactElement;
  title: string;
  count?: number;
  maxCount?: number;
  hasNotifications?: boolean;
  hasDivider?: boolean;
  'data-test-tabs-nav-item': number;
  'data-test-tabs-nav-item-value': string;
  isDisabled?: boolean;
}

export function Tab({
  id,
  icon,
  title,
  hasNotifications,
  hasDivider = false,
  count = 0,
  maxCount,
  isDisabled = false,
  ...props
}: TabProps): ReactElement {
  const hasCount = !icon && count > 0;
  return (
    <AriaTab
      className={cx(styles.tab, icon && styles['has-icon'], hasDivider && styles['with-divider'])}
      id={id}
      {...props}
      isDisabled={isDisabled}
    >
      {icon}
      {icon && hasNotifications ? (
        <span className={styles['notification-dot']} data-testid="notification-dot" />
      ) : null}
      {title}
      {hasCount ? (
        <span className="caption-bold ml-8" data-testid="counter">
          {maxCount && count > maxCount ? `${maxCount}+` : count}
        </span>
      ) : null}
    </AriaTab>
  );
}
