/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { RadioGroupProps as AriaRadioGroupProps } from 'react-aria-components';
import { Label, RadioGroup as AriaRadioGroup, FieldError, Button } from 'react-aria-components';
import type { ReactElement, ReactNode } from 'react';
import cx from 'clsx';
import { WarningSign, InfoIcon } from '../../assets/icons';
import { Tooltip } from '../tooltip';
import styles from './radio-group.strict-module.css';

export interface RadioGroupProps<T extends string> extends Omit<AriaRadioGroupProps, 'onChange'> {
  label?: string;
  errorMessage?: string;
  defaultValue?: T;
  value?: T;
  onChange?: (value: T) => void;
  children?: ReactNode;
  tooltipText?: string;
  labelClassName?: string;
}

export function RadioGroup<T extends string>({
  label,
  errorMessage,
  children,
  className,
  tooltipText,
  onChange: onChangeProps,
  labelClassName = 'mb-24 title-4',
  ...props
}: RadioGroupProps<T>): ReactElement {
  const mappedProps = onChangeProps
    ? {
        ...props,
        onChange: (value: string): void => {
          onChangeProps(value as T);
        },
      }
    : props;

  return (
    <AriaRadioGroup
      className={cx(styles.wrapper, className)}
      {...mappedProps}
      data-test-radio-group
    >
      {label ? (
        <Label className={cx(styles['group-label'], labelClassName)}>
          {label}
          {tooltipText ? (
            <Tooltip label={tooltipText} placement="top">
              <Button aria-label="tooltip" className={cx(styles.tooltip, 'ml-8')}>
                <InfoIcon />
              </Button>
            </Tooltip>
          ) : null}
        </Label>
      ) : null}
      {children}
      {errorMessage ? (
        <FieldError className={styles.error}>
          <WarningSign className="mr-8" />
          {errorMessage}
        </FieldError>
      ) : null}
    </AriaRadioGroup>
  );
}
