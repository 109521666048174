import {
  Disclosure as AriaDisclosure,
  DisclosurePanel as AriaDisclosurePanel,
  Button as AriaButton,
} from 'react-aria-components';
import type { DisclosureProps as AriaDisclosureProps } from 'react-aria-components';
import cx from 'clsx';
import { IconChevronBottomOutlined } from '@repo/monochrome-icons';
import styles from './accordion.strict-module.css';

type BaseAccordionSingleProps = {
  children: React.ReactNode;
  className?: string;
} & (
  | { title: string; icon?: React.ReactNode; header?: never }
  | { title?: never; icon?: never; header: JSX.Element }
);

export type AccordionSingleProps = BaseAccordionSingleProps & AriaDisclosureProps;

export function AccordionSingle({
  title,
  icon,
  header,
  children,
  className,
  ...props
}: AccordionSingleProps): React.ReactElement {
  return (
    <AriaDisclosure className={cx(styles.accordion, className)} {...props}>
      <div className={styles.accordion__header}>
        <AriaButton
          className={styles.accordion__header__clickable}
          preventFocusOnPress
          slot="trigger"
        >
          {title ? (
            <div className={cx('title-3', styles.accordion__label)}>
              {icon ? <span className={styles.accordion__label_icon}>{icon}</span> : null}
              {title}
            </div>
          ) : null}
          {header ? header : null}
          <IconChevronBottomOutlined aria-hidden="true" className={styles.accordion__arrow} />
        </AriaButton>
      </div>
      <AriaDisclosurePanel className={styles.accordion__content}>
        <main className={styles.accordion__content__inner} role="complementary">
          {children}
        </main>
      </AriaDisclosurePanel>
    </AriaDisclosure>
  );
}
