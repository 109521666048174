import { useEffect, useState } from 'react';
import { useDateFormatter } from 'react-aria';
import { getLocalTimeZone } from '@internationalized/date';
import type { DateFieldProps, DateValue, ValidationResult } from 'react-aria-components';
import { useIntl } from 'react-intl';

interface UseDatePickerValidationProps<T extends DateValue> {
  value: DateValue | null | undefined;
  onChange?: (newValue: T | null, isInvalid?: boolean) => void;
  errorMessage?: string;
  minValue?: DateFieldProps<T>['minValue'];
  maxValue?: DateFieldProps<T>['maxValue'];
  isRequired?: boolean;
  customValidationMessages: { [key in keyof ValidationResult['validationDetails']]?: string };
}
/**
 * This hook is used to validate the date field value.
 * It returns the error and invalid state.
 */
export function useDateFieldValidation<T extends DateValue>({
  value,
  onChange,
  minValue,
  maxValue,
  errorMessage,
  isRequired,
  customValidationMessages,
}: UseDatePickerValidationProps<T>): {
  error: string | undefined;
  isInvalid: boolean;
  handleChange: (newValue: T | null, isInvalid?: boolean) => void;
} {
  const [error, setError] = useState<string | undefined>(undefined);
  const isInvalid = Boolean(error);
  const intl = useIntl();
  const timeZone = getLocalTimeZone();

  const formatter = useDateFormatter({
    timeZone,
  });

  useEffect(() => {
    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    if (!value && !isRequired) {
      setError(undefined);
      return;
    }

    let errorMsg;
    if (isRequired && !value) {
      errorMsg =
        customValidationMessages.valueMissing ||
        intl.formatMessage({ id: 'datepicker-error-required' });
    } else if (minValue && value && value.compare(minValue) < 0) {
      errorMsg =
        customValidationMessages.rangeUnderflow ||
        intl.formatMessage(
          { id: 'datepicker-error-minimum-date' },
          { date: formatter.format(minValue.toDate(timeZone)) }
        );
    } else if (maxValue && value && value.compare(maxValue) > 0) {
      errorMsg =
        customValidationMessages.rangeOverflow ||
        intl.formatMessage(
          { id: 'datepicker-error-maximum-date' },
          { date: formatter.format(maxValue.toDate(timeZone)) }
        );
    }

    setError(errorMsg);
  }, [
    value,
    errorMessage,
    minValue,
    intl,
    maxValue,
    customValidationMessages,
    formatter,
    timeZone,
    isRequired,
  ]);

  const handleChange = (newValue: T | null): void => {
    onChange?.(newValue, isInvalid);
  };

  return {
    error,
    isInvalid,
    handleChange,
  };
}
