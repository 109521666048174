import { IntlProvider } from '../../providers/intl-provider';
import { SegmentProvider } from '../../providers/segment-provider';
import { EmailVerification } from '../email-verification';

export const RISKY_LOGIN_STATES = {
  DEVICE_CONFIRMED: 'device-confirmed',
  WRONG_DEVICE: 'wrong-device',
  TIMEOUT: 'timeout',
  GENERIC_ERROR: 'generic-error',
} as const;

export type RiskyLoginState = (typeof RISKY_LOGIN_STATES)[keyof typeof RISKY_LOGIN_STATES];

interface EmailVerificationScreenProps {
  status: RiskyLoginState;
}

function EmailVerificationContent({ status }: EmailVerificationScreenProps): React.ReactNode {
  switch (status) {
    case RISKY_LOGIN_STATES.DEVICE_CONFIRMED:
      return <EmailVerification.DeviceConfirmed />;
    case RISKY_LOGIN_STATES.WRONG_DEVICE:
      return <EmailVerification.WrongDevice />;
    case RISKY_LOGIN_STATES.TIMEOUT:
      return <EmailVerification.Timeout />;
    case RISKY_LOGIN_STATES.GENERIC_ERROR:
      return <EmailVerification.GenericError />;
  }
}

export function EmailVerificationScreen({ status }: EmailVerificationScreenProps): JSX.Element {
  return (
    <SegmentProvider>
      <IntlProvider>
        <EmailVerificationContent status={status} />
      </IntlProvider>
    </SegmentProvider>
  );
}
