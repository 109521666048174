import type { ReactNode } from 'react';
import cx from 'clsx';
import styles from './timeline-item.strict-module.css';

export interface TimelineItemProps {
  id: string;
  lineVariant?: 'solid' | 'dashed';
  hideLine?: boolean;
  slots: {
    media: ReactNode;
    title: ReactNode;
    content?: ReactNode;
  };
}

export function TimelineItem(props: TimelineItemProps & { 'data-testid': string }): ReactNode {
  const { slots, lineVariant = 'solid', hideLine = false, ...rest } = props;
  return (
    <li
      className={cx(
        styles['timeline-item'],
        styles[`variant-${lineVariant}`],
        hideLine && styles['hide-line']
      )}
      {...rest}
      data-test-variant={lineVariant}
    >
      <div className={styles['media-container']}>{slots.media}</div>
      <div className={styles['item-content']}>
        <div className={cx(styles['item-title'], 'body-2')}>{slots.title}</div>
        {slots.content !== undefined && (
          <div className={cx(styles['item-description'], 'caption')}>{slots.content}</div>
        )}
      </div>
    </li>
  );
}
