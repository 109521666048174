import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { type BasketItemProps } from '../types';
import { Quantity } from './quantity';
import styles from './basket-item.strict-module.css';

export function BasketItem({
  item,
  itemTotal,
  currency,
  isPreview = false,
}: BasketItemProps): ReactNode {
  const { formatNumber, formatMessage } = useIntl();
  const { title, description, measureUnit, vatRate, quantity, unitPrice } = item;

  const formatPercentage = (value: string): string => {
    return formatNumber(parseFloat(value), {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    });
  };

  const formatCurrency = (value: number): string => {
    return formatNumber(value, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
    });
  };

  const getQuantityHeader = (): string =>
    `${formatMessage({ id: 'payment-link.itemisation.header.quantity' })}: `;

  return (
    <tr className={cx(styles['item-row'], isPreview && styles.preview)} data-test-basket-item>
      <td className={styles['item-cell']} data-test-basket-item-title>
        <p className={cx(styles['item-title'], 'body-1')}>{title}</p>
        {description ? <p className={styles['item-description']}>{description}</p> : null}
      </td>
      <td className={cx(styles['quantity-cell'], 'body-2')} data-test-basket-item-quantity>
        <p>
          <Quantity quantity={quantity} unit={measureUnit} />
        </p>
      </td>
      <td className={cx(styles['vat-cell'], 'body-2')} data-test-basket-item-vat>
        <p>{formatPercentage(vatRate)}</p>
      </td>
      <td className={cx(styles['total-cell'], 'body-2')} data-test-basket-item-total>
        <p>{formatCurrency(itemTotal)}</p>
        <p className={cx(styles['quantity-subtext'], 'caption')}>
          {getQuantityHeader()}
          <Quantity quantity={quantity} unit={measureUnit} />
        </p>
        {quantity > 1 ? (
          <p className={cx(styles['unit-price-subtext'], 'caption')}>
            {formatMessage(
              { id: 'payment-link.itemisation.header.unit-price-each' },
              { unitPrice: formatCurrency(parseFloat(unitPrice.value)) }
            )}
          </p>
        ) : null}
      </td>
    </tr>
  );
}
