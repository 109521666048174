import type { ReactElement, ReactNode } from 'react';
import {
  ComboBox as AriaComboBox,
  type ComboBoxProps as AriaComboBoxProps,
  Button,
  ListBox,
  Popover,
  type ValidationResult,
} from 'react-aria-components';
import cx from 'clsx';
import { IconChevronBottomOutlined } from '@repo/monochrome-icons';
import { Description, Error, Input, Label } from '../form-elements';
import styles from './combo-box.strict-module.css';

interface ComboBoxProps<T extends object> extends Omit<AriaComboBoxProps<T>, 'children'> {
  label: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  placeholder: string;
  maxLength?: number;
  multiple?: boolean;
  isOptional?: boolean;
  isOptionalText?: string;
  children: ReactNode | ((item: T) => ReactNode);
  withArrowButton?: boolean;
}

export function ComboBox<T extends object>({
  label,
  className,
  description,
  errorMessage,
  placeholder,
  maxLength,
  multiple,
  isOptional,
  isOptionalText,
  children,
  withArrowButton,
  ...props
}: ComboBoxProps<T>): ReactElement {
  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <div> component with 'ember-testing' id)
  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;

  const isInvalid = Boolean(errorMessage);

  return (
    <AriaComboBox {...props} isInvalid={isInvalid}>
      {({ isOpen }) => (
        <>
          <div className={cx(className, styles.container)}>
            {Boolean(label) && (
              <Label>
                {label} {Boolean(isOptional) && isOptionalText}
              </Label>
            )}
            <span className={styles['input-container']}>
              <Input
                className={withArrowButton ? styles['input-with-arrow-button'] : ''}
                maxLength={maxLength}
                multiple={multiple}
                placeholder={placeholder}
              />
              {withArrowButton ? (
                <Button className={styles['chevron-icon-box']}>
                  <IconChevronBottomOutlined
                    className={cx(styles['chevron-icon'], isOpen && styles['chevron-icon-open'])}
                  />
                </Button>
              ) : null}
            </span>
            <Error>{errorMessage}</Error>
            {Boolean(description) && <Description>{description}</Description>}
          </div>
          <Popover
            UNSTABLE_portalContainer={TEST_PORTAL_CONTAINER}
            className={cx('body-2', styles.popover)}
            data-combo-box-popover
            offset={8}
            placement="bottom start"
          >
            <ListBox>{children}</ListBox>
          </Popover>
        </>
      )}
    </AriaComboBox>
  );
}
