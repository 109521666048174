import { useRef, useState, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { TextField } from '@repo/design-system-kit';
import type { Amount } from '../../../types/amount';
import type { Mollie } from '../../../types/mollie';
import { PaymentMethods } from '../../shared/constants';
import { PaymentMethodSelector } from '../../shared/payment-method';
import { CreditCardFields } from './internal/credit-card-fields';
import { Skeleton } from './internal/skeleton';
import styles from './checkout-form.strict-module.css';

export interface CheckoutData {
  email: string;
  method: PaymentMethods;
  token?: string;
}

interface CheckoutFormProps {
  payee: string;
  methods: PaymentMethods[];
  amount?: Amount<string>;
  mollie?: Mollie | null;
  disabled?: boolean;
  onCheckout?: ({ email, method, token }: CheckoutData) => void;
  isPreview?: boolean;
}

export function CheckoutForm({
  amount,
  disabled,
  methods,
  mollie,
  onCheckout,
  payee,
  isPreview = false,
}: CheckoutFormProps): ReactNode {
  const { formatMessage, locale } = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethods | null>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const hasCreditCard = methods.some(method => method === PaymentMethods.CreditCard);
  const otherMethods = methods.filter(method => method !== PaymentMethods.CreditCard);
  const shouldBeDisabled = disabled || isLoading;

  const handleButtonClick = (method: PaymentMethods): void => {
    setSelectedMethod(method);
    formRef.current?.requestSubmit();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (!mollie || !selectedMethod || !onCheckout) {
      return;
    }

    setIsLoading(true);
    setHasSubmitted(true);

    const formData = new FormData(event.currentTarget);
    const email = formData.get('email') as string;

    let token = null;
    let error = null;

    try {
      const mollieToken = await mollie.createToken();
      token = mollieToken.token;
      error = mollieToken.error;
    } catch (err) {
      return;
    } finally {
      setIsLoading(false);
    }

    if (error) {
      return;
    }

    onCheckout({ method: selectedMethod, token, email });
  };

  if (methods.length === 0 || !amount) {
    return <Skeleton payee={payee} />;
  }

  return (
    <form
      aria-busy={isLoading}
      aria-live="polite"
      className={cx(styles.container, isPreview && styles.preview)}
      data-testid="checkout-form"
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <h2 className={cx('title-2 mb-32', styles.title)}>
        {formatMessage({ id: 'payment-link.payment-details.title' }, { merchant: payee })}
      </h2>

      <h3 className="title-3 mb-16">
        {formatMessage({ id: 'payment-link.payment-details.contact-details' })}
      </h3>

      <TextField
        className="mb-32"
        errorMessage={({ validationDetails }) => {
          if (validationDetails.valueMissing) {
            return formatMessage({ id: 'payment-link.payment-details.email.error' });
          }
          if (validationDetails.typeMismatch) {
            return formatMessage({ id: 'payment-link.payment-details.email.invalid.error' });
          }
          return '';
        }}
        isDisabled={shouldBeDisabled}
        isRequired
        label={formatMessage({ id: 'payment-link.payment-details.email.label' })}
        name="email"
        placeholder={formatMessage({ id: 'payment-link.payment-details.email.placeholder' })}
        type="email"
      />

      <h3 className="title-3 mb-16">
        {formatMessage({ id: 'payment-link.payment-details.payment-methods' })}
      </h3>

      <div className={styles['payment-methods-container']}>
        {hasCreditCard ? (
          <CreditCardFields
            amount={amount}
            disabled={shouldBeDisabled}
            hasSubmitted={hasSubmitted}
            isLoading={selectedMethod === PaymentMethods.CreditCard && isLoading}
            mollie={mollie}
            onSubmit={() => {
              handleButtonClick(PaymentMethods.CreditCard);
            }}
          />
        ) : null}

        {Boolean(otherMethods.length) && (
          <div>
            <h4 className="title-4 mb-16">
              {hasCreditCard
                ? formatMessage(
                    { id: 'receivable-invoices.public-payment-link.other-methods' },
                    { language: locale }
                  )
                : formatMessage(
                    { id: 'receivable-invoices.public-payment-link.select-method' },
                    { language: locale }
                  )}
            </h4>

            <div className={styles['payment-methods-list']} data-testid="payment-methods">
              {otherMethods.map(method => (
                <PaymentMethodSelector
                  isDisabled={shouldBeDisabled}
                  isLoading={selectedMethod === method && isLoading}
                  key={method}
                  method={method}
                  onPress={() => {
                    handleButtonClick(method);
                  }}
                  selectedLocale={locale}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </form>
  );
}
