import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { SCA_STATUSES, type ScaStatus } from '../components';
import { httpClient } from '../utils/fetch-client';
import { authBaseURL } from '../utils/environment';
import type { OnSensitiveAction } from '../domain/sensitive-action';

const SCA_STATUS_URL = `${authBaseURL}/v3/secure/sca_sessions`;

function getScaAuthStatus({ token }: { token: string }): Promise<{
  result: ScaStatus;
}> {
  const url = `${SCA_STATUS_URL}/${token}`;
  return httpClient.get(url);
}

export function useScaAuthStatus({
  token,
  onSensitiveAction,
}: {
  token: string;
  onSensitiveAction: OnSensitiveAction;
}): UseQueryResult<{
  result: ScaStatus;
  canceled_at?: string;
}> {
  return useQuery({
    queryKey: ['sca-auth-status', token],
    queryFn: async () => {
      try {
        const scaAuthStatus = await getScaAuthStatus({ token });

        if (scaAuthStatus.result === SCA_STATUSES.ALLOW) {
          await onSensitiveAction({
            header: 'X-Qonto-Sca-Session-Token',
            token,
          });
        }

        return scaAuthStatus;
      } catch (error) {
        return {
          result: SCA_STATUSES.ERROR,
        };
      }
    },
    enabled: Boolean(token),
    refetchIntervalInBackground: true,
    staleTime: 60 * 1000,
    refetchInterval: ({ state: { data } }) => {
      if (
        data?.result === SCA_STATUSES.ERROR ||
        data?.result === SCA_STATUSES.ALLOW ||
        data?.result === SCA_STATUSES.DENY
      ) {
        return false;
      }

      return 1000;
    },
  });
}
