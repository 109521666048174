/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { IconFileAttachmentOutlined, IconTrashOutlined } from '@repo/monochrome-icons';
import { useEffect, useState, type ReactElement } from 'react';
import { Button } from 'react-aria-components';
import { useIntl } from 'react-intl';
import { useFormatBytes } from '../../hooks/use-format-bytes';
import styles from './attachment.strict-module.css';

interface AttachmentProps {
  className?: string;
  file: File;
  onRemove?: (file: File) => void;
}

export function Attachment({ className, file, onRemove }: AttachmentProps): ReactElement {
  const { formatMessage } = useIntl();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const fileSize = useFormatBytes(file.size);
  const fileURL = file instanceof File ? URL.createObjectURL(file) : '';

  useEffect(() => {
    if (!(file instanceof File)) {
      setErrorMessage(formatMessage({ id: 'something-wrong' }));
    }
  }, [file, formatMessage]);

  return (
    <div className={`${styles.attachment} ${className || ''}`}>
      <a href={fileURL} rel="noreferrer" target="_blank">
        <span className={`${styles.illustration} ${errorMessage ? styles.error : ''}`}>
          <IconFileAttachmentOutlined className={styles.icon} />
        </span>
      </a>
      <div className={styles.info}>
        <p className="body-1">{file.name}</p>
        {!errorMessage && <p className={`body-2 ${styles.fileSize}`}>{fileSize}</p>}
        {Boolean(errorMessage) && (
          <p className={`caption ${styles.errorMessage}`}>{errorMessage}</p>
        )}
      </div>
      {Boolean(onRemove) && (
        <Button
          aria-label="Remove file"
          className={styles.removeButton}
          onPress={() => {
            onRemove ? onRemove(file) : null;
          }}
        >
          <IconTrashOutlined className={styles.icon} />
        </Button>
      )}
    </div>
  );
}
