export enum StandingFrequency {
  None = 'STANDING_FREQUENCY_NONE',
  Weekly = 'STANDING_FREQUENCY_WEEKLY',
  Monthly = 'STANDING_FREQUENCY_MONTHLY',
  Quarterly = 'STANDING_FREQUENCY_QUARTERLY',
  HalfYearly = 'STANDING_FREQUENCY_HALF_YEARLY',
  Yearly = 'STANDING_FREQUENCY_YEARLY',
}

export interface SchedulingOptions {
  startDate: string;
  standingFrequency: StandingFrequency;
  endDate?: string | null;
}
