import styles from './qonto-logo.strict-module.css';

export function QontoLogoThemedSvg(props: React.SVGProps<SVGSVGElement>): React.ReactNode {
  return (
    <svg
      fill="none"
      height="244"
      viewBox="0 0 825 244"
      width="825"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={styles.qontoLogo}
    >
      <g clipPath="url(#clip0_1943_16601)">
        <path
          d="M115.643 238.958C136.207 239.078 156.452 233.755 174.419 223.506L175.311 222.98L176.203 223.517C192.613 233.714 210.855 240.427 229.847 243.255L234.309 209.62C224.22 208.096 214.404 205.065 205.173 200.625L203.087 199.619L204.57 197.802C222.229 176.304 231.565 149.217 231.565 119.479C231.565 51.362 181.714 0 115.621 0C49.7063 0 0 51.362 0 119.479C0 187.596 49.7287 238.958 115.643 238.958ZM115.643 33.6584C161.01 33.6584 195.222 70.5513 195.222 119.479C195.222 141.343 188.173 161.903 175.389 177.367L174.162 178.841L172.823 177.493C157.778 162.409 145.817 144.404 137.641 124.531L105.013 137.743C114.128 159.983 127.299 180.239 143.821 197.425L145.773 199.436L143.174 200.408C134.356 203.682 125.045 205.342 115.666 205.311C70.2984 205.311 36.075 168.407 36.075 119.479C36.075 70.5513 70.2761 33.6584 115.643 33.6584Z"
          fill="#1D1D1B"
        />
        <path
          d="M326.522 74.7227C279.672 74.7227 244.444 111.021 244.444 159.16C244.444 207.162 279.728 243.255 326.522 243.255C373.317 243.255 408.6 207.105 408.6 159.16C408.6 111.021 373.317 74.7227 326.522 74.7227ZM326.522 209.585C299.148 209.585 278.556 187.87 278.556 159.172C278.556 130.199 299.204 108.347 326.522 108.347C353.841 108.347 374.489 130.199 374.489 159.172C374.533 187.458 353.439 209.608 326.522 209.608V209.585Z"
          fill="#1D1D1B"
        />
        <path
          d="M491.086 111.742C515.627 111.742 530.218 126.989 530.218 150.498V243.256H563.449V147.892C563.449 108.108 535.238 81.524 497.835 81.524C472.971 81.524 452.301 98.2103 452.1 98.4389V81.4668H418.736V243.256H452.133V149.138C452.133 124.291 472.324 111.742 491.086 111.742Z"
          fill="#1D1D1B"
        />
        <path
          d="M627.897 243.255C635.573 243.163 643.213 242.162 650.664 240.272V206.922C645.824 208.311 640.84 209.109 635.817 209.299C616.921 209.299 606.089 199.299 606.089 181.87V110.301L650.709 110.495V78.3799L606.089 78.0485V35.1211H573.584V175.206C573.584 217.185 594.399 243.255 627.897 243.255Z"
          fill="#1D1D1B"
        />
        <path
          d="M742.922 74.7227C696.138 74.7227 660.844 111.021 660.844 159.16C660.844 207.162 696.138 243.255 742.922 243.255C789.706 243.255 825 207.105 825 159.16C825 111.021 789.717 74.7227 742.922 74.7227ZM742.922 209.585C715.559 209.585 694.956 187.87 694.956 159.172C694.956 130.199 715.604 108.347 742.922 108.347C770.24 108.347 790.944 130.199 790.944 159.172C790.944 187.458 769.85 209.608 742.922 209.608V209.585Z"
          fill="#1D1D1B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1943_16601">
          <rect fill="white" height="243.255" width="825" />
        </clipPath>
      </defs>
    </svg>
  );
}
