export const UNIT_CODES = [
  'unit',
  'hour',
  'day',
  'month',
  'gram',
  'kilogram',
  'liter',
  'meter',
  'square_meter',
  'cubic_meter',
  'ampere',
  'number_of_articles',
  'centimeter',
  'cubic_meter_per_hour',
  'gigajoule',
  'gigawatt',
  'gigawatt_hour',
  'half_year',
  'joule',
  'kilojoule',
  'kilovar',
  'kilowatt',
  'kilowatt_hour',
  'megajoule',
  'megawatt',
  'megawatt_hour',
  'milligram',
  'milliliter',
  'millimeter',
  'minute',
  'pair',
  'quarter',
  'second',
  'set',
  'ton',
  'two_week',
  'watt_hour',
  'week',
  'year',
];

export type Unit = (typeof UNIT_CODES)[number];
