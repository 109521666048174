import * as React from 'react';
import { useIntl } from 'react-intl';
import type { MessageDescriptor } from 'react-intl';

/**
 * A drop-in replacement for react-intl's <FormattedMessage /> that allows raw HTML
 * Necessary until we're fully migrated to React end-to-end, as Ember currently has
 * many raw HTML usages.
 */
export function FormattedMessage(props: MessageDescriptor): React.JSX.Element {
  const intl = useIntl();

  return <span dangerouslySetInnerHTML={{ __html: intl.formatMessage(props) }} />;
}
