import type { ReactNode } from 'react';
import { IconCheckmarkRoundedFilled, IconCheckmarkRoundedOutlined } from '@repo/monochrome-icons';
import { motion } from 'framer-motion';
import styles from './loading-state-steps.strict-module.css';
import type { LoadingStateStep } from './loading-state';

export function LoadingStateSteps({
  steps,
  progress,
}: {
  steps: LoadingStateStep[];
  progress: number;
}): ReactNode {
  return (
    <>
      {steps.map((step, index) => {
        const isCompleted = index < progress;
        const completedInitialOpacity = progress > index + 1 ? 1 : 0.3;

        return (
          <ul className={styles.step} key={`${step.label}-${progress}`}>
            {isCompleted ? (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: completedInitialOpacity }}
                key={`filled-icon-${progress}`}
                transition={{ duration: 0.5 }}
              >
                <IconCheckmarkRoundedFilled aria-hidden="true" />
              </motion.div>
            ) : (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 1 }}
                key={`outlined-icon-${progress}`}
                transition={{ duration: 0.3 }}
              >
                <IconCheckmarkRoundedOutlined aria-hidden="true" />
              </motion.div>
            )}
            <li className="body-1" data-test-loading-state-step-label={index}>
              {step.label}
            </li>
          </ul>
        );
      })}
    </>
  );
}
