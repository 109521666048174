export enum BlackCardDesign {
  MetalGraphiteBlack2019 = 'metal.graphite.black.2019',
  PlusBlack2023 = 'plus.black.2023',
  PlusRecycledPlasticBlack2023 = 'plus.recycled.plastic.black.2023',
}

export enum LightCardDesign {
  AdvertisingDefault2023 = 'advertising.default.2023',
  FlashDefault2021 = 'flash.default.2021',
  MetalMineralGray2024 = 'metal.mineral.gray.2024',
  MetalSandGold2024 = 'metal.sand.gold.2024',
  PlusRecycledPlasticSilver2023 = 'plus.recycled.plastic.silver.2023',
  PlusRecycledPlasticLilac2023 = 'plus.recycled.plastic.lilac.2023',
  StandardRecycledPlastic2023 = 'standard.recycled.plastic.2023',
  VirtualDefault2017 = 'virtual.default.2017',
}

export type CardDesign = BlackCardDesign | LightCardDesign;

export enum CardLevel {
  Advertising = 'advertising',
  Flash = 'flash',
  Metal = 'metal',
  Plus = 'plus',
  Standard = 'standard',
  Virtual = 'virtual',
}

export type PhysicalCardLevel = CardLevel.Metal | CardLevel.Plus | CardLevel.Standard;
