import { useIntl } from 'react-intl';
import { WrongDeviceSvg } from '../../svgs/wrong-device';
import { EmailVerificationGeneric } from './generic';

export function WrongDevice(): React.ReactNode {
  const { formatMessage } = useIntl();
  return (
    <EmailVerificationGeneric
      data-testid="wrong-device"
      illustration={<WrongDeviceSvg />}
      subtitle={formatMessage({
        id: 'sca.sensitive-actions.accordion.confirm-device.failed.subtitle',
      })}
      title={formatMessage({
        id: 'sca.sensitive-actions.accordion.confirm-device.failed.title',
      })}
    />
  );
}
