import type { ComponentPropsWithoutRef } from 'react';
import React from 'react';
import cx from 'clsx';
import styles from './toggle-button.strict-module.css';

interface Item {
  value: string;
  label: string;
}

export interface ToggleButtonProps extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  items: [Item, Item];
  name: string;
  onChange?: (value: string) => void;
  value: string;
  disabled?: boolean;
  variant?: 'default' | 'accent';
  className?: string;
}

export function ToggleButton({
  items,
  name,
  onChange,
  value,
  disabled,
  variant = 'default',
  className,
  ...props
}: ToggleButtonProps): React.ReactElement {
  const isSelected = (item: Item): boolean => {
    return item.value === value;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (disabled) return;
    onChange?.(event.target.value);
  };

  return (
    <div
      aria-disabled={disabled}
      className={cx(styles.container, styles[variant], disabled && styles.disabled, className)}
      data-test-toggle-button
      data-test-variant={variant}
      role="radiogroup"
      tabIndex={disabled ? -1 : 0}
      {...props}
    >
      <div className={styles.grid}>
        <div className={cx(styles.background, value === items[1].value && styles.right)} />
        {items.map((item, index) => (
          <React.Fragment key={item.value}>
            <input
              checked={isSelected(item)}
              className={styles.input}
              data-test-value={item.value}
              disabled={disabled}
              id={`${name}-${index}`}
              name={name}
              onChange={handleChange}
              type="radio"
              value={item.value}
            />
            <label
              className={cx(styles.label, isSelected(item) && styles.selected, 'body-1')}
              data-test-label={item.value}
              htmlFor={`${name}-${index}`}
            >
              {item.label}
            </label>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
