import type { ReactNode } from 'react';
import cx from 'clsx';
import styles from './row.strict-module.css';

interface RowProps {
  content?: ReactNode;
  label: string;
  color?: 'danger';
}

export function Row({
  children,
  label,
  color,
  content,
  className,
  ...props
}: RowProps & React.HTMLAttributes<HTMLDivElement>): ReactNode {
  if (!children && !content) {
    return null;
  }

  return (
    <div className={cx(styles.row, className)} data-test-info-list-row {...props}>
      <dt className={cx(styles.label, color === 'danger' && styles.danger)}>{label}</dt>
      <dd className={styles.content}>{children || content}</dd>
    </div>
  );
}
