import React, { isValidElement } from 'react';
import { Avatar } from '@repo/design-system-kit';
import { IconClockOutlined } from '@repo/monochrome-icons';
import styles from './counterparty.strict-module.css';

export interface CounterpartyProps {
  title: string;
  subtitle?: React.ReactNode;
  imageUrl?: string;
  showAvatar?: boolean;
  isCompact?: boolean;
  isScheduled?: boolean;
}

export function Counterparty({
  title,
  showAvatar = true,
  subtitle,
  imageUrl,
  isCompact = false,
  isScheduled = false,
  ...props
}: CounterpartyProps): React.ReactNode {
  const renderAvatar = (): React.ReactNode => {
    return imageUrl ? <Avatar src={imageUrl} /> : <Avatar name={title} />;
  };

  return (
    <div className={`${styles.container} ${isCompact ? styles.compact : ''}`} {...props}>
      {showAvatar ? (
        <div className={styles.avatarContainer}>
          {renderAvatar()}
          {isScheduled ? (
            <div className={styles.recurringIcon} data-testid="scheduled-icon">
              <IconClockOutlined />
            </div>
          ) : null}
        </div>
      ) : null}
      <div className={styles.content}>
        <p className={styles.title} data-testid="counterparty-title">
          {title}
        </p>
        <Subtitle subtitle={subtitle} />
      </div>
    </div>
  );
}

function Subtitle({ subtitle }: { subtitle: React.ReactNode }): React.ReactNode {
  if (typeof subtitle === 'string') {
    return (
      <p className={styles.subtitle} data-testid="counterparty-subtitle">
        {subtitle}
      </p>
    );
  }

  if (isValidElement(subtitle)) {
    return (
      <div className={styles.subtitle} data-testid="counterparty-subtitle">
        {subtitle}
      </div>
    );
  }

  return null;
}
