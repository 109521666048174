import cx from 'clsx';
import type { ReactNode } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { IconArrowRightOutlined } from '@repo/monochrome-icons';
import { BalanceCell, Counterparty, ExpectedDate } from '../../../../shared';
import styles from './tooltip.strict-module.css';

interface TooltipData {
  side?: 'credit' | 'debit';
  date?: string | Date;
  metadata: { logo_url: string };
  predicted_balance?: { value: string; currency: string };
  total_amount_for_the_day?: { value: string; currency: string };
  total_transactions?: number;
  inflowsTransactions?: {
    amount: number;
    currency: string;
    counterparty_name?: string;
    metadata?: { logo?: string };
  }[];
  outflowsTransactions?: {
    amount: number;
    currency: string;
    counterparty_name?: string;
    metadata?: { logo?: string };
  }[];
}

interface TooltipProps {
  activeTooltipIndex: number | null;
  dataLength: number;
  active?: boolean;
  payload?: {
    payload: TooltipData;
  }[];
  coordinate?: { x: number; y: number };
  hoveredIndex?: number | null;
  hoveredKey?: string | null;
  scheduledTransactionPreview?: {
    newDate: string;
    amount: string;
    type: 'inflows' | 'outflows';
    counterparty_name?: string;
    metadata?: {
      logo?: string;
    };
  } | null;
  highlightedIndex?: number | null;
}

export function CustomTooltip({
  active,
  activeTooltipIndex,
  dataLength,
  payload,
  coordinate,
  hoveredIndex,
  hoveredKey,
  scheduledTransactionPreview,
  highlightedIndex,
}: TooltipProps): ReactNode {
  const { formatMessage } = useIntl();

  if (!active || !payload?.length) return null;

  const data: TooltipData | null = payload[0]?.payload || null;
  if (!data) return null;

  const {
    side,
    date,
    predicted_balance: balance,
    total_amount_for_the_day: netChange,
    inflowsTransactions = [],
    outflowsTransactions = [],
  } = data;

  let transactionCount = 0;
  if (hoveredIndex === null) {
    transactionCount = inflowsTransactions.length + outflowsTransactions.length;
  } else if (hoveredKey === 'inflows') {
    transactionCount = inflowsTransactions.length;
  } else {
    transactionCount = outflowsTransactions.length;
  }

  const transactionsLabel = formatMessage(
    { id: 'cash-flow.upcoming-transactions.tooltip.label' },
    { count: transactionCount }
  );

  // Adjust tooltip position
  let left = coordinate?.x ?? 0;
  if (activeTooltipIndex ? activeTooltipIndex <= 1 : true) left += 89;
  if ((activeTooltipIndex ?? 0) >= dataLength - 4) left -= 89;

  const renderTransactionInfo = (): JSX.Element => {
    if (hoveredIndex !== null && hoveredKey) {
      const transactions = hoveredKey === 'inflows' ? inflowsTransactions : outflowsTransactions;

      if (transactions.length === 1) {
        const transaction = transactions[0];
        const title = transaction?.counterparty_name || '';
        const imageUrl = transaction?.metadata?.logo || '';

        return <Counterparty imageUrl={imageUrl} isCompact title={title} />;
      }
    }

    return <p className="body-2">{transactionsLabel}</p>;
  };

  // Render financial details only when hoveredIndex is null
  const renderFinancialDetails = (): JSX.Element | null => {
    if (hoveredIndex !== null) return null;

    return (
      <>
        {/* Net Change */}
        <div className={cx(styles.row, styles.divider)}>
          <p className="body-2">
            <FormattedMessage id="overview.net-change.label" />
          </p>
          <div className={cx('body-2', styles['value-column'])}>
            <BalanceCell
              amount={{
                value: Math.abs(parseFloat(netChange?.value ?? '0')),
                currency: netChange?.currency ?? 'EUR',
              }}
              displayCurrencySymbol
              side={side}
            />
          </div>
        </div>

        {/* Balance */}
        <div className={styles.row}>
          <p className="body-2">
            <FormattedMessage id="cash-flow.balance.selected-period-header" />
          </p>
          <div className={cx('body-2', styles['value-column'])}>
            <BalanceCell
              amount={{
                ...(balance ?? { value: '0', currency: 'EUR' }),
                value: parseFloat(balance?.value ?? '0'),
              }}
              displayColor={false}
              displayCurrencySymbol
              displaySign={false}
              side="credit"
            />
          </div>
        </div>
      </>
    );
  };

  // If we have a scheduled transaction preview, display a special tooltip
  if (scheduledTransactionPreview && highlightedIndex === activeTooltipIndex) {
    return (
      <div
        className={styles.tooltip}
        data-testid="custom-tooltip-scheduled"
        style={{
          top: '135px',
          left,
          transform: 'translate(-50%)',
        }}
      >
        {/* Scheduled Transaction Info */}
        <div className={styles.row}>
          <Counterparty
            imageUrl={scheduledTransactionPreview.metadata?.logo || ''}
            isCompact
            title={scheduledTransactionPreview.counterparty_name ?? ''}
          />
          <div className={cx('body-2', styles['value-column'])}>
            <div className={styles['date-with-icon']}>
              <IconArrowRightOutlined className={styles['arrow-icon']} />
              <ExpectedDate date={scheduledTransactionPreview.newDate} />
            </div>
          </div>
        </div>

        {/* Amount */}
        <div className={cx(styles.row, styles.divider)}>
          <p className="body-2">
            <FormattedMessage id="cash-flow.upcoming-transactions.form.amount.label" />
          </p>
          <div className={cx('body-2', styles['value-column'])}>
            <BalanceCell
              amount={{
                value: parseFloat(scheduledTransactionPreview.amount),
                currency: 'EUR',
              }}
              displayCurrencySymbol
              side={scheduledTransactionPreview.type === 'inflows' ? 'credit' : 'debit'}
            />
          </div>
        </div>
      </div>
    );
  }

  // Regular tooltip
  return (
    <div
      className={styles.tooltip}
      data-testid="custom-tooltip"
      style={{
        top: '135px',
        left,
        transform: 'translate(-50%)',
      }}
    >
      {/* Transactions Info */}
      <div className={styles.row}>
        {renderTransactionInfo()}
        <div className={cx('body-2', styles['value-column'])}>
          <ExpectedDate date={typeof date === 'string' ? date : (date?.toISOString() ?? '')} />
        </div>
      </div>

      {/* Conditional Financial Details */}
      {renderFinancialDetails()}
    </div>
  );
}
