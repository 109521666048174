import type React from 'react';
import { useIntl } from 'react-intl';
import { PaymentMethods } from '../../constants';

interface PaymentMethodTitleProps {
  method?: PaymentMethods;
  defaultTitle?: string;
  selectedLocale?: string;
}

export function PaymentMethodTitle({
  method,
  defaultTitle = '-',
  selectedLocale,
}: PaymentMethodTitleProps): React.ReactNode {
  const { formatMessage } = useIntl();

  if (method === PaymentMethods.ApplePay) {
    return formatMessage({ id: 'receivable-invoices.public-payment-link.methods.apple-pay' });
  }
  if (method === PaymentMethods.BankTransfer) {
    return formatMessage(
      { id: 'receivable-invoices.public-payment-link.methods.bank-transfer' },
      { language: selectedLocale }
    );
  }
  if (method === PaymentMethods.CreditCard) {
    return formatMessage(
      { id: 'receivable-invoices.public-payment-link.methods.credit-card' },
      { language: selectedLocale }
    );
  }
  if (method === PaymentMethods.Paypal) {
    return formatMessage({ id: 'receivable-invoices.public-payment-link.methods.paypal' });
  }
  if (method === PaymentMethods.Ideal) {
    return formatMessage({ id: 'payment-link.payment-details.methods.ideal' });
  }

  return defaultTitle;
}
