import { useState, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { clsx } from 'clsx';
import type { Mollie } from '../../../types/mollie.js';
import { PaymentMethods } from '../../shared/constants';
import { PaymentMethodSelector } from '../../shared/payment-method';
import { CheckoutHeader, type CheckoutHeaderProps } from '../checkout-header';
import { CreditCardFields } from '../credit-card-fields';
import styles from './checkout-panel.strict-module.css';

interface CheckoutPanelProps extends CheckoutHeaderProps {
  disabled?: boolean;
  methods: PaymentMethods[];
  mollie?: Mollie | null;
  onCreditCardSubmit?: (token: string) => void;
  onMethodClick: (method: PaymentMethods) => void;
  children?: ReactNode;
}

export function CheckoutPanel({
  disabled,
  methods,
  mollie,
  onCreditCardSubmit,
  onMethodClick,
  selectedLocale,
  children,
  ...props
}: CheckoutPanelProps): ReactNode {
  const { formatMessage } = useIntl();

  const [isLoading, setIsLoading] = useState(false);

  const hasCreditCard = methods.some(method => method === PaymentMethods.CreditCard);
  const otherMethods = methods.filter(method => method !== PaymentMethods.CreditCard);

  const shouldBeDisabled = disabled || isLoading;

  return (
    <div className={styles.container} data-test-checkout-panel>
      <CheckoutHeader {...props} selectedLocale={selectedLocale} />
      {children}
      <h2 className={clsx('title-2', styles['content-title'])}>
        {formatMessage(
          { id: 'receivable-invoices.public-payment-link.header' },
          { language: selectedLocale }
        )}
      </h2>
      {hasCreditCard ? (
        <CreditCardFields
          disabled={shouldBeDisabled}
          mollie={mollie}
          onSubmit={onCreditCardSubmit}
          selectedLocale={selectedLocale}
          setIsLoading={setIsLoading}
        />
      ) : null}
      {Boolean(otherMethods.length) && (
        <div>
          <div className={clsx('title-4', styles['content-title'])}>
            {hasCreditCard
              ? formatMessage(
                  { id: 'receivable-invoices.public-payment-link.other-methods' },
                  { language: selectedLocale }
                )
              : formatMessage(
                  { id: 'receivable-invoices.public-payment-link.select-method' },
                  { language: selectedLocale }
                )}
          </div>
          <div className={styles['payment-methods-list']}>
            {otherMethods.map(method => (
              <PaymentMethodSelector
                data-testid-payment-method={method}
                isDisabled={shouldBeDisabled}
                key={method}
                method={method}
                onPress={() => {
                  onMethodClick(method);
                }}
                selectedLocale={selectedLocale}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
