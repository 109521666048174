import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import {
  Select as AriaSelect,
  type SelectProps as AriaSelectProps,
  ListBox,
  Popover,
  SelectValue,
  type ValidationResult,
} from 'react-aria-components';
import cx from 'clsx';
import { isNil } from 'es-toolkit';
import { IconChevronBottomOutlined, IconChevronTopOutlined } from '@repo/monochrome-icons';
import { Description, Error, Label, Trigger } from '../form-elements';
import styles from './select.strict-module.css';

interface SelectProps<T extends object> extends AriaSelectProps<T> {
  label: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  placeholder: string;
  multiple?: boolean;
  isOptional?: boolean;
  isOptionalText?: string;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

export function Select<T extends object>({
  label,
  description,
  errorMessage,
  isOptional,
  isOptionalText,
  children,
  defaultOpen = false,
  isOpen,
  onOpenChange,
  isDisabled,
  isReadOnly,
  ...props
}: SelectProps<T>): ReactElement {
  const [internalIsOpen, setInternalIsOpen] = useState(defaultOpen);

  function handleOpenChange(open: boolean): void {
    setInternalIsOpen(open);
    onOpenChange?.(open);
  }

  useEffect(() => {
    if (!isNil(isOpen)) {
      setInternalIsOpen(isOpen);
    }
  }, [isOpen]);

  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <div> component with 'ember-testing' id)
  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;

  const isInvalid = Boolean(errorMessage);

  return (
    <AriaSelect
      isDisabled={isDisabled || isReadOnly}
      isInvalid={isInvalid}
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      {...props}
    >
      <div className={styles.container}>
        {Boolean(label) && (
          <Label>
            {label} {Boolean(isOptional) && isOptionalText}
          </Label>
        )}
        <Trigger
          className={styles['input-container']}
          isInvalid={isInvalid}
          isReadOnly={isReadOnly}
        >
          <SelectValue className={styles['input-value']} />
          {internalIsOpen ? (
            <IconChevronTopOutlined aria-hidden />
          ) : (
            <IconChevronBottomOutlined aria-hidden />
          )}
        </Trigger>
        <Error>{errorMessage}</Error>
        {Boolean(description) && <Description>{description}</Description>}
      </div>
      <Popover
        UNSTABLE_portalContainer={TEST_PORTAL_CONTAINER}
        className={cx('body-2', styles.popover)}
        offset={8}
        placement="bottom start"
      >
        <ListBox>{children}</ListBox>
      </Popover>
    </AriaSelect>
  );
}
