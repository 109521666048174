import React, { useState } from 'react';
import { Form } from 'react-aria-components';
import { useIntl } from 'react-intl';
import { Button } from '@repo/design-system-kit';
import type { Mollie } from '../../../types/mollie.js';
import { CreditCardIcons } from '../../shared/credit-card-icons';
import { CreditCardInput } from '../../shared/credit-card-input';
import styles from './credit-card-fields.strict-module.css';

interface CreditCardFieldsProps {
  disabled?: boolean;
  mollie?: Mollie | null;
  onSubmit?: (token: string) => void;
  setIsLoading?: (loading: boolean) => void;
  selectedLocale?: string;
}

export function CreditCardFields({
  disabled,
  mollie,
  onSubmit,
  setIsLoading,
  selectedLocale,
}: CreditCardFieldsProps): React.ReactNode {
  const { formatMessage } = useIntl();

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const onPress = async (): Promise<void> => {
    if (!mollie) {
      return;
    }

    setIsLoading?.(true);
    setHasSubmitted(true);

    const { token, error } = await mollie.createToken();

    setIsLoading?.(false);

    if (error) {
      return;
    }

    onSubmit?.(token);
  };

  // If `mollie` is undefined it means we are not expecting Mollie because Mollie default value is `null`
  const withMollie = typeof mollie !== 'undefined';
  const isMollieLoading = withMollie && !mollie;

  return (
    <Form className={styles.container}>
      {!isMollieLoading ? (
        <div className={styles['card-fields']}>
          <CreditCardInput
            disabled={disabled}
            hasSubmitted={hasSubmitted}
            label={formatMessage(
              {
                id: 'receivable-invoices.public-payment-link.credit-card.labels.number',
              },
              { language: selectedLocale }
            )}
            mollie={mollie}
            type="cardNumber"
            withMollie={withMollie}
          />
          <CreditCardInput
            disabled={disabled}
            hasSubmitted={hasSubmitted}
            label={formatMessage(
              {
                id: 'receivable-invoices.public-payment-link.credit-card.labels.name',
              },
              { language: selectedLocale }
            )}
            mollie={mollie}
            type="cardHolder"
            withMollie={withMollie}
          />
          <div className={styles.asides}>
            <CreditCardInput
              disabled={disabled}
              hasSubmitted={hasSubmitted}
              label={formatMessage(
                {
                  id: 'receivable-invoices.public-payment-link.credit-card.labels.expiration-date',
                },
                { language: selectedLocale }
              )}
              mollie={mollie}
              type="expiryDate"
              withMollie={withMollie}
            />
            <CreditCardInput
              disabled={disabled}
              hasSubmitted={hasSubmitted}
              label={formatMessage(
                {
                  id: 'receivable-invoices.public-payment-link.credit-card.labels.security-code',
                },
                { language: selectedLocale }
              )}
              mollie={mollie}
              type="verificationCode"
              withMollie={withMollie}
            />
          </div>
        </div>
      ) : null}
      <div className={styles.footnote}>
        <span className="caption">
          {formatMessage(
            { id: 'receivable-invoices.public-payment-link.credit-card.footnote' },
            { language: selectedLocale }
          )}
        </span>
        <CreditCardIcons />
      </div>
      <Button
        className="mb-16"
        data-pay-invoice-button
        isDisabled={isMollieLoading || disabled}
        onPress={() => void onPress()}
      >
        {formatMessage(
          { id: 'receivable-invoices.public-payment-link.cta' },
          { language: selectedLocale }
        )}
      </Button>
    </Form>
  );
}
