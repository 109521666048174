import type { AriaToastRegionProps } from '@react-aria/toast';
import type { ToastState } from '@react-stately/toast';
import { useToastRegion } from '@react-aria/toast';
import type { ReactNode } from 'react';
import { useRef } from 'react';
import cx from 'clsx';
import styles from './toast-context.strict-module.css';
import type { ToastData } from './toast-component';
import { ToastComponent } from './toast-component';

export interface ShowToastConfig {
  timeout?: number;
  onClose?: () => void;
}

interface ToastRegionProps extends AriaToastRegionProps {
  state: ToastState<ToastData>;
}

export function ToastRegion({ state, ...props }: ToastRegionProps): ReactNode {
  const ref = useRef(null);
  const { regionProps } = useToastRegion(props, state, ref);

  return (
    <div {...regionProps} className={cx(styles.region, 'toast-region')} ref={ref}>
      {state.visibleToasts.map(toast => (
        <ToastComponent
          key={toast.key}
          state={state}
          text={toast.content.text}
          toast={toast}
          type={toast.content.type}
        />
      ))}
    </div>
  );
}
