import type { QueuedToast } from '@react-stately/toast';
import { useToastState } from '@react-stately/toast';
import type { PropsWithChildren, ReactElement } from 'react';
import { createContext, useContext } from 'react';
import type { AriaToastRegionProps } from '@react-aria/toast';
import type { ShowToastConfig } from './toast-region';
import { ToastRegion } from './toast-region';
import type { ToastData } from './toast-component';

interface ToastContextValue {
  showToast: (toast: ToastData, config?: ShowToastConfig) => string;
  hideToast: (toastId: string) => void;
  visibleToast?: QueuedToast<ToastData>;
}

const ToastContext = createContext<ToastContextValue | null>(null);

export function ToastProvider({
  children,
  ...props
}: PropsWithChildren<AriaToastRegionProps>): ReactElement {
  const state = useToastState<ToastData>({
    maxVisibleToasts: 1,
    hasExitAnimation: true,
  });

  const showToast = (newToast: ToastData, config?: ShowToastConfig): string => {
    return state.add(newToast, config);
  };

  const hideToast = (toastId: string): void => {
    state.close(toastId);
  };

  return (
    <ToastContext.Provider value={{ showToast, hideToast, visibleToast: state.visibleToasts[0] }}>
      {children}
      {state.visibleToasts.length > 0 && <ToastRegion {...props} state={state} />}
    </ToastContext.Provider>
  );
}

export function useToast(): ToastContextValue {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
}
