import './broadcast-channel-polyfill.js';

export interface EmailVerificationMessage {
  action: 'confirm-device-success' | 'confirm-device-error';
}

function isEmailVerificationMessage(data: unknown): data is EmailVerificationMessage {
  return typeof data === 'object' && data !== null && 'action' in data;
}

const emailVerificationChannelId = 'email-verification-channel';

export const emailVerificationChannel = {
  confirmDevice(): void {
    const channel = new BroadcastChannel(emailVerificationChannelId);
    channel.postMessage({ action: 'confirm-device-success' });
  },

  onMessage(callback: (message: EmailVerificationMessage) => Promise<void>): () => void {
    const channel = new BroadcastChannel(emailVerificationChannelId);
    channel.onmessage = async (event: MessageEvent): Promise<void> => {
      if (isEmailVerificationMessage(event.data)) await callback(event.data);
    };

    return () => {
      channel.onmessage = null;
      channel.close();
    };
  },
};
