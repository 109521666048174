import { type ComponentPropsWithRef } from 'react';
import cx from 'clsx';
import styles from './dots.strict-module.css';

interface DotButtonProps extends ComponentPropsWithRef<'button'> {
  isSelected?: boolean;
}

export function DotButton(props: DotButtonProps): JSX.Element {
  const { children, isSelected, className, ...restProps } = props;

  return (
    <button
      aria-pressed={isSelected}
      className={cx(className, isSelected && styles['dot-selected'])}
      type="button"
      {...restProps}
    >
      {children}
    </button>
  );
}

interface DotsProps {
  totalSlides: number;
  selectedIndex: number;
  onDotClick: (index: number) => void;
  className?: string;
}

export function Dots({
  totalSlides,
  selectedIndex,
  onDotClick,
  className,
}: DotsProps): JSX.Element {
  return (
    <div className={cx(styles['dots-container'], className)}>
      {Array.from({ length: totalSlides }, (_, index) => (
        <DotButton
          className={styles.dot}
          data-testid="dot"
          isSelected={index === selectedIndex}
          key={`dot-${index}`}
          onClick={() => {
            onDotClick(index);
          }}
        />
      ))}
    </div>
  );
}
