import { LOCALES_DEFINITION, type LocaleCode } from '@repo/shared-config/app/constants/locales';
import enMessages from '../../translations/en.json';
import frMessages from '../../translations/fr.json';
import deMessages from '../../translations/de.json';
import esMessages from '../../translations/es.json';
import itMessages from '../../translations/it.json';
import ptMessages from '../../translations/pt.json';
import type { TranslationsJson } from './translations';

export function loadMessages({ locale }: { locale: LocaleCode }): Record<string, TranslationsJson> {
  switch (locale) {
    case LOCALES_DEFINITION.FR.code:
      return frMessages;
    case LOCALES_DEFINITION.DE.code:
      return deMessages;
    case LOCALES_DEFINITION.ES.code:
      return esMessages;
    case LOCALES_DEFINITION.IT.code:
      return itMessages;
    case LOCALES_DEFINITION.PT.code:
      return ptMessages;
    case LOCALES_DEFINITION.EN.code:
    default:
      return enMessages;
  }
}
