export function VerifyPhoneNumberSvg(props: React.SVGProps<SVGSVGElement>): React.ReactNode {
  return (
    <svg
      fill="none"
      height="200"
      viewBox="0 0 200 200"
      width="200"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M73.1991 190.694C72.6491 190.349 72.1985 189.867 71.8915 189.295C71.5846 188.723 71.4319 188.081 71.4485 187.432V48.8549C71.4434 47.0745 71.8813 45.3209 72.7226 43.7518C73.5639 42.1828 74.7822 40.8477 76.2679 39.8666L128.681 10.4237C130.474 9.40928 132.723 8.62175 135.274 10.4237C137.825 12.2256 137.491 14.5369 137.491 15.9108L137.534 151.698C137.537 153.504 137.09 155.283 136.232 156.873C135.374 158.463 134.132 159.813 132.62 160.801L79.3539 191.498C78.5819 191.862 77.7304 192.075 76.8782 192.032C75.256 192.032 74.1878 191.332 73.1991 190.694Z"
        fill="white"
        stroke="#1D1D1B"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <path
        d="M68.7216 42.088C69.5743 40.4908 70.8151 39.1338 72.3297 38.1417L125.002 7.90159L125.156 7.82027L125.237 7.7689C127.174 6.78216 128.833 6.69863 130.72 7.79012L134.976 10.2241C133.763 9.42351 132 9.08403 130.24 10.1027L77.0079 40.804C75.4726 41.8137 74.2138 43.191 73.346 44.8107C72.4782 46.4305 72.0288 48.2414 72.0387 50.079L72.1243 187.569C72.1166 188.278 72.2239 189.067 72.5627 189.689C72.8922 190.312 73.2247 190.714 74.0791 191.239L71.1949 189.465C70.1887 188.836 69.3454 187.978 68.7341 186.961C68.1227 185.944 67.6045 184.051 67.6045 182.876L67.4418 47.3012C67.4258 45.4838 67.8658 43.6914 68.7216 42.088Z"
        fill="#B0F0DA"
        stroke="#1D1D1B"
        strokeWidth="0.5"
      />
      <path
        d="M95.0775 39.1802C90.8435 41.6249 90.3648 33.1205 86.6162 35.245L118.502 17.0041C114.73 19.1821 118.417 25.7039 114.174 28.1542L104.626 33.6672L95.0775 39.1802Z"
        fill="#CEF6E9"
      />
      <path
        d="M80.8145 38.5947C81.4824 38.209 83.5728 37.0021 86.5905 35.2597M86.5905 35.2597C90.3626 33.0817 90.8339 41.6305 95.0775 39.1802C98.4724 37.2201 102.857 34.6881 104.626 33.6672L114.174 28.1542C118.417 25.7039 114.73 19.1821 118.502 17.0041M86.5905 35.2597L118.502 17.0041M128.439 11.1255L118.502 17.0041"
        stroke="#1D1D1B"
        strokeWidth="0.5"
      />
      <path
        d="M97.5797 33.0791L110.79 25.4014"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeWidth="0.5"
      />
      <path
        d="M67.8075 67.3562C68.4609 67.7334 68.9869 68.5561 68.984 69.2032L68.984 75.9134C68.9866 76.2301 68.8662 76.4386 68.6667 76.5273L69.7183 76.0222L69.822 75.9709C70.0654 75.8705 70.2136 75.678 70.2132 75.3256L70.2132 68.6153C70.2161 67.9682 69.6901 67.1455 69.0367 66.7683C68.7169 66.5837 68.4902 66.5269 68.2987 66.6017L68.2029 66.6391L66.904 67.2338C67.1094 67.167 67.4877 67.1716 67.8075 67.3562Z"
        fill="#CEF6E9"
        stroke="#1D1D1B"
        strokeWidth="0.5"
      />
      <path
        d="M66.5686 67.5218C66.6304 67.4147 66.7112 67.3365 66.8188 67.2738C67.0839 67.1236 67.4555 67.1597 67.8434 67.3837C68.5258 67.7777 69.0566 68.6193 69.0631 69.3188L69.0631 75.7763C69.0635 76.1154 68.9301 76.3774 68.6938 76.5085C68.4287 76.6587 68.0572 76.6226 67.6692 76.3986C66.9868 76.0046 66.456 75.163 66.4495 74.4636L66.4495 68.006C66.4465 67.8259 66.4913 67.6555 66.5686 67.5218Z"
        fill="#CEF6E9"
        stroke="#1D1D1B"
        strokeWidth="0.5"
      />
      <path
        d="M67.8075 78.8318C68.4609 79.209 68.9869 80.0317 68.984 80.6788L68.984 87.389C68.9866 87.7056 68.8662 87.9142 68.6667 88.0029L69.7183 87.4978L69.822 87.4465C70.0654 87.3461 70.2136 87.1536 70.2132 86.8011L70.2132 80.0909C70.2161 79.4438 69.6901 78.6211 69.0367 78.2439C68.7169 78.0593 68.4902 78.0025 68.2987 78.0773L68.2029 78.1147L66.904 78.7094C67.1094 78.6426 67.4877 78.6472 67.8075 78.8318Z"
        fill="#CEF6E9"
        stroke="#1D1D1B"
        strokeWidth="0.5"
      />
      <path
        d="M66.5686 78.9973C66.6304 78.8903 66.7112 78.8121 66.8188 78.7494C67.0839 78.5992 67.4555 78.6353 67.8434 78.8593C68.5258 79.2532 69.0566 80.0949 69.0631 80.7943L69.0631 87.2519C69.0635 87.591 68.9301 87.853 68.6938 87.9841C68.4287 88.1343 68.0572 88.0982 67.6692 87.8742C66.9868 87.4802 66.456 86.6386 66.4495 85.9391L66.4495 79.4816C66.4465 79.3015 66.4913 79.1311 66.5686 78.9973Z"
        fill="#CEF6E9"
        stroke="#1D1D1B"
        strokeWidth="0.5"
      />
      <path
        d="M67.5449 55.3262C68.7476 56.0413 71.1531 57.8151 71.1531 59.1889C71.1531 60.5626 71.1531 78.1234 71.1531 86.7321"
        stroke="#1D1D1B"
        strokeWidth="0.5"
      />
      <path
        d="M67.8259 59.6532C68.4793 60.0305 69.0053 60.8532 69.0024 61.5003L69.0024 63.9325C69.005 64.2491 68.8846 64.4577 68.6851 64.5464L69.7367 64.0413L69.8404 63.99C70.0838 63.8896 70.232 63.6971 70.2316 63.3446L70.2316 60.9124C70.2345 60.2653 69.7085 59.4426 69.0551 59.0653C68.7353 58.8807 68.5086 58.8239 68.3171 58.8988L68.2214 58.9362L66.9224 59.5308C67.1278 59.4641 67.5062 59.4686 67.8259 59.6532Z"
        fill="#CEF6E9"
        stroke="#1D1D1B"
        strokeWidth="0.5"
      />
      <path
        d="M66.588 59.8178C66.6498 59.7108 66.7306 59.6326 66.8382 59.5698C67.1033 59.4196 67.4748 59.4558 67.8628 59.6798C68.5452 60.0737 69.076 60.9154 69.0825 61.6148L69.0825 63.7944C69.0829 64.1336 68.9495 64.3955 68.7132 64.5266C68.4481 64.6768 68.0765 64.6407 67.6886 64.4167C67.0062 64.0227 66.4754 63.1811 66.4689 62.4817L66.4689 60.3021C66.4659 60.1219 66.5107 59.9516 66.588 59.8178Z"
        fill="#CEF6E9"
        stroke="#1D1D1B"
        strokeWidth="0.5"
      />
      <mask
        fill="black"
        height="63.5"
        id="path-13-outside-1_37656_6244"
        maskUnits="userSpaceOnUse"
        width="49.3634"
        x="82.134"
        y="71.9609"
      >
        <rect fill="white" height="63.5" width="49.3634" x="82.134" y="71.9609" />
        <path
          clipRule="evenodd"
          d="M87.3301 98.4609C84.9387 99.8416 83 103.2 83 105.961L83 120.21C83 122.971 84.9387 124.09 87.3301 122.71L93.6667 119.051L93.6667 127.115L102.863 113.742L126.67 99.9968C129.061 98.6161 131 95.2583 131 92.4968L131 78.2481C131 75.4867 129.061 74.3674 126.67 75.7481L87.3301 98.4609Z"
          fillRule="evenodd"
        />
      </mask>
      <path
        clipRule="evenodd"
        d="M87.3301 98.4609C84.9387 99.8416 83 103.2 83 105.961L83 120.21C83 122.971 84.9387 124.09 87.3301 122.71L93.6667 119.051L93.6667 127.115L102.863 113.742L126.67 99.9968C129.061 98.6161 131 95.2583 131 92.4968L131 78.2481C131 75.4867 129.061 74.3674 126.67 75.7481L87.3301 98.4609Z"
        fill="#CEF6E9"
        fillRule="evenodd"
      />
      <path
        d="M93.6667 119.051L94.0997 118.801L94.0997 118.301L93.6667 118.551L93.6667 119.051ZM93.6667 127.115L93.2337 127.365L93.2337 128.373L93.9285 127.362L93.6667 127.115ZM102.863 113.742L102.863 113.242L102.717 113.326L102.601 113.495L102.863 113.742ZM83.433 105.711C83.433 103.226 85.1778 100.204 87.3301 98.9609L87.3301 97.9609C84.6995 99.4797 82.567 103.173 82.567 106.211L83.433 105.711ZM83.433 119.96L83.433 105.711L82.567 106.211L82.567 120.46L83.433 119.96ZM87.3301 122.21C85.1778 123.452 83.433 122.445 83.433 119.96L82.567 120.46C82.567 123.497 84.6995 124.728 87.3301 123.21L87.3301 122.21ZM93.6667 118.551L87.3301 122.21L87.3301 123.21L93.6667 119.551L93.6667 118.551ZM94.0997 126.865L94.0997 118.801L93.2337 119.301L93.2337 127.365L94.0997 126.865ZM102.601 113.495L93.4048 126.868L93.9285 127.362L103.125 113.989L102.601 113.495ZM126.67 99.4968L102.863 113.242L102.863 114.242L126.67 100.497L126.67 99.4968ZM130.567 92.7468C130.567 95.2321 128.822 98.2542 126.67 99.4968L126.67 100.497C129.3 98.9781 131.433 95.2844 131.433 92.2468L130.567 92.7468ZM130.567 78.4981L130.567 92.7468L131.433 92.2468L131.433 77.9981L130.567 78.4981ZM126.67 76.2481C128.822 75.0055 130.567 76.0128 130.567 78.4981L131.433 77.9981C131.433 74.9606 129.3 73.7293 126.67 75.2481L126.67 76.2481ZM87.3301 98.9609L126.67 76.2481L126.67 75.2481L87.3301 97.9609L87.3301 98.9609Z"
        fill="#1D1D1B"
        mask="url(#path-13-outside-1_37656_6244)"
      />
      <path
        d="M92.9072 110.673L92.9465 108.476L91.4032 110.609L90.6788 109.71L92.3416 107.689L90.6108 107.749L91.2431 105.994L92.8705 106.284L92.6831 104.211L93.9283 103.475L93.889 105.671L95.4323 103.538L96.1566 104.437L94.4939 106.459L96.2246 106.399L95.5924 108.153L93.965 107.863L94.1524 109.936L92.9072 110.673Z"
        fill="#1D1D1B"
      />
      <path
        d="M102.317 105.54L102.357 103.343L100.813 105.476L100.089 104.577L101.752 102.556L100.021 102.616L100.653 100.861L102.281 101.151L102.093 99.0782L103.338 98.3419L103.299 100.538L104.842 98.4053L105.567 99.3043L103.904 101.326L105.635 101.266L105.003 103.021L103.375 102.73L103.563 104.803L102.317 105.54Z"
        fill="#1D1D1B"
      />
      <path
        d="M111.728 100.407L111.768 98.2106L110.224 100.344L109.5 99.4445L111.163 97.4229L109.432 97.4829L110.064 95.7282L111.692 96.0183L111.504 93.9454L112.75 93.2091L112.71 95.4054L114.254 93.2724L114.978 94.1715L113.315 96.1931L115.046 96.133L114.414 97.8878L112.786 97.5977L112.974 99.6706L111.728 100.407Z"
        fill="#1D1D1B"
      />
      <path
        d="M121.139 95.2741L121.178 93.0778L119.635 95.2107L118.91 94.3116L120.573 92.2901L118.842 92.3501L119.475 90.5953L121.102 90.8855L120.915 88.8126L122.16 88.0763L122.12 90.2726L123.664 88.1396L124.388 89.0387L122.725 91.0602L124.456 91.0002L123.824 92.755L122.196 92.4649L122.384 94.5378L121.139 95.2741Z"
        fill="#1D1D1B"
      />
    </svg>
  );
}
