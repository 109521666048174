import { useIntl } from 'react-intl';
import { IconLockFilled } from '../../assets/icons/icon-lock-filled.tsx';
import { IconLockOutlined } from '../../assets/icons/icon-lock-outlined.tsx';
import { IconMollieLogo } from '../../assets/icons/icon-mollie-logo.tsx';
import styles from './footer.strict-module.css';

interface FooterProps {
  hasPaymentLink?: boolean;
}

export function Footer({ hasPaymentLink = false }: FooterProps): React.ReactNode {
  const { formatMessage } = useIntl();

  return (
    <footer className={styles.footer}>
      {hasPaymentLink ? (
        <p className={styles['with-payment-link']} data-testid="footer-with-payment-link">
          <IconLockFilled />
          <span>{formatMessage({ id: 'receivable_invoice.mollie_footer.text' })}</span>
          <IconMollieLogo
            aria-label={formatMessage({ id: 'receivable_invoice.mollie_footer.aria_label' })}
            className={styles['mollie-logo']}
          />
        </p>
      ) : (
        <p className="caption" data-testid="footer-without-payment-link">
          <IconLockOutlined className="mr-8" />
          <span>{formatMessage({ id: 'invoice.preview.secure' })}</span>
        </p>
      )}
    </footer>
  );
}
