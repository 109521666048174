import { Toggle, type ToggleProps } from '@repo/design-system-kit';
import type { ReactElement } from 'react';
import cx from 'clsx';
import styles from './toggle-section.strict-module.css';

interface ToggleSectionProps extends ToggleProps {
  title?: string | ReactElement;
  content?: ReactElement;
  checked?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

export function ToggleSection({
  isDisabled = false,
  isReadOnly = false,
  className,
  title,
  content,
  onChange,
  checked,
  ...props
}: ToggleSectionProps): ReactElement {
  return (
    <div
      className={cx(
        styles.wrapper,
        isDisabled && styles.disabled,
        isReadOnly && styles.readonly,
        className
      )}
    >
      <Toggle
        className={styles.toggle}
        isDisabled={isDisabled || isReadOnly}
        isReadOnly={isReadOnly}
        isSelected={checked}
        onChange={onChange}
        {...props}
        data-test-selector
      >
        <div className={styles.contentWrapper}>
          <div className={styles.header}>
            {title ? (
              <span className={cx('body-1', styles.title)} data-test-selector-title>
                {title}
              </span>
            ) : null}
          </div>
        </div>
      </Toggle>
      {content && checked ? <div className={styles.content}>{content}</div> : null}
    </div>
  );
}
