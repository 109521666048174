import type { HTMLAttributes, ReactNode } from 'react';
import cx from 'clsx';
import styles from './placeholder-line.strict-module.css';

interface PlaceholderLineProps extends HTMLAttributes<HTMLDivElement> {
  standalone?: boolean;
  width?: string;
}

export function PlaceholderLine({
  standalone = true,
  width = '100%',
  className,
  ...attributes
}: PlaceholderLineProps): ReactNode {
  return (
    <div
      className={cx(styles.line, standalone && styles.standalone, className)}
      data-test-placeholder-line
      style={{ width }}
      {...attributes}
    />
  );
}
