import React, { Children, useEffect, useState, useCallback, useRef } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { PrevButton, NextButton, usePrevNextButtons } from './arrows.tsx';
import styles from './pdf-preview-carousel.strict-module.css';
import { Dots } from './dots.tsx';

export interface CarouselOptions {
  align?: 'start' | 'center' | 'end';
  watchDrag?: boolean;
}

export interface Slide {
  id: string;
}

export interface NodeProps {
  nodes: HTMLElement[];
}

export interface CarouselProps {
  slides: Slide[];
  options: CarouselOptions;
  children: React.ReactElement<NodeProps>;
  selectedIndex: number;
  onSlideChange: (index: number) => void;
}

export interface EmberNodeProps {
  node: HTMLElement;
}

export function EmberNodeWrapper({ node }: EmberNodeProps): JSX.Element {
  // React doesn't allow to render iframe without passing by a ref
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.innerHTML = '';
      containerRef.current.appendChild(node.cloneNode(true));
    }
  }, [node]);

  return (
    <div
      className={styles['pdf-preview-carousel-slide']}
      data-testid="pdf-preview-carousel-slide"
      ref={containerRef}
    />
  );
}

export function PdfPreviewCarousel({
  children,
  onSlideChange,
  options,
  selectedIndex,
  slides,
}: CarouselProps): React.JSX.Element {
  const [emblaRef, emblaApi] = useEmblaCarousel({ ...options, startIndex: selectedIndex });
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);

  const { onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi, onSlideChange);

  const handleDotClick = (index: number): void => {
    if (emblaApi) {
      emblaApi.scrollTo(index);
      onSlideChange(index);
    }
  };

  const updateScrollButtonsState = useCallback(() => {
    if (!emblaApi) return;
    setCanScrollPrev(emblaApi.canScrollPrev());
    setCanScrollNext(emblaApi.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;

    const onSelect = (): void => {
      onSlideChange(emblaApi.selectedScrollSnap());
    };

    emblaApi.on('select', onSelect);

    updateScrollButtonsState();
    emblaApi.on('select', updateScrollButtonsState);
    emblaApi.on('reInit', updateScrollButtonsState);
    return () => {
      emblaApi.off('select', onSelect);
      emblaApi.off('select', updateScrollButtonsState);
      emblaApi.off('reInit', updateScrollButtonsState);
    };
  }, [emblaApi, onSlideChange, updateScrollButtonsState]);

  useEffect(() => {
    if (emblaApi && emblaApi.selectedScrollSnap() !== selectedIndex) {
      emblaApi.scrollTo(selectedIndex);
    }
  }, [emblaApi, selectedIndex]);

  // Because of ReactBridge, we cant pass AttachmentPreview as a Children, but we need
  // to pass the whole iteration. And render only the first index, because the bridge creates
  // an array of nodes arrays instead of one array

  const childrenArrayIframes = (Children.toArray(children)[0] as React.ReactElement<NodeProps>)
    .props.nodes;
  return (
    <section className={styles['pdf-preview-carousel']} data-testid="pdf-preview-carousel">
      {canScrollPrev ? <PrevButton onClick={onPrevButtonClick} /> : null}
      <div className={styles['pdf-preview-carousel-viewport']} ref={emblaRef}>
        <div className={styles['pdf-preview-carousel-container']}>
          {childrenArrayIframes.map((child, index) => {
            // eslint-disable-next-line react/no-array-index-key -- static array
            return <EmberNodeWrapper key={`node-${index}`} node={child} />;
          })}
        </div>
      </div>
      {canScrollNext ? <NextButton onClick={onNextButtonClick} /> : null}
      <Dots onDotClick={handleDotClick} selectedIndex={selectedIndex} totalSlides={slides.length} />
    </section>
  );
}
