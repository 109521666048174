import { IconWarningSignFilledSmall } from '@repo/monochrome-icons';
import dayjs from 'dayjs';
import styles from './expected-date.strict-module.css';

interface ExpectedDateProps {
  date: string;
}

export function ExpectedDate({ date }: ExpectedDateProps): React.ReactElement {
  const formattedDate = dayjs(date).format('MMM D, YYYY');
  const isBeforeToday = dayjs(date).isBefore(dayjs().startOf('day'));

  return (
    <div
      className={`${styles.expectedDate} ${isBeforeToday ? styles.alert : ''}`}
      data-testid="expected-date"
    >
      {formattedDate}
      {isBeforeToday ? <IconWarningSignFilledSmall data-testid="warning-icon" /> : null}
    </div>
  );
}
