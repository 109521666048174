import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'usehooks-ts';
import cx from 'clsx';
import { type BasketSummaryProps } from '../types';
import styles from './basket-summary.strict-module.css';

export function BasketSummary({
  totals,
  currency,
  isPreview = false,
}: BasketSummaryProps): ReactNode {
  const { formatNumber, formatMessage } = useIntl();
  const isMobile = useMediaQuery('(width < 768px)');
  const showMobileView = isMobile && !isPreview;

  const formatCurrency = (value: number): string => {
    return formatNumber(value, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
    });
  };

  return (
    <tfoot className={cx(styles['basket-summary'], isPreview && styles.preview)}>
      <tr>
        <td className={styles['empty-cell']} />
        <th
          className={cx(styles['subtotal-label'], 'body-1')}
          colSpan={showMobileView ? 1 : 2}
          scope="row"
        >
          {formatMessage({ id: 'payment-link.itemisation.summary.subtotal' })}
        </th>
        <td className={cx(styles['subtotal-value'], 'body-2')}>
          {formatCurrency(totals.totalExcludingVat)}
        </td>
      </tr>
      <tr>
        <td className={styles['empty-cell']} />
        <th
          className={cx(styles['vat-label'], 'body-1')}
          colSpan={showMobileView ? 1 : 2}
          scope="row"
        >
          {formatMessage({ id: 'payment-link.itemisation.summary.vat-amount' })}
        </th>
        <td className={cx(styles['vat-value'], 'body-2')}>{formatCurrency(totals.totalVat)}</td>
      </tr>
      <tr>
        <td className={styles['empty-cell']} />
        <th
          className={cx(styles['total-label'], styles['total-row'], 'body-1')}
          colSpan={showMobileView ? 1 : 2}
          scope="row"
        >
          {formatMessage({ id: 'payment-link.itemisation.summary.total' })}
        </th>
        <td className={cx(styles['total-value'], styles['total-row'], 'body-2')}>
          {formatCurrency(totals.totalIncludingVat)}
        </td>
      </tr>
    </tfoot>
  );
}
