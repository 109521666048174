import type { ReactElement, ReactNode } from 'react';
import type { SwitchProps as AriaSwitchProps } from 'react-aria-components';
import { Switch } from 'react-aria-components';
import cx from 'clsx';
import styles from './toggle.strict-module.css';

export interface ToggleProps extends AriaSwitchProps {
  checked?: boolean;
  className?: string;
  children?: ReactNode;
}

export function Toggle({
  checked,
  className,
  children = null,
  ...props
}: ToggleProps): ReactElement {
  return (
    <Switch className={cx(styles.wrapper, className)} defaultSelected={checked} {...props}>
      {children !== null ? <div className={cx(styles.children, 'mr-8')}>{children}</div> : null}
      <div className={styles.toggle}>
        <div className={styles.knob} />
        <div className={styles.overlay} />
      </div>
    </Switch>
  );
}
