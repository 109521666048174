import type { ReactNode, ReactElement } from 'react';
import { Children, cloneElement } from 'react';
import cx from 'clsx';
import styles from './info-list.strict-module.css';
import { Row } from './row.tsx';

export function InfoList({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDListElement>): ReactNode {
  const arrayChildren = Children.toArray(children) as ReactElement[];

  return (
    <dl className={cx(styles['info-list'], className)} {...props}>
      {arrayChildren.map(child => cloneElement(child))}
    </dl>
  );
}

InfoList.Row = Row;
