import type { CipherSuite, RecipientContext } from 'hpke-js';
import { b64ToArrayBuffer } from './base-64.ts';

interface DecryptParams {
  b64Enc: string;
  b64EncryptedData: string;
  plainTextAad: string;
  plainTextInfo: string;
  recipientKey: CryptoKey;
  senderPublicKey: CryptoKey;
  suite: CipherSuite;
}

export async function decrypt({
  b64Enc,
  b64EncryptedData,
  plainTextAad,
  plainTextInfo,
  recipientKey,
  senderPublicKey,
  suite,
}: DecryptParams): Promise<string> {
  const enc: ArrayBuffer = b64ToArrayBuffer(b64Enc);
  const encryptedData: ArrayBuffer = b64ToArrayBuffer(b64EncryptedData);

  const rc: RecipientContext = await suite.createRecipientContext({
    enc,
    info: new TextEncoder().encode(plainTextInfo),
    recipientKey,
    senderPublicKey,
  });

  const aad = new TextEncoder().encode(plainTextAad);
  const decryptedData = await rc.open(encryptedData, aad);

  return new TextDecoder().decode(decryptedData);
}
