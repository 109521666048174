import type { ReactNode, HTMLAttributes } from 'react';
import cx from 'clsx';
import { LottiePlayer } from '../lottie-player';
import animationData from '../../assets/lotties/search-no-result.json';
import styles from './empty-state.strict-module.css';

interface EmptyStateProps extends HTMLAttributes<HTMLDivElement> {
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  subtitle: string;
  title: string;
}

export function EmptyState({
  headingLevel = 'h2',
  subtitle,
  title,
  ...props
}: EmptyStateProps): ReactNode {
  const Heading = headingLevel;

  return (
    <div className={styles.container} data-test-empty-state role="alert" {...props}>
      <div className={cx(styles.lottie, 'mb-32')}>
        <LottiePlayer animationData={animationData} data-testid="lottie" />
      </div>

      <Heading className="title-3 mb-8">{title}</Heading>

      <p className="body-2">{subtitle}</p>
    </div>
  );
}
