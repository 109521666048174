import { DeviceConfirmed } from './device-confirmed';
import { GenericError } from './generic-error';
import { Timeout } from './timeout';
import { WrongDevice } from './wrong-device';

export const EmailVerification = {
  Timeout,
  GenericError,
  WrongDevice,
  DeviceConfirmed,
};
