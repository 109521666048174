import type { ReactElement, ReactNode } from 'react';
import { useRef, useState } from 'react';
import type { ButtonProps as AriaButtonProps } from 'react-aria-components';
import {
  Button,
  Collection,
  Group,
  Menu,
  MenuItem,
  MenuTrigger,
  Popover,
} from 'react-aria-components';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { IconChevronBottomOutlined } from '@repo/monochrome-icons';
import btnStyles from '../../primitives/button.strict-module.css';
import { Spinner } from '../spinner';
import styles from './dropdown-button.strict-module.css';

export interface BaseDropdownButtonProps {
  dataTestId?: string;
  isLoading?: boolean;
  triggerDataTestId?: string;
  variant?: 'primary' | 'secondary';
  onOpenChange?: (isOpen: boolean) => void;
  items: {
    label: string;
    onSelect: () => void;
    disabled?: boolean;
    dataTestId?: string;
  }[];
  customMenu?: ReactNode;
}

interface DropdownButtonProps extends AriaButtonProps, BaseDropdownButtonProps {
  children: ReactNode;
  style?: React.CSSProperties;
}

export function DropdownButton({
  children,
  isLoading = false,
  isDisabled = false,
  items,
  className,
  style,
  dataTestId,
  triggerDataTestId,
  variant = 'primary',
  customMenu,
  onOpenChange,
  ...props
}: DropdownButtonProps): ReactElement {
  const intl = useIntl();
  const mainButtonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <div> component with 'ember-testing' id)
  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;

  const handleOpenChange = (open: boolean): void => {
    setIsOpen(open);
    onOpenChange?.(open);
  };

  return (
    <div
      className={cx(
        'body-1',
        styles.container,
        variant === 'secondary' && styles.secondary,
        className
      )}
      style={style}
    >
      <Group className={styles.buttonGroup} isDisabled={isDisabled}>
        <Button
          className={cx(
            btnStyles.btn,
            btnStyles.primary,
            btnStyles['btn--small'],
            styles.mainButton,
            (items.length > 0 || customMenu) && styles.mainButtonBorders
          )}
          data-testid={dataTestId}
          isDisabled={isDisabled || isLoading}
          ref={mainButtonRef}
          {...props}
        >
          <div className={styles.buttonContent}>{children}</div>
          {isLoading ? <Spinner className={styles.spinner} /> : null}
        </Button>
        {items.length > 0 || customMenu ? (
          <MenuTrigger onOpenChange={handleOpenChange}>
            <Button
              aria-label={
                isOpen
                  ? intl.formatMessage({ id: 'a11y-buttons-close-aria-label' })
                  : intl.formatMessage({ id: 'a11y-buttons-open-aria-label' })
              }
              className={cx(
                btnStyles.btn,
                btnStyles.primary,
                btnStyles['btn--small'],
                styles.triggerButton
              )}
              data-testid={triggerDataTestId ?? `${dataTestId}-trigger`}
              isDisabled={isDisabled || isLoading}
            >
              <IconChevronBottomOutlined />
            </Button>

            <Popover
              UNSTABLE_portalContainer={TEST_PORTAL_CONTAINER}
              className={cx('body-2', styles.popover)}
              offset={4}
              placement="bottom start"
              triggerRef={mainButtonRef}
            >
              {customMenu ?? (
                <Menu className={styles.menu}>
                  <Collection items={items}>
                    {item => (
                      <MenuItem
                        className={styles.menuItem}
                        data-testid={item.dataTestId}
                        id={item.label}
                        isDisabled={item.disabled}
                        key={item.label}
                        onAction={item.onSelect}
                      >
                        {item.label}
                      </MenuItem>
                    )}
                  </Collection>
                </Menu>
              )}
            </Popover>
          </MenuTrigger>
        ) : null}
      </Group>
    </div>
  );
}
