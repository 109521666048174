import type { IntlShape } from 'react-intl';
import { BlackCardDesign, CardLevel } from '../constants';

export function getCardShortName(cardDesign: string, intl: IntlShape): string | null {
  switch (cardDesign as CardLevel | BlackCardDesign) {
    case CardLevel.Metal:
      return intl.formatMessage({ id: 'cards.name.metal' });
    case CardLevel.Plus:
      return intl.formatMessage({ id: 'cards.name.plus' });
    case CardLevel.Standard:
      return intl.formatMessage({ id: 'cards.name.standard' });
    case CardLevel.Virtual:
      return intl.formatMessage({ id: 'cards.name.virtual' });
    case CardLevel.Flash:
      return intl.formatMessage({ id: 'cards.name.flash' });
    case CardLevel.Advertising:
      return intl.formatMessage({ id: 'cards.name.advertising' });
    case BlackCardDesign.PlusRecycledPlasticBlack2023:
      return intl.formatMessage({ id: 'cards.name.plus-black-2023' });
    default:
      return null;
  }
}
