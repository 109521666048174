import { useIntl } from 'react-intl';
import { Button } from '@repo/design-system-kit';
import type { Amount } from '../../../../types/amount';
import type { Mollie } from '../../../../types/mollie';
import { CardProvider } from '../../../shared/constants';
import { CreditCardIcons } from '../../../shared/credit-card-icons';
import { CreditCardInput } from '../../../shared/credit-card-input';
import styles from './credit-card-fields.strict-module.css';

interface CreditCardFieldsProps {
  amount: Amount<string>;
  hasSubmitted: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  mollie?: Mollie | null;
  onSubmit: () => void;
}

export function CreditCardFields({
  amount,
  disabled,
  hasSubmitted,
  isLoading = false,
  mollie,
  onSubmit,
}: CreditCardFieldsProps): React.ReactNode {
  const { formatMessage, formatNumber, locale } = useIntl();

  const formattedTotal = formatNumber(parseFloat(amount.value), {
    style: 'currency',
    currency: amount.currency,
    minimumFractionDigits: 2,
  });

  // If `mollie` is undefined it means we are not expecting Mollie because Mollie default value is `null`
  const withMollie = typeof mollie !== 'undefined';
  const isMollieLoading = withMollie && !mollie;

  return (
    <div className={styles.container} data-testid="credit-card-fields">
      {!isMollieLoading ? (
        <div className={styles['card-fields']}>
          <CreditCardInput
            disabled={disabled}
            hasSubmitted={hasSubmitted}
            label={formatMessage(
              {
                id: 'receivable-invoices.public-payment-link.credit-card.labels.number',
              },
              { language: locale }
            )}
            mollie={mollie}
            type="cardNumber"
            withMollie={withMollie}
          />
          <CreditCardInput
            disabled={disabled}
            hasSubmitted={hasSubmitted}
            label={formatMessage(
              {
                id: 'receivable-invoices.public-payment-link.credit-card.labels.name',
              },
              { language: locale }
            )}
            mollie={mollie}
            type="cardHolder"
            withMollie={withMollie}
          />
          <div className={styles.inline}>
            <CreditCardInput
              disabled={disabled}
              hasSubmitted={hasSubmitted}
              label={formatMessage(
                {
                  id: 'receivable-invoices.public-payment-link.credit-card.labels.expiration-date',
                },
                { language: locale }
              )}
              mollie={mollie}
              type="expiryDate"
              withMollie={withMollie}
            />
            <CreditCardInput
              disabled={disabled}
              hasSubmitted={hasSubmitted}
              label={formatMessage(
                {
                  id: 'receivable-invoices.public-payment-link.credit-card.labels.security-code',
                },
                { language: locale }
              )}
              mollie={mollie}
              type="verificationCode"
              withMollie={withMollie}
            />
          </div>
        </div>
      ) : null}

      <div className={styles.footnote}>
        <span className="caption">
          {formatMessage(
            { id: 'receivable-invoices.public-payment-link.credit-card.footnote' },
            { language: locale }
          )}
        </span>
        <CreditCardIcons
          providers={[
            CardProvider.Visa,
            CardProvider.Mastercard,
            CardProvider.CartesBancaires,
            CardProvider.AmericanExpress,
          ]}
        />
      </div>

      <Button
        isDisabled={isMollieLoading || disabled}
        isLoading={isLoading}
        onPress={onSubmit}
        type="button"
      >
        {formatMessage({ id: 'payment-link.payment-details.cta' }, { linkAmount: formattedTotal })}
      </Button>
    </div>
  );
}
