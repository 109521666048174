import * as React from 'react';
import { clsx } from 'clsx';
import { Button, ExternalLink, TextAreaField, LottiePlayer } from '@repo/design-system-kit';
import successLottie from '../../assets/lotties/success.json';
import styles from './subscription-success.strict-module.css';

interface SubscriptionSuccessProps {
  title: string;
  subtitle: string;
  ctaText: string;
  onClick: () => void;
  onClickShareFeedback?: () => void;
  onUpdateTextArea?: (value: string) => void;
  lottie?: object;
  stretch?: boolean;
  asset?: React.ReactNode;
  shareFeedbackText?: string;
  shareFeedbackLink?: string;
  shareFeedbackSubtitle?: string;
  hasNewFeedbackStyle?: boolean;
  textAreaPlaceholder?: string;
  textAreaLabel?: string;
  textAreaValue?: string;
}

export function SubscriptionSuccess({
  title,
  subtitle,
  ctaText,
  onClick,
  onClickShareFeedback,
  onUpdateTextArea,
  lottie = successLottie,
  asset,
  stretch = false,
  shareFeedbackText,
  shareFeedbackLink,
  shareFeedbackSubtitle,
  hasNewFeedbackStyle = false,
  textAreaPlaceholder = "",
  textAreaLabel = "",
  textAreaValue = "",
}: SubscriptionSuccessProps): React.JSX.Element {
  const hasShareButton = hasNewFeedbackStyle ? onUpdateTextArea : shareFeedbackText && shareFeedbackLink;
  const closeButtonVariant = hasShareButton ? 'tertiary' : 'primary';

  return (
    <div className={(hasNewFeedbackStyle && hasShareButton) ? clsx(styles.container, styles['left-align']) : styles.container} data-test-subscription-success>
      {asset || (
        <div className={styles['lottie-container']}>
          <LottiePlayer
            animationData={lottie}
            className={styles.lottie}
            data-test-subscription-success-lottie
            loop={false}
          />
        </div>
      )}
      {hasNewFeedbackStyle ? (
        <>
          <h1
            className={clsx(styles.title, 'title-1')}
            data-test-subscription-success-title
          >
            {title}
          </h1>
          <p
            className={clsx(
              styles.subtitle,
              'body-1',
              hasShareButton && styles['subtitle-with-button']
            )}
            data-test-subscription-success-subtitle
          >
            {subtitle}
          </p>
          {hasShareButton ? (
            <>
              <TextAreaField
                className={styles.textarea}
                data-testid="subscription-success-textarea"
                label={textAreaLabel}
                maxLength={500}
                onChange={onUpdateTextArea}
                placeholder={textAreaPlaceholder}
                value={textAreaValue}
              />
              <div className={styles['share-feedback-container']}>
                <Button
                  data-test-subscription-success-share-feedback-button
                  onPress={onClick}
                  stretch={stretch}
                >
                  {ctaText}
                </Button>
              </div>
            </>
          ) : (
            <Button
              data-test-subscription-success-button
              onPress={onClick}
              stretch={stretch}
              variant={closeButtonVariant}
            >
              {ctaText}
            </Button>
          )}
        </>
      ) : (
        <>
          <h1
            className={clsx(styles.title, 'title-1')}
            data-test-subscription-success-title
          >
            {title}
          </h1>
          <p
            className={clsx(
              styles.subtitle,
              'body-1',
              hasShareButton && styles['subtitle-with-button']
            )}
            data-test-subscription-success-subtitle
          >
            {subtitle}
          </p>
          {hasShareButton ? <>
              <p
                className={clsx(styles.subtitle, 'body-1')}
                data-testid="subscription-success-share-feedback-subtitle"
              >
                {shareFeedbackSubtitle}
              </p>
              <div className={styles['share-feedback-container']}>
                <ExternalLink
                  data-test-subscription-success-share-feedback-button
                  display="button"
                  href={shareFeedbackLink}
                  onPress={onClickShareFeedback}
                  rel="noopener noreferrer"
                  size="medium"
                  target="_blank"
                  variant="primary"
                >
                  {shareFeedbackText}
                </ExternalLink>
              </div>
            </> : null}
          <Button
            data-test-subscription-success-button
            onPress={onClick}
            stretch={stretch}
            variant={closeButtonVariant}
          >
            {ctaText}
          </Button>
        </>
      )}
    </div>
  );
}
