import { AmountField, Label } from '@repo/design-system-kit';
import { type HTMLAttributes, useId } from 'react';
import { useIntl } from 'react-intl';
import { CollapseOptions } from '../collapse-options';
import styles from './vat-rate.strict-module.css';

export interface VatRateOption {
  value: number;
  clearable?: boolean;
  label: string;
}

export interface VatRateProps extends HTMLAttributes<HTMLDivElement> {
  vatRate?: string | null;
  selectedVatOption?: VatRateOption;
  vatRateOnChange: (value: string) => void;
  updateVatOption: (option?: VatRateOption) => void;
  isDisabled?: boolean;
  className?: string;
  vatRates: number[];
  label?: string;
  errorMessage?: string;
  isInvalid?: boolean;
}

export function VatRate({
  vatRates,
  vatRate,
  isDisabled,
  vatRateOnChange,
  selectedVatOption,
  updateVatOption,
  label,
  errorMessage,
  isInvalid,
  ...props
}: VatRateProps): JSX.Element {
  const id = useId();
  const { formatMessage } = useIntl();

  const vatRateOptions: VatRateOption[] = vatRates.map((value: number) => {
    let optionLabel;
    let clearable = false;
    if (value === 0) {
      optionLabel = formatMessage({
        id: 'payment-link.create.step-1.item.create.vat.accounting.no-rate',
      });
    } else if (value > 0) {
      optionLabel = `${value} %`;
    } else {
      optionLabel = formatMessage({
        id: 'payment-link.create.step-1.item.create.vat.accounting.other',
      });
      clearable = true;
    }

    return {
      clearable,
      label: optionLabel,
      value,
    };
  });

  const labelId = `vat-rate-${id}`;

  return (
    <div {...props}>
      {label ? (
        <div className={styles['vat-rate-label']}>
          <Label data-test-label id={labelId}>
            {label}
          </Label>
        </div>
      ) : null}
      <CollapseOptions
        aria-label={label}
        aria-labelledby={labelId}
        collapsedContent={
          <AmountField
            amount={vatRate}
            aria-labelledby={labelId}
            className={styles['vat-amount-field']}
            currency="%"
            data-test-vat-amount-percentage
            decimals={1}
            isDisabled={isDisabled}
            label=""
            minValue={0}
            name={`vat-rate-${id}`}
            onAmountChange={vatRateOnChange}
          />
        }
        data-test-collapse-group=""
        disabled={isDisabled}
        onUpdate={updateVatOption}
        options={vatRateOptions}
        selectedOption={selectedVatOption}
      >
        {option => <div data-test-vat-rate-option="">{option.label}</div>}
      </CollapseOptions>
      {errorMessage && isInvalid ? (
        <div className={styles.error} data-test-vat-rate-error="">
          {errorMessage}
        </div>
      ) : null}
    </div>
  );
}
