import type { ReactElement, SVGProps } from 'react';

export function IconNavTransactionOutlined(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="currentColor"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.64003 10.64C1.24003 10.22 1.24003 9.56 1.64003 9.14L8.24 2.36C8.42 2.14 8.7 2 9.02 2C9.28 2 9.54 2.1 9.74 2.32L16.4 9.14C16.8 9.56 16.8 10.22 16.4 10.64C16 11.06 15.36 11.06 14.96 10.64L10.04 5.62V28.94C10.04 29.52 9.58 30 9.02 30C8.46 30 8.00001 29.52 8.00001 28.94V5.62L3.08 10.64C2.68 11.06 2.04003 11.06 1.64003 10.64Z"
        fillRule="evenodd"
      />
      <path
        d="M30.36 21.36C30.76 21.78 30.76 22.44 30.36 22.86L23.76 29.64C23.58 29.86 23.3 30 22.98 30C22.72 30 22.46 29.9 22.26 29.68L15.62 22.86C15.22 22.44 15.22 21.78 15.62 21.36C16.02 20.94 16.66 20.94 17.06 21.36L21.98 26.38V3.06C21.98 2.48 22.44 2 23 2C23.56 2 24.02 2.48 24.02 3.06V26.38L28.94 21.36C29.34 20.94 29.98 20.94 30.38 21.36H30.36Z"
        fillRule="evenodd"
      />
    </svg>
  );
}
