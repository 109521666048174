import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../utils/fetch-client';
import { authBaseURL } from '../utils/environment';

const MFA_SMS_URL = `${authBaseURL}/v1/mfas/sms`;

function postOtpSms(): Promise<unknown> {
  return httpClient.post(MFA_SMS_URL, {
    body: { otp: {} },
    credentials: 'include',
  });
}

export function useOtpSms(): UseMutationResult<unknown, unknown, void> {
  return useMutation({
    mutationKey: ['send-sms'],
    mutationFn: () => postOtpSms(),
  });
}

function postActivateSms({ otpToken }: { otpToken: string }): Promise<unknown> {
  return httpClient.post(`${MFA_SMS_URL}/activate`, {
    body: { otp: { token: otpToken } },
    credentials: 'include',
  });
}

export function useActivateSms(): UseMutationResult<unknown, unknown, { otpToken: string }> {
  return useMutation({
    mutationKey: ['activate-sms'],
    mutationFn: ({ otpToken }: { otpToken: string }) => postActivateSms({ otpToken }),
  });
}

function postTriggerSms({ otpToken }: { otpToken: string }): Promise<unknown> {
  return httpClient.post(`${MFA_SMS_URL}/trigger`, {
    body: { otp: { token: otpToken } },
    credentials: 'include',
  });
}

export function useTriggerSms(): UseMutationResult<unknown, unknown, { token: string }> {
  return useMutation({
    mutationKey: ['trigger-sms'],
    mutationFn: ({ token }: { token: string }) => postTriggerSms({ otpToken: token }),
  });
}
