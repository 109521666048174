import type { ReactElement, ComponentPropsWithoutRef } from 'react';
import cx from 'clsx';
import styles from './avatar.strict-module.css';

export type AvatarSize = '64' | '56' | '48' | '40' | '32' | '28' | '24' | '16';

interface ContentRendererProps {
  content?: ReactElement;
  name?: string;
  props: ComponentPropsWithoutRef<'img'>;
}

function ContentRenderer({ content, name, props }: ContentRendererProps): ReactElement {
  if (content) {
    return <div className={styles['avatar-content']}>{content}</div>;
  }

  if (name) {
    const initials = name
      .split(' ')
      .filter(Boolean)
      .map(initial => (initial[0] ? initial[0].toUpperCase() : ''))
      .slice(0, 2)
      .join('');
    return <span>{initials}</span>;
  }

  const { alt, loading, className, ...propsRest } = props;

  return (
    <img
      alt={alt ?? ''}
      className={cx(styles.avatar, className)}
      data-test-avatar
      loading={loading ?? 'eager'}
      {...propsRest}
    />
  );
}

interface AvatarProps extends Omit<ComponentPropsWithoutRef<'img'>, 'content'> {
  size?: AvatarSize;
  name?: string;
  content?: ReactElement;
  withBorder?: boolean;
  className?: string;
  isDisabled?: boolean;
}

export function Avatar({
  size = '32',
  name = '',
  isDisabled = false,
  withBorder = false,
  content,
  ...props
}: AvatarProps): ReactElement {
  return (
    <div
      className={cx(
        styles['avatar-wrapper'],
        styles[`size-${size}`],
        name && styles['avatar-initials'],
        withBorder && styles['avatar-borders'],
        isDisabled && styles['avatar-disabled']
      )}
    >
      <ContentRenderer content={content} name={name} props={props} />
    </div>
  );
}
