/* eslint-disable react/jsx-no-leaked-render -- It's cleaner to have && in templates */
import type { ReactNode, HTMLAttributes } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import type { BaseButtonProps } from '../button';
import { Button } from '../button';
import { LottiePlayer } from '../lottie-player';
import animationData from '../../assets/lotties/server-error.json';
import styles from './error-state.strict-module.css';

interface ErrorStateProps extends HTMLAttributes<HTMLDivElement> {
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  onRetry?: () => void;
  subtitle: string;
  title: string;
  retryButtonVariant?: BaseButtonProps['variant'];
}

export function ErrorState({
  headingLevel = 'h2',
  onRetry,
  subtitle,
  title,
  retryButtonVariant = 'tertiary',
  ...props
}: ErrorStateProps): ReactNode {
  const intl = useIntl();

  const Heading = headingLevel;

  return (
    <div className={styles.container} data-test-error-state role="alert" {...props}>
      <div className={cx(styles.lottie, 'mb-32')}>
        <LottiePlayer animationData={animationData} data-testid="lottie" />
      </div>

      <Heading className="title-2 mb-8">{title}</Heading>

      <p className={cx('body-2', onRetry && 'mb-32')}>{subtitle}</p>

      {onRetry && (
        <Button onPress={onRetry} variant={retryButtonVariant}>
          {intl.formatMessage({ id: 'error-state-btn-retry' })}
        </Button>
      )}
    </div>
  );
}
