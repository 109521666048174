import type { HTMLAttributes, ReactElement } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { Button } from '../button';
import type { BadgeType } from '../badge';
import { Badge } from '../badge';
import { CloseIcon } from '../../assets/icons';
import styles from './promotional-card.strict-module.css';

interface PromotionalCardProps extends HTMLAttributes<HTMLElement> {
  // Type of badge (optional)
  badgeType?: BadgeType;
  // Text displayed in the badge (optional)
  badgeText?: string;
  // Main content text of the promotional card
  subtitle: string;
  // Call-to-action text
  ctaText: string;
  // Optional callback for when the CTA button is clicked
  onCtaClick?: () => void;
  // Optional callback for when the close button is clicked; if provided, the close button will be displayed
  onCloseClick?: () => void;
}

export function PromotionalCard({
  badgeType,
  badgeText,
  subtitle,
  ctaText,
  onCtaClick,
  onCloseClick,
  className,
  ...props
}: PromotionalCardProps): ReactElement {
  const { formatMessage } = useIntl();

  const showCloseButton = Boolean(onCloseClick);

  const commonProps = {
    ...props,
    className: cx(
      styles['promotional-card'],
      !showCloseButton && styles['promotional-card-interactive'],
      className
    ),
  };

  if (showCloseButton) {
    return (
      <div {...commonProps}>
        <div className={styles.header}>
          {badgeType && badgeText ? <Badge text={badgeText} type={badgeType} /> : null}
          <div className={styles['close-btn-spacer']} />
        </div>

        <span className="caption" data-testid="promotional-card-subtitle">
          {subtitle}
        </span>

        <Button data-testid="promotional-card-cta" onPress={onCtaClick} variant="tertiary">
          {ctaText}
        </Button>

        <Button
          aria-label={formatMessage({ id: 'a11y-buttons-close-aria-label' })}
          className={styles['close-btn']}
          data-testid="promotional-card-close"
          iconOnly
          onPress={onCloseClick}
          size="small"
          variant="tertiary"
        >
          <CloseIcon aria-hidden />
        </Button>
      </div>
    );
  }
  return (
    <button onClick={onCtaClick} type="button" {...commonProps}>
      <div className={styles.header}>
        {badgeType && badgeText ? <Badge text={badgeText} type={badgeType} /> : null}
      </div>
      <span className="caption" data-testid="promotional-card-subtitle">
        {subtitle}
      </span>
      <span className={styles['cta-link']} data-testid="promotional-card-cta">
        {ctaText}
      </span>
    </button>
  );
}
