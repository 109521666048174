export function CodeVerificationSvg(props: React.SVGProps<SVGSVGElement>): React.ReactNode {
  return (
    <svg fill="none" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M74.0366 45.0542C74.0816 45.0294 74.1419 45.0359 74.2047 45.0721L163.614 96.6926C163.747 96.7692 163.854 96.9558 163.854 97.1092L163.854 154.379C163.854 154.448 163.832 154.502 163.792 154.53L162.591 155.363C162.545 155.395 162.48 155.39 162.412 155.351C130.133 136.711 107.764 122.111 74.3408 102.806C74.2114 102.731 74.103 102.55 74.0983 102.398L72.3516 46.1097C72.3491 46.0301 72.3754 45.9694 72.4239 45.9427L74.0366 45.0542Z"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeWidth="0.5"
      />
      <path
        d="M72.5537 48.415L162.471 100.333L162.473 153.204C162.473 154.905 161.279 155.594 159.806 154.744L75.2223 105.906C73.7495 105.056 72.5555 102.987 72.5554 101.287L72.5537 48.415Z"
        fill="white"
      />
      <path
        d="M72.5537 48.415L72.5537 48.165L72.3372 48.04L72.3372 48.29L72.5537 48.415ZM162.471 100.333L162.688 100.458L162.688 100.208L162.471 100.083L162.471 100.333ZM72.5554 101.287L72.3389 101.162L72.5554 101.287ZM162.473 153.204L162.256 153.079L162.473 153.204ZM72.5537 48.665L162.471 100.583L162.471 100.083L72.5537 48.165L72.5537 48.665ZM162.255 100.208L162.256 153.079L162.689 153.329L162.688 100.458L162.255 100.208ZM159.806 154.494L75.2223 105.656L75.2223 106.156L159.806 154.994L159.806 154.494ZM72.7719 101.412L72.7702 48.54L72.3372 48.29L72.3389 101.162L72.7719 101.412ZM75.2223 105.656C73.869 104.875 72.772 102.974 72.7719 101.412L72.3389 101.162C72.339 103.001 73.6299 105.237 75.2223 106.156L75.2223 105.656ZM162.256 153.079C162.256 154.642 161.159 155.275 159.806 154.494L159.806 154.994C161.399 155.914 162.689 155.168 162.689 153.329L162.256 153.079Z"
        fill="#1D1D1B"
      />
      <path
        d="M72.2803 47.2097L72.2821 104.109C72.2821 104.212 72.3539 104.336 72.4424 104.385C74.1562 105.334 76.8915 106.222 79.4601 106.077C82.1082 105.927 113.577 102.537 113.627 102.532C113.628 102.532 113.626 102.532 113.627 102.532C113.676 102.524 116.768 102.019 116.768 100.868C116.768 99.7154 113.659 95.7552 113.627 95.7145C113.626 95.7133 113.628 95.7154 113.627 95.7142L113.626 95.7135C113.469 95.5191 81.7541 56.2409 79.4584 53.4872C77.2283 50.8122 74.1569 48.1635 72.4406 47.1197C72.352 47.0658 72.2803 47.1075 72.2803 47.2097Z"
        fill="white"
        stroke="#1D1D1B"
        strokeWidth="0.5"
      />
      <path
        d="M162.469 98.9484L162.471 156.366C160.774 155.386 157.95 153.072 155.304 149.859C152.658 146.647 121.19 106.83 121.19 106.83C121.19 106.83 118.054 102.695 118.054 101.533C118.054 100.371 121.19 99.9944 121.19 99.9944C121.19 99.9944 153.005 97.2432 155.302 97.1344C157.6 97.0256 160.792 97.9802 162.469 98.9484Z"
        fill="white"
        stroke="#1D1D1B"
        strokeWidth="0.5"
      />
      <path
        d="M72.3507 104.325L162.471 156.36C162.471 155.153 161.398 152.526 158.753 149.117C156.108 145.708 122.13 103.714 122.13 103.714C122.13 103.714 118.912 99.6253 117.088 98.5722C115.264 97.5191 112.045 97.8907 112.045 97.8907C112.045 97.8907 78.2512 101.002 75.9983 101.335C73.7453 101.668 72.3507 103.133 72.3507 104.325Z"
        fill="white"
        stroke="#1D1D1B"
        strokeWidth="0.5"
      />
      <path
        d="M117.689 105.313C120.792 106.889 125.141 106.056 125.183 106.048C125.184 106.047 125.182 106.048 125.183 106.048C125.227 106.041 156.443 101.491 158.826 100.825C161.211 100.158 162.478 100.439 162.478 99.6767L162.478 98.7586C162.478 98.6562 162.406 98.5316 162.317 98.4806L72.736 46.9838C72.4708 46.8313 72.2673 46.9628 72.3405 47.2995C72.6217 48.593 73.6138 51.3302 75.4423 53.7794C77.6343 56.7155 112.493 100.787 112.64 100.973C112.645 100.978 112.642 100.976 112.647 100.982C112.768 101.152 114.672 103.781 117.689 105.313Z"
        fill="white"
        stroke="#1D1D1B"
        strokeWidth="0.5"
      />
      <path
        d="M157.163 80.3869C159.094 79.2083 161.799 79.4048 164.696 81.1726C170.491 84.512 175.32 92.9586 175.32 99.8337C175.32 103.173 174.161 105.53 172.229 106.709L168.366 109.066C170.298 107.887 171.457 105.53 171.457 102.191C171.457 95.3158 166.821 87.0656 160.833 83.5298C157.935 81.7619 155.231 81.5655 153.3 82.7441L157.163 80.3869Z"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <path
        d="M160.836 83.3304C155.041 79.991 150.212 82.7411 150.212 89.4198C150.212 96.2949 154.848 104.545 160.836 108.081C166.63 111.42 171.459 108.67 171.459 101.991C171.266 95.1163 166.63 86.8662 160.836 83.3304Z"
        fill="#F1F78E"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M103.786 102.295L37.1521 63.3135C35.1708 62.1045 32.9439 62.108 31.0767 62.9454C28.7239 64.0005 24.2131 67.8863 24.2131 70.8315L26.9422 92.4588C26.9422 96.6485 29.0916 100.474 32.6741 102.478L72.081 125.429L75.4843 127.615L85.9853 150.602L89.0976 147.835V135.812L100.203 144.619C101.673 145.616 106.052 142.395 108.047 139.885C108.956 138.741 109.518 137.284 109.518 135.63V112.314C109.518 108.124 107.368 104.299 103.786 102.295Z"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M100.843 104.887L34.2098 65.9055C29.7317 63.1731 23.9998 66.6341 23.9998 71.9167V95.0509C23.9998 99.2405 26.1493 103.066 29.7317 105.07L69.1386 128.022L72.5419 130.207L86.1552 150.427V138.405L96.3652 144.234C100.843 146.784 106.575 143.505 106.575 138.222V114.906C106.575 110.716 104.426 106.891 100.843 104.887Z"
        fill="#FDFFD0"
        stroke="#1D1D1B"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <circle
        cx="3.44529"
        cy="3.44529"
        fill="#1D1D1B"
        r="3.44529"
        transform="matrix(0.866025 0.5 -2.20305e-08 1 31 82)"
      />
      <circle
        cx="3.44529"
        cy="3.44529"
        fill="#1D1D1B"
        r="3.44529"
        transform="matrix(0.866025 0.5 -2.20305e-08 1 42.4365 88.6035)"
      />
      <circle
        cx="3.44529"
        cy="3.44529"
        fill="#1D1D1B"
        r="3.44529"
        transform="matrix(0.866025 0.5 -2.20305e-08 1 53.8721 95.2051)"
      />
      <circle
        cx="3.44529"
        cy="3.44529"
        fill="#1D1D1B"
        r="3.44529"
        transform="matrix(0.866025 0.5 -2.20305e-08 1 69.5479 104.459)"
      />
      <circle
        cx="3.44529"
        cy="3.44529"
        fill="#1D1D1B"
        r="3.44529"
        transform="matrix(0.866025 0.5 -2.20305e-08 1 80.9844 111.061)"
      />
      <circle
        cx="3.44529"
        cy="3.44529"
        fill="#1D1D1B"
        r="3.44529"
        transform="matrix(0.866025 0.5 -2.20305e-08 1 92.4209 117.664)"
      />
    </svg>
  );
}
