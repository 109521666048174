import { createRoot } from 'react-dom/client';
import type { UserData } from '../providers/user-provider';
import type { ErrorObject, ScaErrorType } from '../utils/sca-errors';
import { getErrorWithType, isScaError, SCA_ERROR_TYPE } from '../utils/sca-errors';
import { createPortalContainer } from '../utils/dom';
import { Providers } from '../providers';
import { faqService, type FaqService } from '../utils/faq-composer';
import { MfaErrorUI } from './mfa-error-ui';
import { ScaAuthUI } from './sca-auth-ui';
import { ScaEnforcementErrorUI } from './sca-enforcement-error-ui';
import { EmailVerificationUI } from './email-verification-ui';

export interface ScaParams {
  header: 'X-Qonto-MFA' | 'X-Qonto-Sca-Session-Token';
  token: string;
}

export type OnSensitiveAction = (params?: ScaParams) => Promise<void>;

export interface RenderScaParams {
  root: ReturnType<typeof createRoot>;
  cancel: () => void;
  onSensitiveAction: OnSensitiveAction;
  errorType: ScaErrorType;
  errorDetails: ErrorObject;
  user?: UserData;
}

export interface ErrorComponentProps {
  onClose: () => void;
  onSensitiveAction: OnSensitiveAction;
  errorDetails: ErrorObject;
}

export function renderSca({
  root,
  cancel,
  onSensitiveAction,
  errorType,
  errorDetails,
  user,
}: RenderScaParams): void {
  let SCAErrorComponent;

  switch (errorType) {
    case SCA_ERROR_TYPE.MFA:
      SCAErrorComponent = MfaErrorUI;
      break;
    case SCA_ERROR_TYPE.SCA:
      SCAErrorComponent = ScaAuthUI;
      break;
    case SCA_ERROR_TYPE.SCA_ENFORCEMENT:
      SCAErrorComponent = ScaEnforcementErrorUI;
      break;
    case SCA_ERROR_TYPE.EMAIL_VERIFICATION:
      SCAErrorComponent = EmailVerificationUI;
      break;
    default:
      // NOTE: if is not a SCA error, we just cleanup and return
      cancel();
      return;
  }

  root.render(
    <Providers user={user}>
      <SCAErrorComponent
        errorDetails={errorDetails}
        onClose={cancel}
        onSensitiveAction={onSensitiveAction}
      />
    </Providers>
  );
}

export async function sensitiveAction({
  action,
  onScaSuccess,
  hostFaqService,
  user,
}: {
  action: () => Promise<unknown>;
  onScaSuccess?: (params: ScaParams) => Promise<void> | void;
  hostFaqService: FaqService;
  user?: UserData;
}): Promise<unknown> {
  try {
    return await action();
  } catch (error) {
    if (!isScaError(error)) throw error;

    const { type: errorType, error: errorDetails } = getErrorWithType(error);

    if (!errorType || !errorDetails) throw error as Error;

    // NOTE: we need to use the host faq service to get the localized article, this is a temporary solution while we migrate zendesk to vanilla javascript
    faqService.getLocalizedArticle = articleId => hostFaqService.getLocalizedArticle(articleId);
    faqService.getLocalizedHelpCenter = hostFaqService.getLocalizedHelpCenter;

    return new Promise<void>((resolve, _reject) => {
      const portalContainer = createPortalContainer('sca-portal-container');
      const root = createRoot(portalContainer);

      const cleanup = (): void => {
        portalContainer.remove();
        root.unmount();
      };

      const cancel = (): void => {
        cleanup();
        resolve();
      };

      const onSensitiveAction: (params?: ScaParams) => Promise<void> = async params => {
        cleanup();
        // NOTE: in some cases we do not call the onScaSuccess callback because Backend will handle the job for us.
        if (params) await onScaSuccess?.(params);
        await sensitiveAction({ action, onScaSuccess, hostFaqService, user });
        resolve();
      };

      window.addEventListener('popstate', cleanup);
      renderSca({
        root,
        cancel,
        onSensitiveAction,
        errorType,
        errorDetails,
        user,
      });
    });
  }
}
