import type { ReactElement, ReactNode } from 'react';
import { TabPanel as AriaTabPanel } from 'react-aria-components';
import cx from 'clsx';
import styles from './tab-panel.strict-module.css';

export interface TabProps {
  children?: ReactNode;
  tabId: string;
  className?: string;
}

export function TabPanel({ children, tabId, className }: TabProps): ReactElement {
  return (
    <AriaTabPanel className={cx(styles['tab-panel'], className)} id={tabId}>
      {children}
    </AriaTabPanel>
  );
}
