import type { ReactElement } from 'react';
import { useEffect, useState, useRef, useCallback } from 'react';
import type { LottieComponentProps, LottieRefCurrentProps } from 'lottie-react';
import Lottie from 'lottie-react';
import type { LocaleCode } from '@repo/shared-config/constants/locales';

export type { LottieRefCurrentProps } from 'lottie-react';

export type LottiePlayerProps = Partial<LottieComponentProps> & {
  animationData?: object;
  fetchingOptions?: RequestInit;
  onDataReady?: () => void;
  onDOMLoaded?: () => void;
  onError?: (error: LottieError) => void;
  path?: string;
  relativePath?: string;
  locale?: LocaleCode;
  speed?: number;
};

export class LottieError extends Error {
  status: number;
  statusText: string;

  constructor(status: number, statusText: string) {
    super(statusText);

    this.name = 'LottieError';

    this.status = status;
    this.statusText = statusText;
  }
}
const LOTTIES_ROOT_FOLDER = '/lotties';

export function LottiePlayer({
  animationData,
  fetchingOptions,
  onDataReady,
  onDOMLoaded,
  onError,
  path,
  relativePath,
  locale,
  speed,
  ...props
}: LottiePlayerProps): ReactElement {
  const [data, setData] = useState<object | null>(null);
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  const fetchLottie = useCallback(
    async (lpath: string) => {
      const response: Response = fetchingOptions
        ? await fetch(lpath, fetchingOptions)
        : await fetch(lpath);
      if (!response.ok) {
        throw new LottieError(response.status, response.statusText);
      } else {
        const json: object = (await response.json()) as object;
        setData(json);
      }
    },
    [fetchingOptions]
  );

  const getLocalizedPath = useCallback(
    async (rpath: string) => {
      if (locale) {
        const localizedPath = `${LOTTIES_ROOT_FOLDER}/localized/${locale}${rpath}`;
        try {
          // Check if the file exists and is of the correct type before actually fetching it
          const response = await fetch(localizedPath, { method: 'HEAD' });
          if (response.ok && response.headers.get('Content-Type')?.includes('application/json')) {
            return localizedPath;
          }
        } catch {
          // fallback to default path without localization folder
        }
      }
      return LOTTIES_ROOT_FOLDER.concat(rpath);
    },
    [locale]
  );

  useEffect(() => {
    const loadAnimation = async (): Promise<void> => {
      if (animationData) {
        setData(animationData);
        onDataReady?.();
        return;
      }

      if (relativePath || path) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- as path is always defined if relativePath is not
        const resolvedPath = relativePath ? await getLocalizedPath(relativePath) : path!;
        try {
          await fetchLottie(resolvedPath);
        } catch (error: unknown) {
          if (onError && error instanceof LottieError) {
            // We do not return the error object directly because we faced issues when doing so.
            // The error object was not being recognized as an instance of LottieError in the tests but as a string.
            // Then, we could not reach status and statusText properties in the tests.
            onError({ ...error });
          }
        }
        onDataReady?.();
      }
    };

    void loadAnimation();
  }, [
    animationData,
    fetchingOptions,
    path,
    onDataReady,
    onError,
    fetchLottie,
    getLocalizedPath,
    relativePath,
    locale,
  ]);
  const onDOMLoadedCallback = (): void => {
    if (lottieRef.current && speed) {
      lottieRef.current.setSpeed(speed);
    }
    onDOMLoaded?.();
  };

  return (
    <Lottie
      lottieRef={lottieRef}
      {...props}
      animationData={data}
      data-test-autoplay={props.autoplay}
      onDOMLoaded={onDOMLoadedCallback}
    />
  );
}
