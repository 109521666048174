import { forwardRef } from 'react';
import type { ReactNode } from 'react';
import cx from 'clsx';
import { IconChevronLeftOutlined, IconCrossRoundedFilled } from '@repo/monochrome-icons';
import { Button } from 'react-aria-components';
import { useIntl } from 'react-intl';
import type { ButtonProps } from 'react-aria-components';
import ButtonStyles from '../../primitives/button.strict-module.css';
import styles from './filter-button.strict-module.css';

interface FilterButtonProps extends ButtonProps {
  children: ReactNode;
  handleCancel: (e: React.MouseEvent) => void;
  isActive: boolean;
  isOpen: boolean;
}

export const FilterButton = forwardRef<HTMLButtonElement, FilterButtonProps>(
  function FilterButton(props, ref): ReactNode {
    const { children, className, handleCancel, isActive, isOpen, ...rest } = props;
    const { formatMessage } = useIntl();
    return (
      <>
        <Button
          className={cx(
            className,
            ButtonStyles['btn-filter'],
            styles.btn,
            isActive && ButtonStyles['btn--active']
          )}
          ref={ref}
          {...rest}
        >
          {children}
          {isActive ? (
            <span aria-hidden="true" className={styles['cancel-placeholder']} />
          ) : (
            <IconChevronLeftOutlined
              className={cx(isOpen && styles['is-open'])}
              data-test-filter-arrow-icon
            />
          )}
        </Button>
        {isActive ? (
          <button
            aria-label={formatMessage({ id: 'a11y.buttons.close-aria-label' })}
            className={styles['period-cancel-btn']}
            data-test-cancel-button
            onClick={handleCancel}
            type="button"
          >
            <IconCrossRoundedFilled aria-hidden="true" />
          </button>
        ) : null}
      </>
    );
  }
);
