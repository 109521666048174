import { type KemInterface } from 'hpke-js';
import { HPKE_CARDS_BE_PUBLIC_KEYS_TABLE } from '../constants/public-keys.ts';
import { arrayBufferToB64, b64ToArrayBuffer } from './base-64.ts';

export async function deserializeB64PublicKey(
  b64PublicKey: string,
  kem: KemInterface
): Promise<CryptoKey> {
  return kem.deserializePublicKey(b64ToArrayBuffer(b64PublicKey));
}

export async function serializePublicKeyToB64(
  publicKey: CryptoKey,
  kem: KemInterface
): Promise<string> {
  const serializedPk: ArrayBuffer = await kem.serializePublicKey(publicKey);
  return arrayBufferToB64(new Uint8Array(serializedPk));
}

export async function getPublicKey({
  backendUrlMode,
  kem,
  publicKeyId,
}: {
  backendUrlMode: string;
  kem: KemInterface;
  publicKeyId: string;
}): Promise<CryptoKey> {
  const keyType = backendUrlMode === 'production' ? 'production' : 'staging';
  const b64Pk = HPKE_CARDS_BE_PUBLIC_KEYS_TABLE[keyType][publicKeyId];

  if (!b64Pk) {
    throw new Error(`Missing public key for "${publicKeyId}" ID in ${keyType}`);
  }

  return deserializeB64PublicKey(b64Pk, kem);
}
