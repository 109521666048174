import { type CSSProperties, type ReactNode } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { UniqueIdentifier } from '@dnd-kit/core/dist/types/other';
import type { DraggableAttributes } from '@dnd-kit/core';

export interface DraggableContainerProps extends Pick<DraggableAttributes, 'aria-roledescription'> {
  style: CSSProperties;
  ref: (node: HTMLElement | null) => void;
}

interface DraggableProps {
  id: UniqueIdentifier;
  children: (draggableProps: DraggableContainerProps) => ReactNode;
}

export function Draggable({ id, children }: DraggableProps): ReactNode {
  const { attributes, isDragging, setNodeRef, transform, transition } = useSortable({
    id,
  });

  return children({
    'aria-roledescription': attributes['aria-roledescription'],
    style: {
      transform: CSS.Transform.toString(
        transform
          ? {
              x: transform.x,
              y: transform.y,
              scaleX: isDragging ? 1.01 : 1,
              scaleY: isDragging ? 1.01 : 1,
            }
          : null
      ),
      transition,
      zIndex: isDragging ? 1 : undefined,
    },
    ref: setNodeRef,
  });
}
