import React, { useEffect, useId, useRef, useState } from 'react';
import { FieldError, Label, TextField } from 'react-aria-components';
import { clsx } from 'clsx';
import { Input } from '@repo/design-system-kit';
import {
  IconCalendarOutlined,
  IconCardOutlined,
  IconLockOutlined,
  IconUserOutlined,
} from '@repo/monochrome-icons';
import type { Mollie, MollieComponentInstance } from '../../../types/mollie.js';
import styles from './credit-card-input.strict-module.css';

const CREDIT_CARD_ICONS = {
  cardNumber: <IconCardOutlined aria-hidden="true" />,
  cardHolder: <IconUserOutlined aria-hidden="true" />,
  expiryDate: <IconCalendarOutlined aria-hidden="true" />,
  verificationCode: <IconLockOutlined aria-hidden="true" />,
};

interface CreditCardInputProps {
  disabled?: boolean;
  hasSubmitted: boolean;
  label: string;
  mollie?: Mollie | null;
  type: 'cardHolder' | 'cardNumber' | 'expiryDate' | 'verificationCode';
  withMollie: boolean;
}

export function CreditCardInput({
  disabled,
  hasSubmitted,
  mollie,
  type,
  label,
  withMollie,
}: CreditCardInputProps): React.ReactNode {
  const fieldId = useId();
  const componentRef = useRef<MollieComponentInstance | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [hasTyped, setHasTyped] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (mollie && !componentRef.current) {
      const mollieComponent = mollie.createComponent(type, {
        styles: {
          // We need to inject CSS this way as Mollie Component is an input in an iframe
          base: { color: '#1d1d1b' },
        },
      });
      mollieComponent.mount(`[id="${fieldId}"]`);
      mollieComponent.addEventListener('focus', () => {
        setIsFocused(true);
      });
      mollieComponent.addEventListener('blur', () => {
        setIsFocused(false);
      });
      mollieComponent.addEventListener('change', event => {
        if (event.dirty && !hasTyped) {
          setHasTyped(true);
        }
        setErrorMessage(event.error ?? '');
      });
      componentRef.current = mollieComponent;
    }
  }, [componentRef, fieldId, mollie, type, hasTyped]);

  useEffect(() => {
    return () => {
      if (componentRef.current) {
        componentRef.current.unmount();
        componentRef.current = null;
      }
    };
  }, [componentRef]);

  const shouldShowIcon = !(hasTyped && type === 'cardNumber');

  return (
    <TextField
      className={styles['credit-card-input']}
      isDisabled={disabled}
      isInvalid={hasSubmitted ? Boolean(errorMessage) : false}
    >
      {/* We pass `type` as the `htmlFor` prop because the Mollie component passes this value to the input as its `id` */}
      <Label className={clsx('body-1', disabled && styles.disabled)} htmlFor={type}>
        {label}
      </Label>
      <div
        className={clsx(
          styles['input-container'],
          withMollie && styles['input-field'],
          isFocused && styles['input-field-focused']
        )}
        id={fieldId}
      >
        {/* If Mollie is not there, we render an input field for testing, otherwise Mollie provides the component */}
        {!withMollie ? <Input className={styles['input-test']} disabled={disabled} /> : null}
        {shouldShowIcon ? (
          <span className={clsx(styles['input-icon'], disabled && styles.disabled)}>
            {CREDIT_CARD_ICONS[type]}
          </span>
        ) : null}
      </div>
      <FieldError className={styles.error}>{hasSubmitted ? errorMessage : ''}</FieldError>
    </TextField>
  );
}
