export const httpClient = {
  handleNoContentResponse: async <T>(response: Response): Promise<T> => {
    const contentType = response.headers.get('content-type');
    if (response.status === 204 || !contentType || !contentType.includes('application/json')) {
      return {} as T;
    }
    return (await response.json()) as T;
  },

  get: async <T>(
    url: string,
    options?: {
      headers?: Record<string, string>;
      params?: Record<string, string | number | boolean>;
    }
  ): Promise<T> => {
    const queryString = options?.params
      ? new URLSearchParams(
          Object.entries(options.params).map(([k, v]) => [k, String(v)])
        ).toString()
      : '';
    const fullUrl = queryString ? `${url}?${queryString}` : url;
    const response = await fetch(fullUrl, {
      method: 'GET',
      headers: options?.headers,
    });

    const contentType = response.headers.get('content-type');
    if (response.status === 204 || !contentType || !contentType.includes('application/json')) {
      return {} as T;
    }

    return (await response.json()) as T;
  },

  post: async <T, D>(
    url: string,
    { body, headers, ...options }: Omit<RequestInit, 'method' | 'body'> & { body?: D }
  ): Promise<T> => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(body),
      ...options,
    });

    const contentType = response.headers.get('content-type');
    if (response.status === 204 || !contentType || !contentType.includes('application/json')) {
      return {} as T;
    }

    return (await response.json()) as T;
  },
};
