import cx from 'clsx';
import type { HTMLAttributes, ReactElement } from 'react';
import { Heading } from 'react-aria-components';
import styles from '../popup.strict-module.css';

export function PopupTitle({
  children,
  ...props
}: HTMLAttributes<HTMLHeadingElement>): ReactElement {
  return (
    <Heading
      className={cx(styles.title, 'title-2')}
      data-test-id="modal-title"
      level={1}
      slot="title"
      {...props}
    >
      {children}
    </Heading>
  );
}
