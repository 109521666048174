import { useState, type FormEvent, type ReactNode } from 'react';
import {
  AmountField,
  Button,
  ChoiceChip,
  DatePicker,
  Select,
  SelectOption,
  TextField,
} from '@repo/design-system-kit';
import cx from 'clsx';
import type { DateValue } from 'react-aria-components';
import { parseDate } from '@internationalized/date';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import styles from './form.strict-module.css';

interface FormData {
  amount: string;
  expectedDate: string;
  name: string;
  id: string;
  side: 'debit' | 'credit';
  frequency: string;
}

interface FormProps {
  existingUpcomingTransactionData?: FormData;
  onCancel: () => void;
  onDelete?: () => void;
  onUpdate: (formData: FormData) => void;
}

export function ManualEntryForm({
  existingUpcomingTransactionData,
  onCancel,
  onDelete,
  onUpdate,
}: FormProps): ReactNode {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const editionState = Boolean(existingUpcomingTransactionData);
  const [form, setForm] = useState<FormData>(() => ({
    id: existingUpcomingTransactionData?.id || '',
    name: existingUpcomingTransactionData?.name || '',
    amount: existingUpcomingTransactionData?.amount || '',
    expectedDate: existingUpcomingTransactionData?.expectedDate || '',
    side: existingUpcomingTransactionData?.side || 'credit',
    frequency: existingUpcomingTransactionData?.frequency || 'none',
  }));

  const handleChange = (field: keyof FormData, value: string | null): void => {
    // If changing amount and it contains a minus sign, set side to debit
    if (field === 'amount' && typeof value === 'string' && value.includes('-')) {
      setForm(prev => ({
        ...prev,
        [field]: typeof value === 'string' ? value || '' : value,
        side: 'debit',
      }));
    } else if (field === 'amount' && typeof value === 'string' && !value.includes('-')) {
      // If changing amount and it doesn't contain a minus sign, set side to credit
      setForm(prev => ({
        ...prev,
        [field]: typeof value === 'string' ? value || '' : value,
        side: 'credit',
      }));
    } else {
      // For other fields, just update normally
      setForm(prev => ({
        ...prev,
        [field]: typeof value === 'string' ? value || '' : value,
      }));
    }
  };

  const handleDateChange = (newValue: DateValue | null): void => {
    setForm(prev => ({
      ...prev,
      expectedDate: newValue?.toString() || '',
    }));
  };

  const { formatMessage } = useIntl();
  const errorMessage = formatMessage({
    id: 'validations.errors.blank',
  });

  const isNameInvalid = hasSubmitted && !form.name.trim();
  const isAmountInvalid = hasSubmitted && form.amount === '';

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    setHasSubmitted(true);
    if (!form.name.trim() || form.amount === '') {
      return;
    }
    onUpdate(form);
  };

  const frequencyItems = [
    {
      key: 'none',
      copy: formatMessage({ id: 'cash-flow.upcoming-transactions.form.frequency.none' }),
    },
    {
      key: 'weekly',
      copy: formatMessage({ id: 'cash-flow.upcoming-transactions.form.frequency.weekly' }),
    },
    {
      key: 'monthly',
      copy: formatMessage({ id: 'cash-flow.upcoming-transactions.form.frequency.monthly' }),
    },
    {
      key: 'quarterly',
      copy: formatMessage({ id: 'cash-flow.upcoming-transactions.form.frequency.quarterly' }),
    },
    {
      key: 'biyearly',
      copy: formatMessage({ id: 'cash-flow.upcoming-transactions.form.frequency.half-yearly' }),
    },
    {
      key: 'yearly',
      copy: formatMessage({ id: 'cash-flow.upcoming-transactions.form.frequency.annual' }),
    },
  ];

  const formTitle = formatMessage({
    id: editionState
      ? 'cash-flow.upcoming-transactions.form.actions-edit.title'
      : 'cash-flow.upcoming-transactions.form.title',
  });

  const submitButtonCopy = formatMessage({
    id: editionState
      ? 'cash-flow.upcoming-transactions.form.actions-save-changes'
      : 'cash-flow.upcoming-transactions.form.actions-submit',
  });

  const today = dayjs().format('YYYY-MM-DD');

  return (
    <div className={styles.container} data-testid="manual-entry-form">
      <div className={styles['form-container']}>
        <h2 className={cx('title-2', 'mb-32', styles.title)} data-testid="manual-entry-form-title">
          {formTitle}
        </h2>
        <form aria-labelledby="form" onSubmit={handleSubmit}>
          <div className={styles.form}>
            <div data-test-name-field>
              <TextField
                data-testid="name-field"
                errorMessage={isNameInvalid ? errorMessage : undefined}
                isInvalid={isNameInvalid}
                label={formatMessage({
                  id: 'cash-flow.upcoming-transactions.form.name.label',
                })}
                onChange={value => {
                  handleChange('name', value);
                }}
                placeholder={formatMessage({
                  id: 'cash-flow.upcoming-transactions.form.name.placeholder',
                })}
                value={form.name}
              />
            </div>
            <div className={styles['amount-row']}>
              <div className={styles['full-width']}>
                <AmountField
                  amount={form.amount}
                  aria-label={formatMessage({
                    id: 'cash-flow.upcoming-transactions.form.amount.label',
                  })}
                  data-testid="amount-field"
                  errorMessage={isAmountInvalid ? errorMessage : undefined}
                  label={formatMessage({
                    id: 'cash-flow.upcoming-transactions.form.amount.label',
                  })}
                  onAmountChange={value => {
                    handleChange('amount', value);
                  }}
                />
              </div>
              <div>
                <div aria-labelledby="side-label" className={styles.side} role="radiogroup">
                  {['credit', 'debit'].map(side => (
                    <ChoiceChip
                      checkedValues={[form.side]}
                      data-testid="choice-chips"
                      key={side}
                      onChange={() => {
                        handleChange('side', side);
                      }}
                      value={side}
                    >
                      <FormattedMessage
                        id={
                          side === 'credit'
                            ? 'cash-flow.upcoming-transactions.form.button-inflow'
                            : 'cash-flow.upcoming-transactions.form.button-outflow'
                        }
                      />
                    </ChoiceChip>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles['date-row']}>
              <div className={styles['full-width']}>
                <DatePicker
                  data-testid="date-picker"
                  label={formatMessage({
                    id: 'cash-flow.upcoming-transactions.form.date.label',
                  })}
                  minValue={parseDate(today)}
                  onChange={handleDateChange}
                  value={
                    form.expectedDate ? parseDate(form.expectedDate.substring(0, 10)) : undefined
                  }
                />
              </div>
              <div className={styles['full-width']}>
                <Select
                  aria-label={formatMessage({
                    id: 'cash-flow.upcoming-transactions.form.frequency.label',
                  })}
                  data-testid="select"
                  defaultSelectedKey={form.frequency || 'none'}
                  label={formatMessage({
                    id: 'cash-flow.upcoming-transactions.form.frequency.label',
                  })}
                  onSelectionChange={key => {
                    handleChange('frequency', key as string);
                  }}
                  placeholder=""
                  selectedKey={form.frequency || 'none'}
                >
                  {frequencyItems.map(({ key, copy }) => (
                    <SelectOption id={key} key={key}>
                      {copy}
                    </SelectOption>
                  ))}
                </Select>
              </div>
            </div>
            <div className={styles.actions}>
              <div className={styles['primary-btns']}>
                <Button
                  data-testid="cancel-btn"
                  onPress={onCancel}
                  type="button"
                  variant="secondary"
                >
                  <FormattedMessage id="cash-flow.upcoming-transactions.form.actions-cancel" />
                </Button>
                <Button
                  aria-label={formatMessage({
                    id: 'cash-flow.upcoming-transactions.form.actions-submit',
                  })}
                  data-testid="submit-btn"
                  type="submit"
                  variant="primary"
                >
                  {submitButtonCopy}
                </Button>
              </div>
              {editionState ? (
                <Button
                  data-testid="delete-btn"
                  onPress={() => {
                    onDelete?.();
                  }}
                  type="button"
                  variant="tertiary"
                >
                  <FormattedMessage id="cash-flow.upcoming-transactions.form.actions-delete" />
                </Button>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
