import cx from 'clsx';
import type { ReactElement } from 'react';
import { Card, type CardProps } from './card';
import styles from './cards.strict-module.css';

export type CardItem = Omit<CardProps, 'index'>;

interface CardsProps {
  items: CardItem[];
  className?: string;
}

export function Cards({ items, className }: CardsProps): ReactElement {
  return (
    <div className={cx(styles.cards, className)} data-testid="empty-state-cards">
      {items.map((item, index) => (
        <Card index={index} key={item.type} {...item} />
      ))}
    </div>
  );
}
