import { useIntl } from 'react-intl';
import { GenericErrorSvg } from '../../svgs/generic-error';
import { EmailVerificationGeneric } from './generic';

export function GenericError(): React.ReactNode {
  const { formatMessage } = useIntl();
  return (
    <EmailVerificationGeneric
      data-testid="generic-error"
      illustration={<GenericErrorSvg />}
      subtitle={formatMessage({
        id: 'sca.sensitive-actions.accordion.confirm-device.generic-error.subtitle',
      })}
      title={formatMessage({
        id: 'sca.sensitive-actions.accordion.confirm-device.generic-error.title',
      })}
    />
  );
}
