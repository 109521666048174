import UAParser from 'ua-parser-js';

const isAndroidBridge =
  // @ts-expect-error -- nativeBridge not defined on default window type
  typeof window !== 'undefined' && Boolean(window.nativeBridge);
const isIOSBridge =
  typeof window !== 'undefined' &&
  // @ts-expect-error -- webkit not defined on default window type
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Not typed
  Boolean(window.webkit?.messageHandlers?.nativeBridge);

export const isWebView = isAndroidBridge || isIOSBridge;

const parser = typeof window === 'undefined' ? null : new UAParser();

export const os = parser?.getOS();
export const browser = parser?.getBrowser();
export const device = parser?.getDevice();
export const isMobile = device?.type === 'mobile';
