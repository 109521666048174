export function ConfirmDeviceSvg(props: React.SVGProps<SVGSVGElement>): React.ReactNode {
  return (
    <svg
      fill="none"
      height="120"
      viewBox="0 0 120 120"
      width="120"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M67.6727 6.4851L66.9446 6.03703C66.8326 5.98102 66.6085 5.98103 66.3845 6.09304L12.7843 37.0098C12.3362 37.2338 12.0002 37.8499 12.0002 38.354V89.0977C12.0002 89.3218 12.1122 89.5458 12.2242 89.6018L12.8963 89.9939C13.0643 90.0499 13.2324 90.0499 13.4564 89.9379L67.1126 59.0771C67.5607 58.8531 67.8967 58.237 67.8967 57.7329V6.98918C67.8967 6.70914 67.8407 6.59712 67.6727 6.4851Z"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <path
        d="M13.5124 37.4597L67.1126 6.54292C67.5607 6.31889 67.8967 6.48691 67.8967 6.99099V57.7347C67.8967 58.2388 67.5607 58.8549 67.1126 59.079L13.5124 89.9957C13.0643 90.2197 12.7283 90.0517 12.7283 89.5476V38.8039C12.7843 38.2998 13.1203 37.6837 13.5124 37.4597Z"
        fill="#FDFFD0"
        stroke="#1D1D1B"
        strokeWidth="0.5"
      />
      <path
        d="M15.3603 40.0338L65.2639 11.2454V53.8119L15.3603 82.6003V40.0338Z"
        fill="white"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <path
        d="M108.111 79.3736L68.1209 56.298C67.7289 56.074 67.1688 56.074 66.7767 56.298L12.8965 87.3828C12.7285 87.4948 12.6164 87.6068 12.6164 87.7748V89.5671C12.6164 89.7351 12.7285 89.8472 12.8965 89.9592L52.8866 113.035C53.2787 113.259 53.8388 113.259 54.2308 113.035L108.111 81.95C108.279 81.8379 108.391 81.7259 108.391 81.5579V79.7656C108.391 79.6536 108.335 79.4856 108.111 79.3736Z"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <path
        d="M68.1766 56.298L108.111 79.3736C108.503 79.5976 108.503 79.9336 108.111 80.1577L54.2305 111.242C53.8384 111.466 53.2783 111.466 52.8863 111.242L12.8962 88.1669C12.5041 87.9429 12.5041 87.6068 12.8962 87.3828L66.7764 56.298C67.1685 56.074 67.7846 56.074 68.1766 56.298Z"
        fill="white"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <rect
        fill="white"
        height="8.2907"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 62.7014 92.6573)"
        width="16.8615"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 30.145 93.0209)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 34.4556 95.514)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 48.6724 82.3256)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 34.7778 90.35)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 39.0864 92.8363)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 53.3071 79.6517)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 39.4106 87.6732)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 43.7192 90.1635)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 57.938 76.9818)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 67.2017 71.6302)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 71.5083 74.1166)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 44.0415 84.9994)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 62.5708 74.3041)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.93459"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 66.9146 76.8148)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 71.8325 68.9535)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.96811"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 76.147 71.4467)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 48.3521 87.4896)"
        width="21.3923"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 25.8381 90.5316)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 44.3635 79.8441)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 30.467 87.8617)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 48.9963 77.1635)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 35.0979 85.1879)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 53.6272 74.4857)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 39.7307 82.5101)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 58.26 71.8187)"
        width="5.34807"
      />
      <rect
        fill="#FDFFD0"
        height="4.97571"
        rx="0.240117"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866025 -0.5 0.866025 0.5 62.8909 69.142)"
        width="10.6961"
      />
      <rect
        fill="#1D1D1B"
        height="33.1952"
        rx="3"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866009 -0.500028 -3.18609e-05 1 43.5371 40.2568)"
        width="53.4082"
      />
      <rect
        fill="#F4F8AC"
        height="33.1952"
        rx="3"
        stroke="#1D1D1B"
        strokeWidth="0.5"
        transform="matrix(0.866009 -0.500028 -3.18609e-05 1 44.4023 40.7568)"
        width="53.4082"
      />
      <mask height="60" id="mask0_36878_2628" maskUnits="userSpaceOnUse" width="47" x="44" y="14">
        <rect
          fill="#F4F8AC"
          height="33.1952"
          rx="3"
          stroke="#1D1D1B"
          strokeWidth="0.5"
          transform="matrix(0.866009 -0.500028 -3.18609e-05 1 44.4043 40.7559)"
          width="53.4082"
        />
      </mask>
      <g mask="url(#mask0_36878_2628)">
        <path
          d="M44.4043 42.7554L65.9986 51.3854C66.888 51.7409 67.9046 51.4158 68.4228 50.6103L90.6563 16.0498"
          stroke="#1D1D1B"
          strokeWidth="0.5"
        />
      </g>
      <g clipPath="url(#clip0_36878_2628)">
        <path
          d="M65.7631 36.9339C65.2678 36.647 64.9732 36.0541 64.9355 35.2687C65.634 34.9102 66.2931 34.8713 66.7851 35.1596C67.2803 35.4468 67.5749 36.0396 67.6127 36.8249C66.9142 37.1834 66.2551 37.2223 65.7631 36.9339ZM69.7702 34.6203C69.2781 35.4768 68.619 36.1991 67.9205 36.6472C67.9584 35.8182 68.253 34.8852 68.7482 34.0261C69.2403 33.1696 69.8994 32.4473 70.5979 31.9993C70.5602 32.8282 70.2655 33.7613 69.7702 34.6203ZM69.7701 36.8724C70.2654 37.1594 70.56 37.7522 70.5977 38.5377C69.8992 38.8962 69.2401 38.9351 68.7481 38.6468C68.2529 38.3596 67.9583 37.7668 67.9205 36.9815C68.619 36.623 69.2781 36.5841 69.7701 36.8724ZM65.763 39.1861C66.2551 38.3296 66.9239 37.6014 67.6223 37.1533C67.5845 37.9823 67.2802 38.9212 66.785 39.7802C66.2929 40.6368 65.6338 41.359 64.9353 41.8071C64.973 40.9781 65.2677 40.0451 65.763 39.1861Z"
          fill="#1D1D1B"
        />
      </g>
      <defs>
        <clipPath id="clip0_36878_2628">
          <rect
            fill="white"
            height="6.53841"
            transform="matrix(0.866009 -0.500028 -3.18609e-05 1 64.9355 35.2687)"
            width="6.53841"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
