import { useRef } from 'react';
import type { HTMLProps, ReactNode } from 'react';
import cx from 'clsx';
import type { Option } from './collapse-option.tsx';
import { CollapseOption } from './collapse-option.tsx';
import styles from './collapse-options.strict-module.css';
import { fadeIn, fadeOut, translate } from './animations.ts';

interface CollapseOptionsProps<T> extends Omit<HTMLProps<HTMLDivElement>, 'children'> {
  options: T[];
  selectedOption?: T;
  hideCustomInputs?: boolean;
  disabled?: boolean;
  allowWrapping?: boolean;
  collapsedContent?: ReactNode;
  onUpdate: (option?: T) => void;
  onClear?: () => void;
  className?: string;
  children: (option: T, index: number) => ReactNode;
}

export function CollapseOptions<T extends Option>({
  options,
  selectedOption,
  hideCustomInputs,
  disabled,
  allowWrapping,
  collapsedContent,
  onUpdate,
  onClear,
  className,
  children,
  ...props
}: CollapseOptionsProps<T>): ReactNode {
  const containerRef = useRef<HTMLDivElement>(null);
  const isCollapsed =
    !hideCustomInputs && Boolean(selectedOption?.clearable && selectedOption.value);
  const wrap = allowWrapping && !isCollapsed;

  async function handleSelect(option: T): Promise<void> {
    const element = containerRef.current;
    if (!element) return;

    onUpdate(option);
    const { value, clearable } = option;

    const reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

    if (value !== 0 && !hideCustomInputs && clearable && !reduceMotion.matches) {
      const optionElements: HTMLElement[] = Array.from(
        element.querySelectorAll('[data-collapse-option]')
      );
      const collapsedContentElement: HTMLElement | null = element.querySelector(
        '[data-collapsed-content]'
      );

      const startPositions = optionElements.map(optionElement => optionElement.offsetLeft);

      await Promise.all([
        ...optionElements.map((optionElement, i) => {
          const startPosition = startPositions[i];
          if (optionElement.dataset.collapseOption === String(value)) {
            return translate(optionElement, startPosition);
          }
          return fadeOut(optionElement, startPosition);
        }),
        collapsedContentElement && fadeIn(collapsedContentElement),
      ]);
    }
  }

  function handleClear(): void {
    onUpdate();
    onClear?.();
  }

  return (
    <div
      aria-disabled={disabled}
      className={cx(styles.wrapper, wrap && styles.wrapperWrap, className)}
      ref={containerRef}
      role="listbox"
      {...props}
    >
      {options.map((option, index) => (
        <CollapseOption
          disabled={disabled}
          isClearable={option.clearable}
          isCollapsed={isCollapsed}
          isSelected={option.value === selectedOption?.value}
          key={String(option.value)}
          onClear={handleClear}
          onSelect={handleSelect}
          option={option}
        >
          {children(option, index)}
        </CollapseOption>
      ))}
      {collapsedContent ? (
        <div
          className={cx(styles.content, isCollapsed && styles.contentVisible)}
          data-collapsed-content=""
        >
          {collapsedContent}
        </div>
      ) : null}
    </div>
  );
}
