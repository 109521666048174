import { IconUploadOutlined } from '@repo/monochrome-icons';
import { useEffect, useState, type ReactElement } from 'react';
import type { DropEvent, DropItem } from 'react-aria';
import { DropZone as AriaDropZone, Button, FileTrigger } from 'react-aria-components';
import type { DropZoneProps as AriaDropZoneProps } from 'react-aria-components';
import { useIntl } from 'react-intl';
import { Label } from '../form-elements';
import { useFormatBytes } from '../../hooks/use-format-bytes';
import { formatExtentions } from '../../utils/format-extentions';
import { Attachment } from '../attachment';
import styles from './dropzone.strict-module.css';

interface DropZoneProps extends AriaDropZoneProps {
  className?: string;
  label?: string;
  placeholder: string;
  allowsMultiple?: boolean;
  acceptedFileTypes?: string[];
  maxFileSize?: number;
  onFilesSelect: (e: File[]) => void;
  showFileList?: boolean;
  customErrorMessage?: string;
}

export function DropZone({
  className,
  label,
  placeholder,
  allowsMultiple = false,
  acceptedFileTypes = ['*'],
  maxFileSize = 30 * 1e6,
  onFilesSelect,
  showFileList = true,
  customErrorMessage,
  ...props
}: DropZoneProps): ReactElement {
  const { formatMessage } = useIntl();
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);
  const maxFileSizeFormatted = useFormatBytes(maxFileSize);

  useEffect(() => {
    onFilesSelect(files);
  }, [files, onFilesSelect]);

  const addFiles = (fileItems: File[]): void => {
    if (allowsMultiple) {
      setFiles(prevFiles => [...prevFiles, ...fileItems]);
    } else {
      setFiles(fileItems[0] ? [fileItems[0]] : []);
    }
  };

  const resetError = (): void => {
    setError(null);
  };

  const onSelect = (e: FileList | null): void => {
    resetError();
    const filesFromSelect = Array.from(e || []);
    addFiles(filesFromSelect);
  };

  const onDrop = async (e: DropEvent): Promise<void> => {
    resetError();
    const filesFromItems = await transformDropItems(e.items);
    addFiles(filesFromItems);
  };

  const transformDropItems = async (items: DropItem[]): Promise<File[]> => {
    const filePromises = items.map(async item => {
      if ('getFile' in item) {
        return item.getFile();
      }
      return null;
    });

    const filesFromItems = await Promise.all(filePromises);
    const filteredFiles = filesFromItems.filter((file): file is File => {
      if (file === null) return false;
      if (!acceptedFileTypes.includes(file.type)) {
        const errorMsg = formatMessage(
          { id: 'wrong_file_extension' },
          {
            extension: formatExtentions([file.type]),
            extensions: formatExtentions(acceptedFileTypes),
          }
        );
        setError(errorMsg);
        return false;
      }
      if (file.size > maxFileSize) {
        const errorMsg = formatMessage({ id: 'file_too_big' }, { maxSize: maxFileSizeFormatted });
        setError(errorMsg);
        return false;
      }
      return true;
    });
    return filteredFiles;
  };

  const removeFile = (file: File): void => {
    setFiles(prevFiles => prevFiles.filter(f => f.name !== file.name));
  };

  return (
    <>
      {Boolean(label) && <Label>{label}</Label>}

      <AriaDropZone
        {...props}
        className={`${className} ${styles.dropzone} ${error ? styles.error : ''}`}
        onDrop={onDrop}
      >
        <FileTrigger
          acceptedFileTypes={acceptedFileTypes}
          allowsMultiple={allowsMultiple}
          onSelect={onSelect}
        >
          <Button className={styles.fileUploader}>
            <IconUploadOutlined className={styles.icon} />
            <span className={`${styles.placeholder} body-2`}>{placeholder}</span>
          </Button>
        </FileTrigger>
      </AriaDropZone>
      {Boolean(customErrorMessage || error) && (
        <p className={`caption ${styles.errorMessage}`} data-testid="error-message">
          {customErrorMessage || error}
        </p>
      )}
      {Boolean(files.length > 0 && showFileList) && (
        <div className={`${styles.fileList}`}>
          {files.map(file => (
            <Attachment file={file} key={file.name} onRemove={removeFile} />
          ))}
        </div>
      )}
    </>
  );
}
