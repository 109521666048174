import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import styles from './metal.strict-module.css';

export interface MetalProps {
  className?: string;
  type: 'light' | 'dark';
}

export function Metal({ className, type, ...props }: MetalProps): ReactNode {
  const isLight = type === 'light';

  return (
    <span
      className={cx('caption-bold', styles.badge, isLight ? styles.light : styles.dark, className)}
      {...props}
    >
      <FormattedMessage id="cards.badge.metal" />
    </span>
  );
}
