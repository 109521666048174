/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconChevronLeftOutlined, IconChevronRightOutlined } from '@repo/monochrome-icons';
import styles from './pagination.strict-module.css';

export interface PaginationMeta {
  currentPage: number;
  pageCount: number;
  perPage: number;
  totalCount: number;
  prevPage?: () => void;
  nextPage?: () => void;
}

export interface PaginationProps {
  responseMeta: PaginationMeta;
  pageSizes?: number[];
  updatePerPage: (pageSize: number) => void;
  updatePage: (page: number) => void;
}

function PerPageOption({
  perPage,
  updatePerPage,
  isActive,
}: {
  perPage: number;
  updatePerPage: (pageSize: number) => void;
  isActive: boolean;
}): ReactNode {
  return (
    <button
      className={cx(styles.option, isActive && styles.active)}
      data-testid={`per-page-option-${String(perPage)}`}
      onClick={() => {
        updatePerPage(perPage);
      }}
      type="button"
    >
      {perPage}
    </button>
  );
}

export function Pagination({
  updatePerPage,
  pageSizes = [25, 50, 100],
  responseMeta,
}: PaginationProps): ReactNode {
  const { formatMessage } = useIntl();

  const goToPreviousPage = (): void => {
    if (responseMeta.prevPage) {
      responseMeta.prevPage();
    }
  };

  const goToNextPage = (): void => {
    if (responseMeta.nextPage) {
      responseMeta.nextPage();
    }
  };

  return (
    <div className={styles.pagination} data-testid="pagination">
      <div className={styles['per-page-controls']} data-testid="per-page-controls">
        <div className={styles.options} data-testid="per-page-options">
          {pageSizes.map((perPage: number) => (
            <PerPageOption
              isActive={perPage === responseMeta.perPage}
              key={perPage}
              perPage={perPage}
              updatePerPage={updatePerPage}
            />
          ))}
        </div>
        <div className={cx(styles['per-page-description'], 'body-2')} data-testid="per-page-label">
          <FormattedMessage id="tables.footer.items-per-page.label" />
        </div>
      </div>
      <div
        className={cx(styles['current-page-description'], 'body-2')}
        data-testid="current-page-description"
      >
        {getCurrentPageDescription(responseMeta, formatMessage)}
      </div>
      <div className={styles.options} data-testid="navigation-controls">
        <button
          aria-label="Go to previous page"
          className={cx(styles.option, 'btn', 'btn--icon-only', 'btn--tertiary')}
          data-testid="previous-page"
          disabled={!responseMeta.prevPage}
          onClick={goToPreviousPage}
          type="button"
        >
          <IconChevronLeftOutlined />
        </button>
        <button
          aria-label="Go to next page"
          className={cx(styles.option, 'btn', 'btn--icon-only', 'btn--tertiary')}
          data-testid="next-page"
          disabled={!responseMeta.nextPage}
          onClick={goToNextPage}
          type="button"
        >
          <IconChevronRightOutlined />
        </button>
      </div>
    </div>
  );
}

function getCurrentPageDescription(
  meta: PaginationMeta,
  formatMessage: (descriptor: { id: string; defaultMessage?: string }) => string
): string {
  const pageCount = meta.pageCount;
  const currentPage = meta.currentPage + 1;
  const totalCount = meta.totalCount;
  const ofText = formatMessage({
    id: 'labels.pagination_summary',
    defaultMessage: 'of',
  });

  return `${String(currentPage)}-${String(pageCount)} ${ofText} ${String(totalCount)}`;
}
