declare global {
  namespace FormatjsIntl {
    interface IntlConfig {
      locale: LocaleCode;
    }
  }
}
export const LOCALES_DEFINITION = {
  EN: { name: 'English', code: 'en' },
  FR: { name: 'Français', code: 'fr' },
  IT: { name: 'Italiano', code: 'it' },
  DE: { name: 'Deutsch', code: 'de' },
  ES: { name: 'Español', code: 'es' },
  PT: { name: 'Português', code: 'pt' },
} as const;

export const LOCALES = Object.values(LOCALES_DEFINITION);

export const DEFAULT_LOCALE = LOCALES_DEFINITION.EN.code;

export const LOCALE_CODES = LOCALES.map(locale => locale.code);
export type LocaleCode = (typeof LOCALE_CODES)[number];
