import { Accordion, LottiePlayer, Popup } from '@repo/design-system-kit';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import otpConfirmationAnimation from '../../../lotties/otp-confirmation-code.json';
import type { SensitiveActionsType } from '../../../types/sensitive-actions';
import { faqService } from '../../../utils/faq-composer';
import styles from './sca.strict-module.css';

interface WaitingProps {
  onClose?: () => void;
  actionType: SensitiveActionsType;
}

function MessageBody({ actionType }: { actionType: WaitingProps['actionType'] }): React.ReactNode {
  switch (actionType) {
    case 'generic':
      return <FormattedMessage id="sca.sensitive-actions.generic.confirm.subtitle" />;
    default:
      return <FormattedMessage id={`sca.sensitive-actions.${actionType}.confirm`} />;
  }
}

export function Waiting({ onClose, actionType }: WaitingProps): React.ReactNode {
  // NOTE -- this is needed until we have a better way to handle the portal container in qunit tests
  // https://www.notion.so/qonto/React-popover-library-used-within-react-aria-components-does-mount-popovers-modals-outside-of-qunit--12631ee4c696806e87dbef81e9a27922
  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;

  const [isOpen, setIsOpen] = useState(true);
  const { formatMessage } = useIntl();
  const handleClose = (): void => {
    setIsOpen(false);
    onClose?.();
  };

  return (
    <Popup.Root
      aria-label={formatMessage({ id: 'sca.sensitive-actions.generic.confirm.title' })}
      isOpen={isOpen}
      portalContainer={TEST_PORTAL_CONTAINER}
      role="dialog"
    >
      <Popup.CloseButton onPress={handleClose} />
      <Popup.Header>
        <figure className={styles.illustrationWrap}>
          <LottiePlayer
            animationData={otpConfirmationAnimation}
            data-img="sms-animation"
            loop
            role="img"
          />
        </figure>
        <Popup.Title>
          <FormattedMessage id="sca.sensitive-actions.generic.confirm.title" />
        </Popup.Title>
        <Popup.Subtitle>
          <MessageBody actionType={actionType} />
        </Popup.Subtitle>
      </Popup.Header>
      <Popup.Body>
        <Accordion.Group>
          <Accordion.Single
            title={formatMessage({ id: 'sca.sensitive-actions.accordion.no-notification.title' })}
          >
            <p className="body-2">
              <FormattedMessage id="sca.sensitive-actions.accordion.no-notification.subtitle" />
            </p>
          </Accordion.Single>
          <Accordion.Single
            title={formatMessage({ id: 'sca.sensitive-actions.accordion.no-phone.title' })}
          >
            <p className="body-2">
              <FormattedMessage
                id="sca.sensitive-actions.accordion.no-phone.subtitle"
                values={{
                  // eslint-disable-next-line react/no-unstable-nested-components -- This is a valid case since enforce the usage of the <a> tag in react-intl
                  a: (chunks: React.ReactNode) => (
                    <a
                      className={styles.faqLink}
                      href={faqService.getLocalizedArticle(4674610)}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </p>
          </Accordion.Single>
        </Accordion.Group>
      </Popup.Body>
    </Popup.Root>
  );
}
