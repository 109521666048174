import cx from 'clsx';
import type { HTMLAttributes, ReactElement } from 'react';
import styles from '../popup.strict-module.css';

export function PopupBody({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>): ReactElement {
  return (
    <div className={cx(styles.body, className)} data-test-id="modal-body" {...props}>
      {children}
    </div>
  );
}
