/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { DateValue, HeadingProps, Key } from 'react-aria-components';
import {
  Select,
  SelectValue,
  Popover,
  ListBox,
  ListBoxItem,
  useContextProps,
  HeadingContext,
  CalendarStateContext,
} from 'react-aria-components';
import type { ReactNode } from 'react';
import { useContext } from 'react';
import { getLocalTimeZone, today, CalendarDate } from '@internationalized/date';
import cx from 'clsx';
import { Button } from '../../../button';
import { IconArrowBottom } from './icon/icon-arrow-bottom';
import styles from './heading.strict-module.css';

const NB_YEAR_OPTIONS_DEFAULT = 21;

interface CalendarHeadingSelectProps extends HeadingProps {
  maxYear?: DateValue['year'];
  minYear?: DateValue['year'];
}

export function CalendarHeadingSelect(
  headingProps: CalendarHeadingSelectProps,
  headingRef: React.ForwardedRef<HTMLHeadingElement>
): ReactNode {
  const state = useContext(CalendarStateContext);
  const [props, ref] = useContextProps(headingProps, headingRef, HeadingContext);

  const baseDate = state?.value ?? today(getLocalTimeZone());

  const nbYearOptions =
    props.minYear && props.maxYear ? props.maxYear - props.minYear + 1 : NB_YEAR_OPTIONS_DEFAULT;

  const yearOptions = Array.from(
    { length: nbYearOptions },
    (_, i) =>
      (props.minYear ?? baseDate.year) + (i - (props.minYear ? baseDate.year - props.minYear : 10))
  );

  const handleSelectionChange = (selectedYear: Key): void => {
    state?.setFocusedDate(new CalendarDate(Number(selectedYear), state.focusedDate.month, 1));
  };

  return (
    <Select aria-label="select" onSelectionChange={handleSelectionChange}>
      <Button className={styles.button} variant="tertiary">
        <div className={styles['button-children']}>
          <SelectValue className="body-1" {...props} ref={ref} />
          <IconArrowBottom aria-hidden="true" />
        </div>
      </Button>
      <Popover className={styles.popover} maxHeight={180}>
        <ListBox className={styles.list}>
          {yearOptions.map(yearOption => (
            <ListBoxItem
              className={cx('body-1', 'overlay', styles.item)}
              id={yearOption}
              key={yearOption}
            >
              {yearOption}
            </ListBoxItem>
          ))}
        </ListBox>
      </Popover>
    </Select>
  );
}
