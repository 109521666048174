import type { RunTimeEnvironment } from '@repo/shared-config/app/constants/hosts';
import { getUrlsTable } from '@repo/shared-config/app/constants/hosts';

interface MetricsAdaptersConfig {
  Segment: {
    config: {
      key: string;
      proxyDomain: string;
    };
  };
}

function getRuntimeEnvFromMetaTag(): RunTimeEnvironment & {
  metricsAdaptersConfig?: MetricsAdaptersConfig;
} {
  const metaTag = document.querySelector("meta[name^='qonto'][name$='/config/environment']");
  const content = metaTag?.getAttribute('content') || '{}';
  const parsedContent = JSON.parse(decodeURIComponent(content)) as RunTimeEnvironment;
  return parsedContent;
}

function getBackendUrls(): ReturnType<typeof getUrlsTable>['backendUrlsTable'] {
  const environment = getRuntimeEnvFromMetaTag();
  const urlsTable = getUrlsTable(environment);
  return urlsTable.backendUrlsTable;
}

export const authBaseURL = getBackendUrls().authBaseURL;

function getSegment(): MetricsAdaptersConfig['Segment'] | undefined {
  const environment = getRuntimeEnvFromMetaTag();
  return environment.metricsAdaptersConfig?.Segment;
}

export const segmentConfig = getSegment()?.config;
