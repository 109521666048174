import cx from 'clsx';
import type { HTMLAttributes, ReactElement } from 'react';
import styles from '../popup.strict-module.css';

export function PopupFooter({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>): ReactElement {
  return (
    <footer className={cx(styles.footer, className)} {...props}>
      {children}
    </footer>
  );
}
