import type { PropsWithChildren, ReactNode } from 'react';
import { useRef } from 'react';
import type { AriaToastProps } from '@react-aria/toast';
import { useToast as useReactAriaToast } from '@react-aria/toast';
import type { QueuedToast, ToastState } from '@react-stately/toast';
import cx from 'clsx';
import { ErrorIcon, InfoIcon, SuccessIcon } from '../icons';
import { Button } from '../../button';
import { CloseIcon } from '../../../assets/icons';
import styles from './toast-context.strict-module.css';

export interface ToastData {
  type?: 'info' | 'success' | 'error';
  text: string;
}

interface ToastComponentProps extends AriaToastProps<ToastData> {
  state: ToastState<ToastData>;
  toast: QueuedToast<ToastData>;
  text: string;
  type?: 'info' | 'success' | 'error';
  className?: string;
}

/**
 * Toast component that displays a message to the user. This is visually identical to toast.tsx in the parent folder,
 * but uses the React Aria's toast implementation under the hood. Apart from that, it also improves the accessibility
 * comparing to toast.tsx.
 */
export function ToastComponent({
  type = 'info',
  text,
  state,
  ...props
}: PropsWithChildren<ToastComponentProps>): ReactNode {
  const ref = useRef(null);
  const { toastProps, contentProps, titleProps, closeButtonProps } = useReactAriaToast(
    props,
    state,
    ref
  );

  function onClose(): void {
    props.toast.onClose?.();
    state.close(props.toast.key);
  }

  return (
    <div
      className={cx(styles.toast, type === 'error' && styles.error, props.className)}
      data-animation={props.toast.animation}
      data-test-toast=""
      data-test-type={type}
      onAnimationEnd={() => {
        // Remove the toast when the exiting animation completes.
        if (props.toast.key && props.toast.animation === 'exiting') {
          state.remove(props.toast.key);
        }
      }}
      ref={ref}
      {...toastProps}
    >
      <div {...contentProps} className={styles['toast-content']}>
        <div className={styles['icon-container']}>
          {type === 'info' && <InfoIcon className={styles.icon} />}
          {type === 'success' && <SuccessIcon className={styles.icon} />}
          {type === 'error' && <ErrorIcon className={styles.icon} />}
        </div>
        <p className={cx('body-1', styles.text)} {...titleProps}>
          {text}
        </p>
        <Button
          className={styles.close}
          iconOnly
          onPress={onClose}
          variant="tertiary"
          {...closeButtonProps}
        >
          <CloseIcon />
        </Button>
      </div>
    </div>
  );
}
