import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface RecycledProps {
  className?: string;
}

export function Recycled({ className, ...props }: RecycledProps): ReactNode {
  return (
    <span className={cx(styles.badge, className)} {...props}>
      <p className="caption-bold">
        <FormattedMessage id="cards.badge.plastic" />
      </p>
    </span>
  );
}
