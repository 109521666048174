/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import cx from 'clsx';
import type { ReactElement } from 'react';
import styles from '../popup.strict-module.css';
import { CloseIcon } from '../../../assets/icons';
import { Button } from '../../button';

interface PopupCloseButtonProps {
  onPress: () => void;
  className?: string;
}

export function PopupCloseButton({
  onPress,
  className,
  ...props
}: PopupCloseButtonProps): ReactElement {
  return (
    <Button
      aria-label="Close"
      className={cx(styles.close, className)}
      data-test-id="modal-close-button"
      iconOnly
      onPress={onPress}
      type="button"
      variant="tertiary"
      {...props}
    >
      <CloseIcon aria-hidden />
    </Button>
  );
}
