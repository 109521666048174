const ANIMATION_IN_DURATION_MS = 260;

export function translate(optionElement: HTMLElement, startPosition?: number): Promise<void> {
  return new Promise(resolve => {
    const animation = optionElement.animate(
      [
        { zIndex: 2, transform: `translateX(${startPosition}px)` },
        { zIndex: 2, transform: 'translateX(0)' },
      ],
      {
        duration: ANIMATION_IN_DURATION_MS,
        easing: 'ease-in-out',
      }
    );
    animation.onfinish = () => resolve;
    animation.oncancel = () => resolve;
  });
}

export function fadeOut(optionElement: HTMLElement, startPosition?: number): Promise<void> {
  return new Promise(resolve => {
    const animation = optionElement.animate(
      [
        {
          display: 'block',
          position: 'absolute',
          transform: `translateX(${startPosition}px)`,
          zIndex: 1,
          opacity: 1,
        },
        {
          display: 'block',
          position: 'absolute',
          transform: `translateX(${startPosition}px)`,
          zIndex: 1,
          opacity: 0,
        },
      ],
      {
        duration: ANIMATION_IN_DURATION_MS,
        easing: 'ease-in-out',
      }
    );
    animation.onfinish = () => resolve;
    animation.oncancel = () => resolve;
  });
}

export function fadeIn(collapsedContent: HTMLElement): Promise<void> {
  return new Promise(resolve => {
    const animation = collapsedContent.animate(
      [
        { position: 'relative', zIndex: 1, opacity: 0 },
        { position: 'relative', zIndex: 1, opacity: 1 },
      ],
      {
        duration: ANIMATION_IN_DURATION_MS,
        easing: 'ease-in-out',
      }
    );
    animation.onfinish = () => resolve;
    animation.oncancel = () => resolve;
  });
}
