export function DeviceConfirmedSvg(props: React.SVGProps<SVGSVGElement>): React.ReactNode {
  return (
    <svg
      fill="none"
      height="188"
      viewBox="0 0 200 188"
      width="200"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M169.748 63.036 82.778 12.82c-.482-.536-.954-1.142-1.427-1.749-.992-1.273-1.984-2.546-3.054-3.164L68.19 2.072c-1.2-.692-2.195-.262-2.39.946l-.036-.02.003 89.028 116.997 67.553-.003-89.028-.002-.001c0-1.547-1.086-3.429-2.426-4.202l-5.745-3.318c-.912-.526-1.675-.266-2.438-.007-.754.256-1.507.513-2.403.013Z"
        fill="#EFE9FE"
        fillRule="evenodd"
      />
      <path
        d="m82.777 12.82-.078.149.038.041.04.024v-.214Zm86.971 50.216v.214l.003.002-.003-.216ZM81.351 11.071l-.098.125.002.002.096-.127Zm0 0 .098-.124-.001-.002-.097.126Zm-3.054-3.164v-.214.214ZM68.19 2.072v.214-.214Zm-2.39.946v.213l.155.09.026-.162-.182-.141Zm-.036-.02v-.214l-.185-.107v.214l.185.107Zm.003 89.028-.185-.107v.213l.185.107v-.213Zm116.997 67.553v.213l.185.107v-.213l-.185-.107Zm-.003-89.028.185.106v-.213l-.185-.107v.214Zm-.002-.001-.185-.107v.213l.185.107v-.213Zm-2.426-4.202v.213-.213Zm-5.745-3.318v-.213.213Zm-2.438-.007-.115-.234.115.234Zm-89.374-49.99 86.971 50.217v-.427l-86.97-50.217v.428Zm-1.522-1.835c.47.604.952 1.223 1.445 1.771l.155-.298c-.47-.523-.933-1.117-1.407-1.726l-.193.253Zm-.002-.002.196-.25-.196.25ZM78.297 8.12c1.008.582 1.954 1.789 2.958 3.077l.193-.253c-.98-1.258-2.019-2.598-3.151-3.252v.428ZM68.19 2.286l10.106 5.835v-.428L68.19 1.86v.427Zm-2.209.873c.181-1.116 1.102-1.513 2.21-.873v-.427c-1.292-.746-2.364-.283-2.574 1.017l.365.283Zm-.217.052.035.02v-.427l-.035-.02v.427Zm.188 88.921L65.95 3.104l-.37-.213.003 89.028.37.213Zm116.812 67.233L65.768 91.812v.427l116.997 67.553v-.427Zm-.188-88.921.003 89.028.37.214-.003-89.029-.37-.213Zm.183.32h.002v-.427l-.002-.001v.427Zm.185-.108c0-1.665-1.169-3.69-2.611-4.522v.427c1.238.715 2.241 2.453 2.241 3.882l.37.213Zm-2.611-4.522-5.745-3.317v.427l5.745 3.317v-.427Zm-5.745-3.317c-.988-.57-1.804-.282-2.553-.028l.23.468c.777-.264 1.488-.495 2.323-.013v-.427Zm-2.553-.028c-.767.261-1.47.49-2.291.032l.006.43c.971.542 1.775.258 2.515.006l-.23-.468Z"
        fill="#1D1D1B"
      />
      <path
        d="m70.443 21.992 108.994 62.933.002 68.892L70.445 90.884l-.002-68.892Z"
        fill="#EFE9FE"
        stroke="#1D1D1B"
        strokeWidth=".214"
      />
      <rect
        fill="#EFE9FE"
        height="5.701"
        rx="1.603"
        stroke="#1D1D1B"
        strokeWidth=".214"
        transform="matrix(.86601 .50003 .00003 1 84.38 20.54)"
        width="109.671"
        x=".093"
        y=".16"
      />
      <path
        d="M78.073 18.353c0 2.434-1.708 3.42-3.816 2.204-2.108-1.217-3.817-4.177-3.817-6.61 0-2.434 1.709-3.42 3.817-2.204 2.107 1.217 3.816 4.177 3.816 6.61Z"
        fill="#EFE9FE"
        stroke="#1D1D1B"
        strokeWidth=".214"
      />
      <path d="m176.831 67.748 2.696 4.67m0-3.113-2.696 1.556" stroke="#1D1D1B" strokeWidth=".214" />
      <path
        clipRule="evenodd"
        d="m145.823 76.83-86.97-50.216c-.482-.536-.954-1.143-1.427-1.75-.992-1.272-1.983-2.546-3.054-3.164l-10.105-5.834c-1.2-.693-2.196-.263-2.392.945l-.035-.02.003 89.028 116.997 67.553-.003-89.028-.001-.001c-.001-1.547-1.087-3.428-2.427-4.202l-5.745-3.317c-.911-.527-1.675-.267-2.438-.007-.754.256-1.507.512-2.403.013Z"
        fill="#EFE9FE"
        fillRule="evenodd"
      />
      <path
        d="m58.853 26.614-.078.148.037.042.04.023v-.213Zm86.97 50.216v.213l.003.002-.003-.215ZM57.426 24.865l-.105.115.009.011.096-.126Zm0 0 .105-.116-.008-.01-.097.126Zm-15.55-8.054v.214l.155.09.027-.163-.183-.141Zm-.036-.02v-.214l-.185-.107v.214l.185.107Zm.003 89.028-.185-.107v.214l.185.107v-.214Zm116.997 67.553v.214l.185.107v-.214l-.185-.107Zm-.003-89.028.185.107v-.214l-.185-.107v.214Zm-.001-.001-.185-.107v.214l.185.107v-.214Zm-2.427-4.202v-.214.214Zm-5.745-3.317v.213-.213Zm-2.438-.007-.115-.234.115.234Zm-89.373-49.99 86.97 50.216v-.427L58.853 26.4v.427Zm-1.523-1.836c.47.605.952 1.223 1.445 1.771l.156-.297c-.47-.523-.934-1.118-1.408-1.727l-.193.253Zm-.008-.01.21-.232-.21.231Zm-2.95-3.067c1.008.582 1.954 1.789 2.958 3.077l.193-.253c-.98-1.258-2.019-2.598-3.151-3.251v.427Zm-10.105-5.835 10.105 5.835v-.427l-10.105-5.835v.427Zm-2.21.873c.181-1.115 1.102-1.512 2.21-.873v-.427c-1.292-.746-2.364-.282-2.574 1.018l.365.282Zm-.217.052.035.02v-.427l-.035-.02v.427Zm.188 88.922-.002-89.028-.37-.214.002 89.028.37.214Zm116.812 67.232L41.843 105.605v.428l116.997 67.553v-.428Zm-.188-88.92.003 89.027.37.214-.003-89.028-.37-.214Zm.184.319h.001v-.427h-.001v.427Zm.185-.107c-.001-1.665-1.17-3.69-2.612-4.523v.428c1.238.714 2.241 2.452 2.242 3.881l.37.214Zm-2.612-4.523-5.745-3.317v.427l5.745 3.318v-.428Zm-5.745-3.317c-.988-.57-1.804-.282-2.553-.027l.23.467c.777-.264 1.488-.495 2.323-.013v-.427Zm-2.553-.027c-.767.26-1.47.49-2.291.031l.006.431c.971.541 1.775.257 2.515.005l-.23-.467Z"
        fill="#1D1D1B"
      />
      <path
        d="m46.518 35.785 108.994 62.933.002 68.892L46.52 104.677l-.002-68.892Z"
        fill="#EFE9FE"
        stroke="#1D1D1B"
        strokeWidth=".214"
      />
      <rect
        fill="#EFE9FE"
        height="5.701"
        rx="1.603"
        stroke="#1D1D1B"
        strokeWidth=".214"
        transform="matrix(.86601 .50003 .00003 1 60.456 34.332)"
        width="109.671"
        x=".093"
        y=".16"
      />
      <path
        d="M54.15 32.146c0 2.434-1.709 3.42-3.816 2.204-2.108-1.217-3.817-4.177-3.817-6.61 0-2.434 1.708-3.42 3.816-2.204 2.108 1.217 3.817 4.177 3.817 6.61Z"
        fill="#EFE9FE"
        stroke="#1D1D1B"
        strokeWidth=".214"
      />
      <path d="m152.908 81.541 2.696 4.67m0-3.113-2.696 1.556" stroke="#1D1D1B" strokeWidth=".214" />
      <path
        clipRule="evenodd"
        d="m121.076 91.099-86.97-50.216c-.482-.536-.954-1.143-1.427-1.749-.992-1.273-1.984-2.546-3.054-3.164L19.52 30.135c-1.2-.693-2.196-.263-2.392.945l-.035-.02.003 89.028 116.997 67.553-.003-89.028h-.001c-.001-1.548-1.087-3.43-2.427-4.203l-5.745-3.317c-.911-.526-1.675-.267-2.438-.007-.754.256-1.507.512-2.403.013Z"
        fill="#EFE9FE"
        fillRule="evenodd"
      />
      <path
        d="m34.105 40.883-.077.149.037.04.04.024v-.213Zm86.971 50.216v.214l.003.001-.003-.215ZM32.679 39.134l-.102.119.006.008.096-.127Zm0 0 .103-.119-.006-.008-.097.127Zm-3.054-3.164v-.214.214ZM19.52 30.135v.214-.214Zm-2.392.945v.214l.156.09.027-.162-.183-.142Zm-.035-.02v-.213l-.185-.107v.213l.185.107Zm.003 89.028-.185-.107v.214l.185.107v-.214Zm116.997 67.553v.214l.185.107v-.214l-.185-.107Zm-.003-89.028.185.107v-.214l-.185-.106v.213Zm-.001 0-.185-.108v.214l.185.107v-.214Zm-2.427-4.203v.214-.214Zm-5.745-3.317v-.214.214Zm-2.438-.007-.115-.234.115.234Zm-89.374-49.99 86.971 50.217v-.428L34.106 40.67v.428Zm-1.522-1.835c.47.604.952 1.222 1.445 1.77l.155-.297c-.47-.523-.933-1.118-1.407-1.727l-.193.254Zm-.006-.009.205-.236-.205.236Zm-2.952-3.069c1.009.583 1.954 1.79 2.958 3.078l.193-.254c-.98-1.258-2.019-2.597-3.151-3.251v.427ZM19.52 30.35l10.105 5.834v-.427L19.52 29.921v.428Zm-2.21.873c.181-1.116 1.102-1.513 2.21-.873v-.428c-1.292-.745-2.364-.282-2.574 1.018l.365.283Zm-.217.052.035.02v-.427l-.035-.02v.427Zm.188 88.921-.003-89.028-.37-.214.003 89.028.37.214Zm116.812 67.233L17.096 119.875v.427l116.997 67.553v-.427Zm-.188-88.922.003 89.029.37.213-.003-89.028-.37-.214Zm.184.32h.001V98.4l-.001-.001v.427Zm.185-.107c-.001-1.665-1.17-3.69-2.612-4.522v.427c1.238.715 2.241 2.452 2.242 3.882l.37.213Zm-2.612-4.522-5.745-3.318v.428l5.745 3.317v-.427Zm-5.745-3.318c-.988-.57-1.804-.282-2.553-.027l.23.468c.777-.265 1.488-.495 2.323-.013v-.428Zm-2.553-.027c-.767.26-1.47.49-2.291.031l.006.431c.971.541 1.775.257 2.515.006l-.23-.468Z"
        fill="#1D1D1B"
      />
      <path
        d="m21.773 50.052 108.994 62.932.002 68.892-108.994-62.932-.002-68.892Z"
        fill="#fff"
        stroke="#1D1D1B"
        strokeWidth=".214"
      />
      <rect
        fill="#D5C8FB"
        height="5.701"
        rx="1.603"
        stroke="#1D1D1B"
        strokeWidth=".214"
        transform="matrix(.86601 .50003 .00003 1 35.71 48.6)"
        width="109.671"
        x=".093"
        y=".16"
      />
      <path
        d="M29.404 46.413c0 2.434-1.708 3.42-3.816 2.203-2.108-1.217-3.817-4.176-3.817-6.61s1.709-3.42 3.817-2.203c2.107 1.217 3.816 4.176 3.816 6.61Z"
        fill="#D5C8FB"
        stroke="#1D1D1B"
        strokeWidth=".214"
      />
      <path d="m128.162 95.81 2.696 4.67m0-3.114-2.696 1.557" stroke="#1D1D1B" strokeWidth=".214" />
      <path
        d="m34.615 96.564 6.242-3.852s12.422 2.152 21.536-2.772l-3.082 8.665-24.696.95v-2.991Z"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeLinejoin="round"
        strokeWidth=".427"
      />
      <path
        d="m62.336 89.974 6.78-3.88s10.525 26.134 27.991 38.248l-6.7 4.25-8.419-5.148-15.158-18.333-4.494-15.137Z"
        fill="#D5C8FB"
        stroke="#1D1D1B"
        strokeLinejoin="round"
        strokeWidth=".427"
      />
      <path
        d="M62.492 171.136s36.098 2.15 34.615-46.794l-6.87 4.194-27.745 42.6Z"
        fill="#D5C8FB"
        stroke="#1D1D1B"
        strokeLinejoin="round"
        strokeWidth=".427"
      />
      <path
        d="M34.596 96.816c8.834 1.575 15.78-.07 20.532-2.123a30.076 30.076 0 0 0 5.47-3.054 21.6 21.6 0 0 0 1.672-1.312l.03.064c.104.211.257.522.457.922.399.798.986 1.95 1.737 3.362a177.492 177.492 0 0 0 6.28 10.871c5.216 8.312 12.291 17.876 19.716 22.831l.001.015.029.34c.023.298.053.737.08 1.296.053 1.119.093 2.721.039 4.651-.108 3.862-.59 9.03-2.088 14.266-1.497 5.236-4.005 10.53-8.158 14.655-4.133 4.106-9.907 7.066-17.968 7.651-16.05-13.702-23.007-32.345-25.948-47.587a114.889 114.889 0 0 1-2.036-19.268 94.494 94.494 0 0 1 .05-5.733 59.576 59.576 0 0 1 .105-1.847Z"
        fill="#EFE9FE"
        stroke="#1D1D1B"
        strokeLinejoin="round"
        strokeWidth=".427"
      />
      <path
        d="M56.926 98.888a36.08 36.08 0 0 0 3.643-1.808 181.761 181.761 0 0 0 6.313 10.907c4.838 7.711 11.608 17.091 19.153 22.848.035.972.052 2.238.011 3.716-.101 3.623-.554 8.382-1.912 13.131-1.362 4.761-3.56 9.261-6.979 12.658-3.019 2.999-7.2 5.354-13.199 6.14-13.942-12.647-20.234-29.502-22.98-43.715a110.552 110.552 0 0 1-1.96-18.508 99.284 99.284 0 0 1-.02-2.347c7.424.445 13.478-1.112 17.93-3.022Z"
        fill="#fff"
        stroke="#1D1D1B"
        strokeLinejoin="round"
        strokeWidth=".214"
      />
      <path
        clipRule="evenodd"
        d="M73.816 125.932c.943.749.987 2.038.098 2.879L59.909 141.88c-.523.496-1.175.842-1.931.767-.756-.075-1.15-.735-1.498-1.317l-5.294-8.881c-.59-.988-.13-2.227 1.024-2.767 1.155-.54 2.569-.177 3.158.811l3.78 6.342 11.35-10.736c.888-.841 2.374-.916 3.318-.167Z"
        fill="#1D1D1B"
        fillRule="evenodd"
      />
    </svg>
  );
}
