import { getLocalTimeZone } from '@internationalized/date';
import { useCallback, useEffect, useState } from 'react';
import { useDateFormatter } from 'react-aria';
import type { DatePickerProps, DateValue, ValidationResult } from 'react-aria-components';
import { useIntl } from 'react-intl';

interface UseDatePickerValidationProps<T extends DateValue> {
  value: DateValue | null | undefined;
  onChange?: (newValue: T | null, isInvalid: boolean) => void;
  isRequired?: boolean;
  minValue?: DatePickerProps<T>['minValue'];
  maxValue?: DatePickerProps<T>['maxValue'];
  customValidationMessages?: {
    [key in keyof ValidationResult['validationDetails']]?: string;
  };
  errorMessage?: string;
}

/**
 * This hook is used to validate the date picker value.
 * It returns the error and invalid state.
 */
export function useDatePickerValidation<T extends DateValue>({
  value,
  onChange,
  isRequired,
  minValue,
  maxValue,
  customValidationMessages,
  errorMessage: errorMessageProp,
}: UseDatePickerValidationProps<T>): {
  error: string | undefined;
  isInvalid: boolean;
  handleChange: (newValue: T | null | undefined) => void;
} {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isInvalid, setIsInvalid] = useState(false);
  const intl = useIntl();
  const timeZone = getLocalTimeZone();

  const formatter = useDateFormatter({
    timeZone,
  });

  const getErrorMessage = useCallback(
    (validationValue: DatePickerProps<T>['value'] | null | undefined) => {
      if (errorMessageProp) {
        return errorMessageProp;
      }

      if (isRequired && !validationValue) {
        return (
          customValidationMessages?.valueMissing ||
          intl.formatMessage({ id: 'datepicker-error-required' })
        );
      }
      if (minValue && validationValue && validationValue.compare(minValue) < 0) {
        return (
          customValidationMessages?.rangeUnderflow ||
          intl.formatMessage(
            { id: 'datepicker-error-minimum-date' },
            { date: formatter.format(minValue.subtract({ days: 1 }).toDate(timeZone)) }
          )
        );
      }
      if (maxValue && validationValue && validationValue.compare(maxValue) > 0) {
        return (
          customValidationMessages?.rangeOverflow ||
          intl.formatMessage(
            { id: 'datepicker-error-maximum-date' },
            { date: formatter.format(maxValue.add({ days: 1 }).toDate(timeZone)) }
          )
        );
      }
      return undefined;
    },
    [
      isRequired,
      minValue,
      maxValue,
      customValidationMessages,
      timeZone,
      formatter,
      intl,
      errorMessageProp,
    ]
  );

  const updateErrorAndInvalidState = useCallback(
    (validationValue: T | null | undefined) => {
      const errorMessage = getErrorMessage(validationValue);
      setError(errorMessage);
      setIsInvalid(Boolean(errorMessage));
    },
    [getErrorMessage]
  );

  const handleChange = (newValue: T | null | undefined): void => {
    const errorMessage = getErrorMessage(newValue);
    updateErrorAndInvalidState(newValue);
    onChange?.(newValue as unknown as T, Boolean(errorMessage));
  };

  // If the value got updated even from outside, we need to validate it
  // e.g value is updated by clicking on a button outside the date picker
  useEffect(() => {
    updateErrorAndInvalidState(value as T);
  }, [value, updateErrorAndInvalidState]);

  return {
    error,
    isInvalid,
    handleChange,
  };
}
