import * as React from 'react';
import { Badge, BadgeHighlight, BadgeStatus, SkeletonLoader } from '@repo/design-system-kit';

export function Loader(): React.JSX.Element {
  return <SkeletonLoader.Block height="24px" width="80px" />;
}

interface ProductBadgeProps {
  traits: { name: 'active' | 'popular' | 'free_trial' | 'recommended'; title: string }[];
  className?: string;
}

export function ProductBadge({ className, traits }: ProductBadgeProps): React.JSX.Element | null {
  const active = traits.find(trait => trait.name === 'active');
  if (active !== undefined) {
    return <BadgeStatus className={className} level="validated" text={active.title} />;
  }
  const recommended = traits.find(trait => trait.name === 'recommended');
  if (recommended !== undefined) {
    return <BadgeHighlight text={recommended.title} />;
  }
  const popular = traits.find(trait => trait.name === 'popular');
  if (popular !== undefined) {
    return <BadgeHighlight text={popular.title} />;
  }
  const freeTrial = traits.find(trait => trait.name === 'free_trial');
  if (freeTrial !== undefined) {
    return <Badge className={className} text={freeTrial.title} type="tryForFree" />;
  }
  return null;
}
