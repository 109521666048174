import type { HTMLAttributes, ReactElement } from 'react';
import cx from 'clsx';
import styles from '../popup.strict-module.css';

interface PopupIllustrationProps extends HTMLAttributes<HTMLDivElement> {
  isPromotional?: boolean;
}

export function PopupIllustration({
  isPromotional = false,
  children,
  ...props
}: PopupIllustrationProps): ReactElement {
  return (
    <figure
      className={cx(isPromotional ? styles.promotionalIllustration : styles.illustration)}
      data-promotional-illustration=""
      {...props}
    >
      {children}
    </figure>
  );
}
