import camelcaseKeys from 'camelcase-keys';

export const INVALID_MFA = 'invalid_mfa';
export const INVALID_SCA = 'invalid_sca';
export const MFA_REQUIRED = 'mfa_required';
export const REQUIRED_MFA = 'required_mfa';
export const DEVICE_EMAIL_VERIFICATION_REQUIRED = 'device_email_verification_required';
export const NO_TRUSTED_DEVICES = 'no_trusted_devices';
export const SCA_REQUIRED = 'sca_required';
export const REQUIRED_SCA = 'required_sca';
export const SCA_ENFORCEMENT = 'paired_device_required';

export const MFA_ERRORS = [MFA_REQUIRED, INVALID_MFA, REQUIRED_MFA] as const;
export const SCA_ERRORS = [SCA_REQUIRED, INVALID_SCA, NO_TRUSTED_DEVICES, REQUIRED_SCA] as const;
export const SCA_ENFORCEMENT_ERRORS = [SCA_ENFORCEMENT] as const;
export const EMAIL_VERIFICATION_ERRORS = [DEVICE_EMAIL_VERIFICATION_REQUIRED] as const;

export type ErrorCode =
  | (typeof MFA_ERRORS)[number]
  | (typeof SCA_ERRORS)[number]
  | (typeof SCA_ENFORCEMENT_ERRORS)[number]
  | (typeof EMAIL_VERIFICATION_ERRORS)[number];

const ALL_ERRORS = [
  ...MFA_ERRORS,
  ...SCA_ERRORS,
  ...SCA_ENFORCEMENT_ERRORS,
  ...EMAIL_VERIFICATION_ERRORS,
] as readonly string[];

export interface ErrorObject {
  code: ErrorCode;
  message?: string;
  detail?: string;
  meta?: Record<string, unknown>;
  [key: string]: unknown;
}

export type ScaError =
  | { errors?: ErrorObject[] | ErrorObject; responseJSON?: { errors?: ErrorObject[] } }
  | ErrorObject[]
  | ErrorObject
  | undefined;

const isObject = (value: unknown): value is Record<string, unknown> =>
  typeof value === 'object' && value !== null;

const isErrorObject = (value: unknown): value is ErrorObject =>
  isObject(value) &&
  'code' in value &&
  typeof value.code === 'string' &&
  ALL_ERRORS.includes(value.code);

const hasProperty = <T extends string>(obj: unknown, property: T): obj is Record<T, unknown> =>
  isObject(obj) && property in obj;

/*
 * Extracts errors from different error formats
 * @example
 * extractErrors([\{ code: 'error_code' \}]) // [\{ code: 'error_code' \}]
 * extractErrors(\{ errors: [\{ code: 'error_code' \}] \}) // [\{ code: 'error_code' \}]
 */
const extractErrors = (error: ScaError): ErrorObject[] => {
  if (!error) return [];

  if (isErrorObject(error)) {
    return [error];
  }

  if (Array.isArray(error)) {
    return error.filter(isErrorObject);
  }

  if (hasProperty(error, 'errors')) {
    const { errors } = error;

    if (Array.isArray(errors)) {
      return errors.filter(isErrorObject);
    }

    if (isErrorObject(errors)) {
      return [errors];
    }
  }

  if (hasProperty(error, 'responseJSON')) {
    const { responseJSON } = error;

    if (hasProperty(responseJSON, 'errors')) {
      const { errors } = responseJSON;

      if (Array.isArray(errors)) {
        return errors.filter(isErrorObject);
      }

      if (isErrorObject(errors)) {
        return [errors];
      }
    }

    if (hasProperty(responseJSON, 'code') && typeof responseJSON.code === 'string') {
      return [responseJSON as ErrorObject];
    }
  }

  return [];
};

const findMatchingError = (
  errors: ErrorObject[],
  errorCodes: readonly string[]
): ErrorObject | null => errors.find(error => errorCodes.includes(error.code)) || null;

export const SCA_ERROR_TYPE = {
  MFA: 'mfa',
  SCA: 'sca',
  SCA_ENFORCEMENT: 'sca-enforcement',
  EMAIL_VERIFICATION: 'email-verification',
} as const;

export type ScaErrorType = (typeof SCA_ERROR_TYPE)[keyof typeof SCA_ERROR_TYPE];

/**
 * Checks if an error object contains any MFA or SCA error
 * @example
 * hasMFAError(\{ code: 'mfa_required' \}) // true
 * hasMFAError(\{ errors: [\{ code: 'invalid_mfa' \}] \}) // true
 * hasMFAError(\{ code: 'unrelated_error' \}) // false
 */
export function hasMFAError(error: ScaError): error is ErrorObject {
  if (!error) return false;

  const errors = extractErrors(error);
  return errors.some(err => ALL_ERRORS.includes(err.code));
}

/**
 * Gets the first MFA error from an error object
 * @example
 * getMFAError(\{ errors: [\{ code: 'mfa_required' \}] \}) // \{ code: 'mfa_required' \}
 * getMFAError(\{ errors: [\{ code: 'unrelated_error' \}] \}) // null
 */
export function getMFAError(error: ScaError): ErrorObject | null {
  const errors = extractErrors(error);
  return findMatchingError(errors, MFA_ERRORS);
}

/**
 * Gets the first SCA error from an error object
 * @example
 * getSCAError(\{ errors: [\{ code: 'sca_required' \}] \}) // \{ code: 'sca_required' \}
 * getSCAError(\{ errors: [\{ code: 'unrelated_error' \}] \}) // null
 */
export function getSCAError(error: ScaError): ErrorObject | null {
  const errors = extractErrors(error);
  return findMatchingError(errors, SCA_ERRORS);
}

/**
 * Gets the first SCA enforcement error from an error object and converts keys to camelCase
 * @example
 getSCAEnforcementError(\{
 *   errors: [\{
 *     code: 'paired_device_required',
 *     deep_link_url: 'https://example.com'
 *   \}]
 * \}) // \{ code: 'paired_device_required', deepLinkUrl: 'https://example.com' \}
 */
export function getSCAEnforcementError(error: ScaError): ErrorObject | null {
  const errors = extractErrors(error);
  const result = findMatchingError(errors, SCA_ENFORCEMENT_ERRORS);
  return result ? camelcaseKeys(result, { deep: true }) : null;
}

/**
 * Gets the first email verification error from an error object
 * @example
 * getEmailVerificationError(\{ errors: [\{ code: 'device_email_verification_required' \}] \})
 * // \{ code: 'device_email_verification_required' \}
 * getEmailVerificationError(\{ errors: [\{ code: 'unrelated_error' \}] \}) // null
 */
export function getEmailVerificationError(error: ScaError): ErrorObject | null {
  const errors = camelcaseKeys(extractErrors(error));
  return findMatchingError(errors, EMAIL_VERIFICATION_ERRORS);
}

/**
 * Determines the type of error (MFA, SCA, SCA Enforcement, or Email Verification) and returns the error with type information
 * @example
 * getErrorWithType(\{ errors: [\{ code: 'mfa_required' \}] \})
 * // \{ type: 'mfa', error: \{ code: 'mfa_required' \} \}
 *
 * getErrorWithType(\{ errors: [\{ code: 'sca_required' \}] \})
 * // \{ type: 'sca', error: \{ code: 'sca_required' \} \}
 *
 * getErrorWithType(\{ errors: [\{ code: 'paired_device_required' \}] \})
 * // \{ type: 'sca-enforcement', error: \{ code: 'paired_device_required', ... \} \}
 *
 * getErrorWithType(\{ errors: [\{ code: 'device_email_verification_required' \}] \})
 * // \{ type: 'email-verification', error: \{ code: 'device_email_verification_required' \} \}
 */
export function getErrorWithType(error: ScaError): {
  type: ScaErrorType | '';
  error: ErrorObject | null;
} {
  const mfaError = getMFAError(error);
  if (mfaError) {
    return { type: SCA_ERROR_TYPE.MFA, error: mfaError };
  }

  const scaError = getSCAError(error);
  if (scaError) {
    return { type: SCA_ERROR_TYPE.SCA, error: scaError };
  }

  const scaEnforcementError = getSCAEnforcementError(error);
  if (scaEnforcementError) {
    return { type: SCA_ERROR_TYPE.SCA_ENFORCEMENT, error: scaEnforcementError };
  }

  const emailVerificationError = getEmailVerificationError(error);
  if (emailVerificationError) {
    return { type: SCA_ERROR_TYPE.EMAIL_VERIFICATION, error: emailVerificationError };
  }

  throw error as Error;
}

export function isScaError(error: unknown): error is ScaError {
  if (!error) return false;
  if (isObject(error) && 'status' in error && error.status !== 428) return false;

  const { type } = getErrorWithType(error as ScaError);
  return (
    type === SCA_ERROR_TYPE.MFA ||
    type === SCA_ERROR_TYPE.SCA ||
    type === SCA_ERROR_TYPE.SCA_ENFORCEMENT ||
    type === SCA_ERROR_TYPE.EMAIL_VERIFICATION
  );
}
