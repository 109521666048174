import { BadgeStatus, type StatusType } from '@repo/design-system-kit';
import type { ReactNode } from 'react';
import styles from './forecast-status-badge.strict-module.css';

interface ForecastStatusBadgeProps {
  actualPercentage?: string;
  isCurrentMonth?: boolean;
  flowType: 'inflows' | 'outflows';
}

export function ForecastStatusBadge({
  actualPercentage,
  isCurrentMonth = false,
  flowType,
}: ForecastStatusBadgeProps): ReactNode {
  if (!actualPercentage) {
    return null;
  }

  const level = getAlertLevel({ actualPercentage, isCurrentMonth, flowType });
  const percentage = Math.floor(Number(actualPercentage));
  const badgeText = `${percentage > 999 ? '999+' : percentage}%`;

  return (
    <BadgeStatus
      className={styles.forecastStatusBadge}
      data-testid="forecast-status-badge"
      level={level}
      text={badgeText}
    />
  );
}

const getAlertLevel = ({
  actualPercentage,
  isCurrentMonth,
  flowType,
}: ForecastStatusBadgeProps): StatusType => {
  if (isCurrentMonth && Number(actualPercentage) >= 100) {
    return flowType === 'inflows' ? 'validated' : 'warning';
  }
  return 'inactive';
};
