import type { ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './skeleton.strict-module.css';

export function SuggestedTransactionCardSkeleton(): ReactNode {
  return (
    <div className={styles.card} data-testid="suggested-transaction-card-skeleton">
      <SkeletonLoader.Line className={styles.title} width="104px" />
      <SkeletonLoader.Line className={styles.amount} width="104px" />
      <SkeletonLoader.Line className={styles.subtitle} width="80px" />
      <div className={styles.actions}>
        <SkeletonLoader.Block height="40px" width="128px" />
        <SkeletonLoader.Block height="40px" width="40px" />
      </div>
    </div>
  );
}
