import { AnalyticsBrowser } from '@segment/analytics-next';
import { segmentConfig } from './environment';

export interface Segment {
  identify: ((traits: Record<string, unknown>) => void) &
    ((userId: string, traits: Record<string, unknown>) => void);

  track: (event: string, params?: Record<string, unknown>) => void;
}

export interface CallIdentify {
  type: 'identify';
  userId?: string;
  traits: Record<string, unknown>;
}

export interface CallTrack {
  type: 'track';
  event: string;
  params?: Record<string, unknown>;
}

export type Call = CallIdentify | CallTrack;

class SegmentMock implements Segment {
  #record<T extends Call>(arg: T): void {
    if (window.__segment === undefined) {
      // eslint-disable-next-line no-console -- for development
      console.log('#segment', arg);
    } else {
      window.__segment.push(arg);
    }
  }

  identify(arg1: string | Record<string, unknown>, arg2?: Record<string, unknown>): void {
    if (arg2 === undefined) {
      this.#record({
        type: 'identify',
        traits: arg1 as Record<string, unknown>,
      });
    } else {
      this.#record({ type: 'identify', userId: arg1 as string, traits: arg2 });
    }
  }

  track(event: string, params?: Record<string, unknown>): void {
    this.#record({ type: 'track', event, params });
  }
}

declare global {
  interface Window {
    __segment?: Call[];
  }
}

export const analytics: Segment = !segmentConfig?.key
  ? new SegmentMock()
  : (AnalyticsBrowser.load({ writeKey: segmentConfig.key }) as Segment);
