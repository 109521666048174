import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { UNIT_CODES } from '../../../../constants';
import type { QuantityProps } from '../types';

export function Quantity({ quantity, unit }: QuantityProps): ReactNode {
  const { formatMessage, locale } = useIntl();

  const getQuantity = (): string => {
    if (unit && UNIT_CODES.includes(unit)) {
      return `${quantity} ${formatMessage({ id: `pdf-preview.unit.${unit}` }, { number: quantity, language: locale })}`;
    }
    return unit ? `${quantity} ${unit}` : String(quantity);
  };

  return <span>{getQuantity()}</span>;
}
