export const HPKE_CARDS_BE_PUBLIC_KEYS_TABLE: Record<
  'production' | 'staging',
  Record<string, string>
> = {
  staging: {
    test_01:
      'BHrHm5Cw4WTRWreMLvPH31ZcigLZR/nIgvn4Z/e+jR2ZMlQFhnYbLTC8LzyrwTAlNfkmc0FRhoqnEx/MMtgjzu4+Le2WS3uwftSOE8w6+54zsSABPjKSfwmSIU1cPW1wWQ==',
    test_02:
      'BBEtt+ojMotO5jjZzXgZ07FYQ7NPzRgRJShYQedXgsQow35ZzirKa0KkLAGHjwkUtK9hdpGcjkVMRvPKNBUwuRprMsYueOpZpAu0HLqH3psicC98/uxx5HuOQLOopKua1Q==',
    test_01_monext:
      'BBLhzTm+4howzDeReS0syyhzRW1v57JYKuZDGEolDSmoO0geKlFQ5284Yidw03Qg5ZBQGy5xP8KwzR6i5G2D8xaJxNOQJ8a462DjnmjZ8TSxhnlOwPuHnR+GeFUSFaXWZA==',
    test_02_monext:
      'BFSTU3lFAI3sRgRvbysZL2/QebafdDqiTehvx3My1NnloXl1sP6UKOtUUrK3sw30LD9jdZlXmjRll4YHsLFi7PxJ7FvREGu7bsSX4QCfZ+65Uzc0IDtbkFmSa+7yTIN2sQ==',
  },
  production: {
    prod_01:
      'BCF48AwGEM8fxaV9nKmqWxh3X4ox2aWTPcq/p/ifmJR3KIYw4w3o7pzzXRgoN6ur9mDuYg+Y4chp0bfrmkauEoMXBNdBuveq97fAUaWHxApLvQITgd1HoVvlWagbXk+vXg==',
    prod_02:
      'BH5qjXLWNImnZLaH+r1jN8SNEfQNbFlX4yqh1OlLlz0vzTQC0yMOKd1pjjfSgDK9K+WcciNuP6fdzbPR/SMeYMoxec/YkJSFbqONKoYwD+9Kw+gNUJiX813dRt0W92dxIQ==',
    prod_01_monext:
      'BOGh7ESPDr6WqPv23QX3TxglVRGUg89PyD86qZn/EGNCNjjqbvNl+Q04Oojn6SBrZLz/Obq1l2TyeekVSqwC1RlY7DUPOSdf54hLCQ176JHYy0FBXNyJeifu9mwQGJ1Klw==',
    prod_02_monext:
      'BPAJ5ZWdB0kop81VRwY6+Rj8prsYWzsAuYvLB/7LL2jaNVGw8p75W40hmDUyDXxamYBk3a5e+GkZhM5h0hPsg38XiMp+dA4CCOPGw/glFkzGIJ2SqqOe5UOpSu8xQSFS4g==',
  },
};
