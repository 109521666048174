import { useState } from 'react';
import { INVALID_MFA } from '../utils/sca-errors';
import type { MfaStatus } from '../components';
import { MFA_STATUSES, MfaModal } from '../components';
import type { MfaMethod } from '../providers/user-provider';
import { useActivateSms, useOtpSms } from '../api/mfa-otp';
import type { ErrorComponentProps } from './sensitive-action';

export function MfaErrorUI({
  onClose,
  onSensitiveAction,
  errorDetails,
}: ErrorComponentProps): JSX.Element {
  const [mfaStatus, setMfaStatus] = useState<MfaStatus>(() => {
    switch (true) {
      case errorDetails.code === INVALID_MFA:
        return MFA_STATUSES.FAILURE;
      case errorDetails.mfaMethod === 'by_sms':
      case errorDetails.mfaMethod === 'by_email':
      case errorDetails.mfaMethod === 'by_app':
        return MFA_STATUSES.CONFIRMATION;
      default:
        return MFA_STATUSES.NOT_STARTED;
    }
  });

  const mfaMethod = (errorDetails.mfaMethod || 'by_sms') as MfaMethod;

  const { mutateAsync: sendOtp } = useOtpSms();
  const { mutateAsync: activateSms } = useActivateSms();

  const handleGetStarted = (): void => {
    setMfaStatus(MFA_STATUSES.VERIFY_NUMBER);
  };

  const handleVerifyNumber = async (): Promise<void> => {
    try {
      await sendOtp();
      setMfaStatus(MFA_STATUSES.CONFIRMATION);
    } catch (error) {
      setMfaStatus(MFA_STATUSES.FAILURE);
    }
  };

  const handleSendSMS = async (): Promise<void> => {
    try {
      await sendOtp();
      setMfaStatus(MFA_STATUSES.VERIFY_NUMBER);
    } catch (error) {
      setMfaStatus(MFA_STATUSES.FAILURE);
    }
  };

  const handleConfirmOtp = async ({ otpToken }: { otpToken: string }): Promise<void> => {
    try {
      // NOTE: if the mfa method is not defined we need to activate the sms method
      if (!errorDetails.mfaMethod || errorDetails.mfaMethod === '') {
        await activateSms({ otpToken });
      }
      await onSensitiveAction({
        header: 'X-Qonto-MFA',
        token: otpToken,
      });
    } catch (error) {
      setMfaStatus(MFA_STATUSES.FAILURE);
    }
  };

  return (
    <MfaModal
      method={mfaMethod}
      onClose={onClose}
      onConfirmOtp={handleConfirmOtp}
      onGetStarted={handleGetStarted}
      onSendSMS={handleSendSMS}
      onVerifyNumber={handleVerifyNumber}
      status={mfaStatus}
    />
  );
}
