export function ExpiredTimeSvg(props: React.SVGProps<SVGSVGElement>): React.ReactNode {
  return (
    <svg
      fill="none"
      height="122"
      viewBox="0 0 122 122"
      width="122"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_36195_217869)">
        <path
          d="M76.3997 84.9816C75.777 69.6283 64.7899 62.6112 58.6385 59.6163C56.6773 58.6594 56.3933 55.8699 58.2725 54.7508C63.1839 51.9187 68.9895 47.8865 71.8753 43.0402C74.7611 38.1938 76.3415 32.6956 76.4653 27.0717H76.4325C76.2031 30.6451 73.701 34.1807 68.9264 36.9107C58.8909 42.6466 42.6166 42.6466 32.5811 36.9107C27.9048 34.2401 25.2826 30.7803 24.9712 27.2771C25.13 32.8652 26.7264 38.3207 29.6094 43.1281C32.4925 47.9355 38.2779 51.9358 43.1585 54.7508C45.0378 55.832 44.7537 58.6432 42.798 59.6163C36.5319 62.6653 25.3973 69.8878 25.0859 85.8574C25.0859 86.0736 25.0586 86.2952 25.0586 86.5169C25.0586 90.4687 27.7409 94.1773 32.6139 96.9615C37.618 99.8213 44.19 101.248 50.762 101.248C57.3339 101.248 63.9059 99.8213 68.91 96.9615C73.783 94.1773 76.4653 90.4741 76.4653 86.5169C76.4653 86.0087 76.3997 84.9816 76.3997 84.9816Z"
          stroke="#1D1D1B"
          strokeLinejoin="round"
          strokeWidth="0.25"
        />
        <path
          d="M76.4667 85.3493C76.4667 93.4584 64.9507 98.3254 50.7578 98.3254C36.565 98.3254 25.0818 94.0814 25.0818 85.9506V85.8425L24.9944 89.9352C25.3058 93.4329 27.928 96.8929 32.6044 99.5689C42.6399 105.299 58.9141 105.299 68.9496 99.5689C73.7242 96.8658 76.2263 93.3032 76.4557 89.7298H76.4885L76.4667 85.3493Z"
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeLinejoin="round"
          strokeWidth="0.25"
        />
        <path
          d="M50.7569 53.7621C54.9115 53.7621 58.2794 51.8331 58.2794 49.4535C58.2794 47.0739 54.9115 45.1448 50.7569 45.1448C46.6023 45.1448 43.2344 47.0739 43.2344 49.4535C43.2344 51.8331 46.6023 53.7621 50.7569 53.7621Z"
          fill="#FFEADD"
          stroke="#1D1D1B"
          strokeLinejoin="round"
          strokeWidth="0.25"
        />
        <path
          d="M53.2839 69.3898C52.8879 69.0769 52.5683 68.6796 52.3489 68.2275C52.1294 67.7754 52.0158 67.2802 52.0165 66.7787V57.5883C52.0146 57.0861 52.1305 56.5904 52.3552 56.1401C52.5799 55.6898 52.9072 55.2973 53.3112 54.9934C54.7372 54.0091 56.0587 52.8843 57.2555 51.6362C55.9498 52.9175 53.5298 53.7987 50.7546 53.7987C47.9794 53.7987 45.4828 52.9121 44.199 51.5984C45.4039 52.8706 46.7406 54.0139 48.187 55.0096C48.592 55.3129 48.9203 55.7052 49.146 56.1555C49.3716 56.6058 49.4884 57.1019 49.4872 57.6046V66.7949C49.4868 67.2968 49.3722 67.7922 49.1518 68.2442C48.9315 68.6963 48.611 69.0933 48.2143 69.406C48.2143 69.406 32.0493 79.9911 32.0493 84.9972C32.0493 90.8952 40.4241 95.6796 50.76 95.6796C61.096 95.6796 69.4708 90.8952 69.4708 84.9972C69.4653 81.1588 53.2839 69.3898 53.2839 69.3898Z"
          fill="white"
          stroke="#1D1D1B"
          strokeLinejoin="round"
          strokeWidth="0.25"
        />
        <path
          d="M53.2839 69.3898C52.8879 69.0769 52.5683 68.6796 52.3489 68.2275C52.1294 67.7754 52.0158 67.2802 52.0165 66.7787V57.5883C52.0146 57.0861 52.1305 56.5904 52.3552 56.1401C52.5799 55.6898 52.9072 55.2973 53.3112 54.9934C54.7372 54.0091 56.0587 52.8843 57.2555 51.6362C55.9498 52.9175 53.5298 53.7987 50.7546 53.7987C47.9794 53.7987 45.4828 52.9121 44.199 51.5984C45.4039 52.8706 46.7406 54.0139 48.187 55.0096C48.592 55.3129 48.9203 55.7052 49.146 56.1555C49.3716 56.6058 49.4884 57.1019 49.4872 57.6046V66.7949C49.4868 67.2968 49.3722 67.7922 49.1518 68.2442C48.9315 68.6963 48.611 69.0933 48.2143 69.406C48.2143 69.406 32.0493 79.9911 32.0493 84.9972C32.0493 90.8952 40.4241 95.6796 50.76 95.6796C61.096 95.6796 69.4708 90.8952 69.4708 84.9972C69.4653 81.1588 53.2839 69.3898 53.2839 69.3898Z"
          fill="#FFD6BC"
          stroke="#1D1D1B"
          strokeLinejoin="round"
          strokeWidth="0.25"
        />
        <path
          d="M57.0767 72.2742C61.8622 76.0098 69.4667 82.362 69.4667 84.9839C69.4667 90.8819 61.0865 95.6663 50.756 95.6663C40.4255 95.6663 32.0453 90.8819 32.0453 84.9839C32.0453 81.6484 39.2072 75.8476 43.9983 72.3391C33.0723 74.0366 25.0581 79.7508 25.0581 86.5355C25.0581 94.6446 36.5632 101.251 50.7341 101.251C64.9051 101.251 76.3774 94.6662 76.3774 86.5355C76.3992 79.6589 68.2157 73.8852 57.0767 72.2742Z"
          fill="#FFEADD"
          stroke="#1D1D1B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.25"
        />
        <path
          d="M31.3665 74.4362C33.6909 69.51 37.6965 65.5383 42.6888 63.2097"
          stroke="#1D1D1B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.25"
        />
        <path
          d="M76.4554 24.3766C71.7728 24.3766 74.1173 27.8613 70.2659 30.4399C68.6541 31.4984 66.9303 32.3794 65.1252 33.0673C64.4423 33.3322 63.7321 33.5755 63.0055 33.8025C62.279 34.0296 61.6835 34.1917 60.9952 34.3431C60.5363 34.4621 60.0665 34.5702 59.5912 34.6675C58.8045 34.8351 58.0015 34.9756 57.1765 35.1C53.3347 35.6598 49.4349 35.7143 45.5786 35.2622C45.1598 35.2117 44.7446 35.1576 44.3331 35.1C43.5136 34.9756 42.6942 34.8351 41.9184 34.6675C41.5251 34.5882 41.1372 34.5017 40.7548 34.408C39.9845 34.2188 39.2361 34.0133 38.5041 33.7863C37.772 33.5592 37.0728 33.316 36.3899 33.0511C34.4607 32.3224 32.6267 31.3677 30.9269 30.2075C30.1682 29.6853 29.4505 29.107 28.78 28.4775C27.9485 27.7129 27.2146 26.8506 26.5948 25.9097C26.4527 25.6826 26.3161 25.4555 26.1905 25.2231C25.9413 24.7703 25.7294 24.2985 25.5568 23.8121C25.2036 22.8631 27.5917 24.3603 25.0105 24.3603L24.9231 27.2774C25.2345 30.7805 27.8567 34.235 32.533 36.911C42.5685 42.6468 58.8427 42.6468 68.8782 36.911C73.6529 34.208 76.1549 30.6454 76.3844 27.072H76.4172V24.3767L76.4554 24.3766Z"
          fill="#FFD6BC"
          stroke="#1D1D1B"
          strokeLinejoin="round"
          strokeWidth="0.25"
        />
        <path
          d="M50.756 38.5068C64.9485 38.5068 76.4539 31.9185 76.4539 23.7915C76.4539 15.6644 64.9485 9.07614 50.756 9.07614C36.5634 9.07614 25.0581 15.6644 25.0581 23.7915C25.0581 31.9185 36.5634 38.5068 50.756 38.5068Z"
          fill="#FFEADD"
          stroke="#1D1D1B"
          strokeLinejoin="round"
          strokeWidth="0.25"
        />
        <path
          d="M101.25 75.446C101.25 86.6031 92.0033 104.455 82.3399 110.034C77.2417 112.977 72.7775 111.04 69.5795 108.869C66.7157 106.925 64.9673 103.135 64.9673 97.864C64.9673 86.7069 72.801 73.1399 82.4645 67.5613C87.1939 64.831 91.4851 64.5203 94.6342 66.2226C97.9194 67.9986 101.25 69.7493 101.25 75.446Z"
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeWidth="0.25"
        />
        <ellipse
          cx="20.2034"
          cy="20.2023"
          fill="#FFD6BC"
          rx="20.2034"
          ry="20.2023"
          stroke="#1D1D1B"
          strokeWidth="0.25"
          transform="matrix(0.866049 -0.499934 2.20342e-08 1 67.2556 78.9968)"
        />
        <path
          d="M85.5092 95.0612C85.7049 94.8522 85.8383 94.5615 85.855 94.3071L87.1874 74.0775C87.2003 73.8811 87.1422 73.7286 87.0279 73.6587L85.9943 73.027C85.8877 72.9619 85.7416 72.9743 85.5887 73.0615L82.3993 74.8797C82.0736 75.0654 81.8123 75.5287 81.8257 75.8967L82.553 95.8153C82.5587 95.9692 82.6128 96.0875 82.7056 96.149L83.5345 96.6978C83.698 96.8061 83.9538 96.7212 84.1747 96.4855L85.5092 95.0612ZM85.9949 102.616C86.1749 102.449 86.3161 102.205 86.372 101.965L87.0245 99.1672C87.0869 98.8996 87.034 98.6776 86.8869 98.5895L85.8789 97.9856C85.7721 97.9216 85.6263 97.9348 85.474 98.0221L82.7127 99.6042C82.3968 99.7852 82.14 100.229 82.14 100.593L82.14 103.858C82.14 104.022 82.1929 104.15 82.2883 104.216L83.1869 104.838C83.3356 104.941 83.5654 104.882 83.7766 104.685L85.9949 102.616Z"
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeWidth="0.25"
        />
        <path
          d="M86.0988 95.5922C86.4066 95.4145 86.6589 94.9869 86.6689 94.6258L87.2376 74.1951C87.2481 73.817 86.9898 73.658 86.6675 73.8441L83.6584 75.5815C83.3368 75.7673 83.0787 76.2232 83.0882 76.5889L83.6028 96.3942C83.6119 96.745 83.8645 96.8823 84.1729 96.7042L86.0988 95.5922ZM86.5353 103.23C86.8503 103.048 87.1057 102.606 87.1058 102.242L87.1059 99.1157C87.1059 98.752 86.8505 98.6045 86.5355 98.7864L83.7907 100.371C83.4756 100.553 83.2202 100.995 83.2202 101.359L83.2201 104.485C83.2201 104.849 83.4755 104.997 83.7905 104.815L86.5353 103.23Z"
          fill="white"
          stroke="#1D1D1B"
          strokeWidth="0.25"
        />
      </g>
      <defs>
        <clipPath id="clip0_36195_217869">
          <rect fill="white" height="121.5" transform="translate(0.25 0.25)" width="121.5" />
        </clipPath>
      </defs>
    </svg>
  );
}
