import cx from 'clsx';
import { type ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import americanExpressIcon from '../../assets/images/american-express.png';
import cartesBancairesIcon from '../../assets/images/cartes-bancaires.png';
import mastercardIcon from '../../assets/images/mastercard.png';
import visaIcon from '../../assets/images/visa.png';
import { CardProvider } from '../constants';
import styles from './credit-card-icons.strict-module.css';

interface CreditCardIconsProps {
  className?: string;
  providers?: CardProvider[];
}

const DEFAULT_PROVIDERS: CardProvider[] = [
  CardProvider.Visa,
  CardProvider.Mastercard,
  CardProvider.AmericanExpress,
];

export function CreditCardIcons({
  className,
  providers = DEFAULT_PROVIDERS,
}: CreditCardIconsProps): ReactNode {
  const { formatMessage } = useIntl();

  const config = useMemo(
    () => ({
      visa: {
        imagePath: visaIcon,
        ariaLabel: formatMessage({
          id: 'receivable-invoices.public-payment-link.credit-card.visa-aria-label',
        }),
      },
      mastercard: {
        imagePath: mastercardIcon,
        ariaLabel: formatMessage({
          id: 'receivable-invoices.public-payment-link.credit-card.mastercard-aria-label',
        }),
      },
      american_express: {
        imagePath: americanExpressIcon,
        ariaLabel: formatMessage({
          id: 'receivable-invoices.public-payment-link.credit-card.american-express-aria-label',
        }),
      },
      cartes_bancaires: {
        imagePath: cartesBancairesIcon,
        ariaLabel: formatMessage({
          id: 'receivable-invoices.public-payment-link.credit-card.cartes-bancaires-aria-label',
        }),
      },
    }),
    [formatMessage]
  );

  return (
    <div className={cx(className, styles.icons)}>
      {providers.map(provider => {
        const { imagePath, ariaLabel } = config[provider];
        return <img alt={ariaLabel} key={provider} src={imagePath} />;
      })}
    </div>
  );
}
