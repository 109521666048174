import { type ReactNode, useState } from 'react';
import type { DateValue } from 'react-aria-components';
import dayjs from 'dayjs';
import { Button, DatePicker } from '@repo/design-system-kit';
import { IconCheckmarkOutlined, IconCrossOutlined } from '@repo/monochrome-icons';
import { parseDate } from '@internationalized/date';
import styles from './action-scheduler.strict-module.css';

interface ActionSchedulerProps {
  date?: string;
  onDateChange?: (date: string | null) => void;
  onSubmit: (date: string | null) => void;
  onCancel: () => void;
  updatedDateForPreviewLine?: (
    date: string | null,
    amount?: string | null,
    originalDate?: string | null,
    side?: string | null
  ) => void;
}

export function ActionScheduler({
  date,
  onDateChange,
  onSubmit,
  onCancel,
}: ActionSchedulerProps): ReactNode {
  const [dateValue, setDateValue] = useState<DateValue | null>(date ? parseDate(date) : null);
  const minStartDate = dayjs().format('YYYY-MM-DD');

  const onChange = (value: DateValue | null): void => {
    setDateValue(value);
    onDateChange?.(value?.toString() ?? null);
  };

  return (
    <div className={styles.container} data-testid="action-scheduler">
      <DatePicker
        className={styles.datePicker}
        minValue={parseDate(minStartDate)}
        onChange={onChange}
        value={dateValue}
      />
      <Button
        data-testid="action-scheduler-submit"
        iconOnly
        onPress={() => {
          onSubmit(dateValue?.toString() ?? null);
        }}
        size="small"
        variant="secondary"
      >
        <IconCheckmarkOutlined />
      </Button>
      <Button
        data-testid="action-scheduler-cancel"
        iconOnly
        onPress={onCancel}
        size="small"
        variant="tertiary"
      >
        <IconCrossOutlined />
      </Button>
    </div>
  );
}
