import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './skeleton.strict-module.css';

interface SkeletonProps {
  payee: string;
}

export function Skeleton({ payee }: SkeletonProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.container}>
      <div>
        <h2 className="title-2 mb-32">
          {formatMessage({ id: 'payment-link.payment-details.title' }, { merchant: payee })}
        </h2>

        <h3 className="title-3 mb-16">
          {formatMessage({ id: 'payment-link.payment-details.contact-details' })}
        </h3>

        <SkeletonLoader.Line className="mb-16" width="100px" />
        <SkeletonLoader.Block className="mb-32" height="40px" width="100%" />
        <SkeletonLoader.Line className="mb-16" width="100px" />
        <SkeletonLoader.Block className="mb-16" height="40px" width="100%" />
        <div className={cx(styles.inline, 'mb-24')}>
          <div>
            <SkeletonLoader.Line className="mb-16" width="100px" />
            <SkeletonLoader.Block height="40px" width="100%" />
          </div>
          <div>
            <SkeletonLoader.Line className="mb-16" width="100px" />
            <SkeletonLoader.Block height="40px" width="100%" />
          </div>
        </div>
        <SkeletonLoader.Block className="mb-24" height="40px" width="100%" />
        <SkeletonLoader.Line className="mb-16" width="280px" />
        <SkeletonLoader.Block className="mb-16" height="80px" width="100%" />
        <SkeletonLoader.Block className="mb-16" height="80px" width="100%" />
        <SkeletonLoader.Block className="mb-16" height="80px" width="100%" />
      </div>
      <div className={styles.overlay} />
    </div>
  );
}
