import { IconDotOutlined } from '@repo/monochrome-icons';
import cx from 'clsx';
import type { ReactNode } from 'react';
import { SkeletonLoader } from '../../skeleton-loader';
import { type TimelineItemProps } from './timeline-item';
import styles from './timeline-item.strict-module.css';

export type LoadingTimelineItemProps = Pick<TimelineItemProps, 'hideLine'>;

export function LoadingTimelineItem(
  props: LoadingTimelineItemProps & { 'data-testid': string }
): ReactNode {
  const { hideLine = false, ...rest } = props;
  return (
    <li
      className={cx(
        styles['timeline-item'],
        styles['variant-dashed'],
        hideLine && styles['hide-line']
      )}
      {...rest}
    >
      <div className={styles['media-container']}>
        <IconDotOutlined height={16} style={{ color: 'var(--content-secondary)' }} width={16} />
      </div>
      <div className={styles['item-content']}>
        <div className={cx(styles['item-title'], styles.loading)}>
          <SkeletonLoader.Line height="16px" standalone width="95px" />
        </div>
        <div className={cx(styles['item-description'], styles.loading)}>
          <SkeletonLoader.Line standalone width="95px" />
        </div>
      </div>
    </li>
  );
}
