/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import React from 'react';
import { useIntl } from 'react-intl';
import type { Amount } from '../../../../types/amount';
import styles from './balance-cell.strict-module.css';

interface BalanceCellProps {
  side?: 'credit' | 'debit';
  amount: Amount;
  displayCurrencySymbol?: boolean;
  subtitle?: string;
  displaySign?: boolean;
  displayColor?: boolean;
}

export function BalanceCell({
  side,
  amount,
  displayCurrencySymbol,
  displaySign = true,
  displayColor = true,
  subtitle,
}: BalanceCellProps): React.ReactNode {
  const { formatNumber } = useIntl();

  const amountValue = side === 'credit' ? amount.value : -amount.value;
  const amountString = formatNumber(amountValue, {
    style: displayCurrencySymbol ? 'decimal' : 'currency',
    currency: amount.currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    signDisplay: displaySign ? 'always' : 'never',
  });

  const className = `${styles.amount} ${side === 'credit' && displayColor ? styles.credit : ''}`;

  return (
    <div>
      <p className={className} data-testid="balance-cell">
        {amountString}
        {displayCurrencySymbol ? ` ${amount.currency}` : ''}
      </p>
      {subtitle ? (
        <p className={styles.subtitle} data-testid="balance-cell-subtitle">
          {subtitle}
        </p>
      ) : null}
    </div>
  );
}
