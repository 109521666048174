import type { HTMLAttributes, ReactNode } from 'react';
import cx from 'clsx';
import styles from './placeholder-block.strict-module.css';

interface PlaceholderBlockProps extends HTMLAttributes<HTMLDivElement> {
  borderRadius?: string;
  width?: string;
  height?: string;
}

export function PlaceholderBlock({
  borderRadius,
  width = '32px',
  height = '32px',
  className,
  ...attributes
}: PlaceholderBlockProps): ReactNode {
  return (
    <div
      className={cx(styles.block, className)}
      data-test-placeholder-block
      style={{
        ...(borderRadius && { borderRadius }),
        width,
        minWidth: width,
        height,
        minHeight: height,
      }}
      {...attributes}
    />
  );
}
