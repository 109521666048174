import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { BasketHeader, BasketItem, BasketSummary } from './internal/components';
import { type BasketProps } from './internal/types';
import { calculateBasketTotals } from './internal/utils/calculate-basket-totals';
import styles from './basket.strict-module.css';

export function Basket({ items, amount, payee, isPreview = false }: BasketProps): ReactNode {
  const { formatNumber, formatMessage } = useIntl();
  const totals = calculateBasketTotals(items);
  const { currency } = amount;

  const formattedTotal = formatNumber(parseFloat(amount.value), {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  });

  return (
    <div className={cx(styles.container, isPreview && styles.preview)}>
      <h2 className={cx(styles.title, 'title-2')}>{formattedTotal}</h2>
      <h3 className={cx(styles.subtitle, 'title-4')}>
        {formatMessage({ id: 'payment-link.payment-details.title' }, { merchant: payee })}
      </h3>

      {items.length > 0 ? (
        <table className={styles.table}>
          <colgroup>
            <col className={cx(styles['col-item'], isPreview && styles['width-mixin'])} />
            <col className={cx(styles['col-quantity'], styles['width-mixin'])} />
            <col className={cx(styles['col-vat'], styles['width-mixin'])} />
            <col className={cx(styles['col-total'], styles['width-mixin'])} />
          </colgroup>

          <BasketHeader isPreview={isPreview} />

          <tbody className={styles.itemisation}>
            {items.map((item, index) => (
              <BasketItem
                currency={currency}
                isPreview={isPreview}
                item={item}
                itemTotal={totals.itemTotals[index] ?? 0}
                key={item.id}
              />
            ))}
          </tbody>

          <BasketSummary currency={currency} isPreview={isPreview} totals={totals} />
        </table>
      ) : null}
    </div>
  );
}
