import type { ReactElement, SVGProps } from 'react';

export function IconIdeal(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill="#F5F5F5" height="40" rx="4" width="40" />
      <g clipPath="url(#clip0_25306_2853)">
        <path
          d="M20.8307 30H10.6389C9.73408 30 9 29.2621 9 28.3526V11.6474C9 10.7379 9.73408 10 10.6389 10H20.8307C30.5018 10 31.9444 16.2548 31.9444 19.9785C31.9444 26.4393 27.9923 30 20.8307 30ZM10.6389 10.5491C10.0328 10.5491 9.54629 11.0382 9.54629 11.6474V28.3526C9.54629 28.9618 10.0328 29.4509 10.6389 29.4509H20.8307C27.6423 29.4509 31.3981 26.0875 31.3981 19.9785C31.3981 11.7761 24.7742 10.5491 20.8307 10.5491H10.6389Z"
          fill="#050505"
        />
        <path
          d="M17.7404 20.8022C17.6295 20.8365 17.5271 20.8537 17.4161 20.8537V20.8622H16.7418V18.6658H17.288C17.4758 18.6658 17.6295 18.6915 17.7575 18.743C17.8856 18.7945 17.988 18.8803 18.0648 18.9746C18.1416 19.069 18.2014 19.1977 18.2355 19.335C18.2697 19.4723 18.2867 19.6353 18.2867 19.8069C18.2867 20.0042 18.2611 20.1587 18.2099 20.296C18.1587 20.4332 18.0904 20.5362 18.0136 20.622C17.9368 20.7078 17.8429 20.7679 17.7404 20.8022Z"
          fill="#050505"
        />
        <path
          d="M24.7827 20.1501L24.3473 18.8717H24.3388L23.8864 20.1501H24.7827Z"
          fill="#050505"
        />
        <path
          clipRule="evenodd"
          d="M15.2481 27.8292V13.2604C15.2481 12.6598 15.7347 12.1621 16.3407 12.1621H21.5561C27.2837 12.1621 29.7676 15.577 29.7676 19.9785C29.7676 24.5774 27.2837 27.8292 21.5561 27.8292H15.2481ZM18.0904 18.1166C17.8941 18.048 17.6892 18.0137 17.4673 18.0137V18.0051H15.9906V21.4886H17.4844C17.749 21.4886 17.9795 21.4371 18.1758 21.3513C18.3721 21.2569 18.5343 21.1368 18.6623 20.9824C18.7904 20.8279 18.8843 20.6392 18.9525 20.4247C19.0123 20.2102 19.0464 19.9785 19.0464 19.7211C19.0464 19.4294 19.0038 19.1806 18.9269 18.9661C18.8416 18.7601 18.7306 18.58 18.594 18.4341C18.4489 18.2968 18.2867 18.1853 18.0904 18.1166ZM20.3015 18.6572H22.1282V18.0051H19.5419V21.4886H22.1709V20.8451H20.3015V19.9957H21.9831V19.4036H20.3015V18.6572ZM24.74 18.0137L26.0374 21.4972H25.2436L24.979 20.725H23.6815L23.4084 21.4972H22.6402L23.9462 18.0137H24.74ZM27.2749 20.8537V18.0137H26.5152V21.4972H28.965V20.8537H27.2749Z"
          fill="#050505"
          fillRule="evenodd"
        />
        <path
          d="M12.3969 21.36C13.2785 21.36 13.9931 20.6417 13.9931 19.7556C13.9931 18.8695 13.2785 18.1511 12.3969 18.1511C11.5154 18.1511 10.8007 18.8695 10.8007 19.7556C10.8007 20.6417 11.5154 21.36 12.3969 21.36Z"
          fill="#050505"
        />
        <path
          d="M13.6003 27.8292C12.2602 27.8292 11.1847 26.7395 11.1847 25.401V23.5049C11.1847 22.8356 11.7225 22.2865 12.3968 22.2865C13.0626 22.2865 13.6089 22.827 13.6089 23.5049V27.8292H13.6003Z"
          fill="#050505"
        />
      </g>
      <defs>
        <clipPath id="clip0_25306_2853">
          <rect fill="white" height="20" transform="translate(0 10)" width="40" />
        </clipPath>
      </defs>
    </svg>
  );
}
