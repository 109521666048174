import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import styles from './amounts-breakdown.strict-module.css';

interface Amount {
  value: string;
  currency: string;
}

interface AmountsBreakdownProps {
  items: AmountsBreakdownItemProps[];
}

interface AmountsBreakdownItemProps {
  label: string;
  amount: Amount;
  sign?: '-' | '+';
}

function AmountsBreakdownItem({ label, amount, sign }: AmountsBreakdownItemProps): ReactNode {
  const { formatNumber } = useIntl();

  const formatAmount = (amountToFormat: Amount): string => {
    const value = Number(amountToFormat.value);
    const formattedAmount = formatNumber(value, {
      style: 'decimal',
      minimumFractionDigits: 2,
    });

    return sign
      ? `${sign}\xA0${formattedAmount}\xA0${amountToFormat.currency}`
      : `${formattedAmount}\xA0${amountToFormat.currency}`;
  };

  return (
    <li className={styles.item}>
      <span className={styles.label}>{label}</span>
      <span>{formatAmount(amount)}</span>
    </li>
  );
}

export function AmountsBreakdown(props: AmountsBreakdownProps): ReactNode {
  const { items, ...otherProps } = props;

  return (
    <ul className={styles['amounts-breakdown']} {...otherProps}>
      {items.map(itemProps => (
        <AmountsBreakdownItem key={itemProps.label} {...itemProps} />
      ))}
    </ul>
  );
}
