import cx from 'clsx';
import type { PropsWithChildren, ReactNode } from 'react';
import { Badge, Button } from '@repo/design-system-kit';
import styles from './education.strict-module.css';
import { Cards, type CardItem } from './internal/cards';

interface EducationProps extends PropsWithChildren {
  cardsContent: CardItem[];
  footerTitle?: string;
  footerSubtitle?: string;
  isPrimaryCtaLoading?: boolean;
  primaryCtaText: string;
  onPrimaryCtaClick: () => void;
  onSecondaryCtaClick?: () => void;
  secondaryCtaText?: string;
  isSecondaryCtaLoading?: boolean;
  textBadge?: string;
  title: ReactNode;
}

export function Education({
  cardsContent,
  children,
  footerTitle,
  footerSubtitle,
  isPrimaryCtaLoading = false,
  isSecondaryCtaLoading = false,
  primaryCtaText,
  onSecondaryCtaClick,
  onPrimaryCtaClick,
  secondaryCtaText,
  textBadge,
  title,
  ...props
}: EducationProps): ReactNode {
  const handleSecondaryCtaClick = (): void => {
    onSecondaryCtaClick?.();
  };

  return (
    <div className={styles.emptyStateEducation} {...props}>
      <header className={cx(styles.header, 'mb-56')}>
        {textBadge ? (
          <Badge className="mb-16" data-testid="education-badge" text={textBadge} type="activate" />
        ) : null}

        <h1 className={cx(styles.title, 'title-2', 'mb-32')} data-testid="education-title">
          {title}
        </h1>

        <div className={styles.actions}>
          {secondaryCtaText ? (
            <Button
              data-testid="education-secondary-cta"
              isLoading={isSecondaryCtaLoading}
              onPress={handleSecondaryCtaClick}
              variant="secondary"
            >
              {secondaryCtaText}
            </Button>
          ) : null}

          <Button
            data-testid="education-primary-cta"
            isLoading={isPrimaryCtaLoading}
            onPress={onPrimaryCtaClick}
            variant="primary"
          >
            {primaryCtaText}
          </Button>
        </div>
      </header>

      <Cards className="mb-56" items={cardsContent} />

      {children ? (
        <footer className={styles.footer}>
          <div className={styles.footerContent}>
            {footerTitle ? (
              <div className="title-4 mb-4" data-testid="education-footer-title">
                {footerTitle}
              </div>
            ) : null}

            {footerSubtitle ? (
              <div
                className={cx('mb-8', styles.footerSubtitle, 'body-2')}
                data-testid="education-footer-subtitle"
              >
                {footerSubtitle}
              </div>
            ) : null}

            {children}
          </div>
        </footer>
      ) : null}
    </div>
  );
}
