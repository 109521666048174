import type { HTMLAttributes, ReactNode } from 'react';
import cx from 'clsx';
import { PlaceholderLine } from '../line';
import styles from './placeholder-header.strict-module.css';

interface PlaceholderHeaderProps extends HTMLAttributes<HTMLDivElement> {
  lines: number;
  width: string;
  smallLinesWidth?: string;
}

export function PlaceholderHeader({
  lines,
  width,
  smallLinesWidth = '75%',
  className,
  ...attributes
}: PlaceholderHeaderProps): ReactNode {
  return (
    <div className={cx(styles.header, className)} {...attributes}>
      <PlaceholderLine width={width} />
      {Array.from({ length: lines }).map((_, index) => (
        <PlaceholderLine
          data-test-placeholder-header-line
          // eslint-disable-next-line react/no-array-index-key -- This is a valid use case for array index as key
          key={index}
          standalone={false}
          width={smallLinesWidth}
        />
      ))}
    </div>
  );
}
