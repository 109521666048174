import type { ReactNode } from 'react';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors,
  type Modifier,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import type { UniqueIdentifier } from '@dnd-kit/core/dist/types/other';
import type { DragEndEvent } from '@dnd-kit/core/dist/types';
import type { Arguments, Transform } from '@dnd-kit/utilities';
import { getEventCoordinates } from '@dnd-kit/utilities';
import { Draggable, type DraggableContainerProps } from './draggable.tsx';

type SortableItem = UniqueIdentifier | { id: UniqueIdentifier };

const sortingStrategies = {
  vertical: verticalListSortingStrategy,
  horizontal: horizontalListSortingStrategy,
};

interface SortableListProps<T> {
  items: T[];
  isDisabled?: boolean;
  direction?: 'vertical' | 'horizontal';
  onSort: (sortedItems: T[]) => void;
  children: (item: T, draggableProps: DraggableContainerProps, index: number) => ReactNode;
}

export function SortableList<T extends SortableItem>({
  children,
  isDisabled,
  direction,
  items,
  onSort,
}: SortableListProps<T>): ReactNode {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const sortingStrategy = direction ? sortingStrategies[direction] : undefined;

  function getId(item: T): UniqueIdentifier {
    if (typeof item === 'string' || typeof item === 'number') {
      return item;
    }

    return item.id;
  }

  function handleDragEnd(event: DragEndEvent): void {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const activeIndex = items.findIndex(item => getId(item) === active.id);
      const overIndex = items.findIndex(item => getId(item) === over?.id);
      const newItems = arrayMove(items, activeIndex, overIndex);
      onSort([...newItems]);
    }
  }

  function snapToDragHandle({ activatorEvent, transform }: Arguments<Modifier>[0]): Transform {
    if (activatorEvent) {
      const dragHandle = (activatorEvent.target as Node).parentElement?.closest('svg');
      if (!dragHandle) {
        return transform;
      }

      const dragHandleCoordinates = dragHandle.getBoundingClientRect();
      const activatorCoordinates = getEventCoordinates(activatorEvent);

      if (!activatorCoordinates) {
        return transform;
      }

      const offsetX = activatorCoordinates.x - dragHandleCoordinates.left;
      const offsetY = activatorCoordinates.y - dragHandleCoordinates.top;

      return {
        ...transform,
        x: transform.x + offsetX - dragHandleCoordinates.width / 2,
        y: transform.y + offsetY - dragHandleCoordinates.height / 2,
      };
    }

    return transform;
  }

  return (
    <DndContext
      collisionDetection={closestCorners}
      modifiers={[snapToDragHandle]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext disabled={isDisabled} items={items} strategy={sortingStrategy}>
        {items.map((item, index) => (
          <Draggable id={getId(item)} key={getId(item)}>
            {draggableProps => children(item, draggableProps, index)}
          </Draggable>
        ))}
      </SortableContext>
    </DndContext>
  );
}
