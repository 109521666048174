import React, { useState } from 'react';
import { Button, Disclaimer, Popup } from '@repo/design-system-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import { QrCode } from '@repo/domain-kit/shared';
import { faqService } from '../../../utils/faq-composer';
import styles from './sca.strict-module.css';

interface EnforceQRCodeProps {
  onClose?: () => void;
  onSms: () => void;
  qrCodeUrl: string;
}

export function EnforceQRCode({ onClose, onSms, qrCodeUrl }: EnforceQRCodeProps): React.ReactNode {
  // NOTE -- this is needed until we have a better way to handle the portal container in qunit tests
  // https://www.notion.so/qonto/React-popover-library-used-within-react-aria-components-does-mount-popovers-modals-outside-of-qunit--12631ee4c696806e87dbef81e9a27922
  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;

  const [isOpen, setIsOpen] = useState(true);
  const [smsStatus, setSmsStatus] = useState<'not-sent' | 'sending' | 'sent'>('not-sent');
  const { formatMessage } = useIntl();

  const handleClose = (): void => {
    onClose?.();
    setIsOpen(false);
  };

  const handleSms = (): void => {
    setSmsStatus('sending');
    onSms();
    setSmsStatus('sent');
  };

  return (
    <Popup.Root
      aria-label={formatMessage({ id: 'sca.enforcement.title' })}
      isOpen={isOpen}
      portalContainer={TEST_PORTAL_CONTAINER}
      role="dialog"
    >
      <Popup.CloseButton onPress={handleClose} />
      <Popup.Header>
        <Popup.Title>
          <FormattedMessage id="sca.enforcement.title" />
        </Popup.Title>
        <Popup.Subtitle>
          <FormattedMessage
            id="sca.enforcement.subtitle"
            values={{
              // eslint-disable-next-line react/no-unstable-nested-components -- This is a valid case since enforce the usage of the <a> tag in react-intl
              a: (chunks: React.ReactNode) => (
                <a
                  className={styles.faqLink}
                  href={faqService.getLocalizedArticle(4674610)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </Popup.Subtitle>
      </Popup.Header>
      <Popup.Body>
        <main className={styles.container}>
          <article className={styles.insetBox}>
            <QrCode url={qrCodeUrl} />
            <FormattedMessage id="sca.enforcement.separator">
              {text => <span className={styles.separator}>{text}</span>}
            </FormattedMessage>
            <Button
              isDisabled={smsStatus === 'sending'}
              onPress={handleSms}
              stretch
              variant="secondary"
            >
              {smsStatus === 'sent' ? (
                <FormattedMessage id="sca.enforcement.resend-sms" />
              ) : (
                <FormattedMessage id="sca.enforcement.sms" />
              )}
            </Button>
          </article>
          <Disclaimer.Block block level="info">
            <FormattedMessage
              id="sca.enforcement.disclaimer.text"
              values={{
                // eslint-disable-next-line react/no-unstable-nested-components -- This is a valid case since enforce the usage of the <a> tag in react-intl
                a: (chunks: React.ReactNode) => (
                  <a
                    href={faqService.getLocalizedArticle(4674606)}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </Disclaimer.Block>
        </main>
      </Popup.Body>
    </Popup.Root>
  );
}
