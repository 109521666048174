import cx from 'clsx';
import type { HTMLAttributes, ReactElement } from 'react';
import styles from '../popup.strict-module.css';

export function PopupSubtitle({
  children,
  ...props
}: HTMLAttributes<HTMLHeadElement>): ReactElement {
  return (
    <h2 className={cx(styles.subtitle, 'body-1')} data-test-id="modal-description" {...props}>
      {children}
    </h2>
  );
}
