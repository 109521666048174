import cx from 'clsx';
import type { ReactNode } from 'react';
import { Button, ListBox, ListBoxItem, Popover, Select } from 'react-aria-components';
import { useIntl } from 'react-intl';
import { IconChevronBottomOutlined } from '@repo/monochrome-icons';
import styles from './styles.strict-module.css';

interface DropdownSelectorProps {
  items: { key: string; labelId: string; dropdownId: string }[];
  selectedKey: string;
  onSelectionChange: (key: string) => void;
  onOpenChange?: (isOpen: boolean) => void;
  ariaLabel?: string;
}

export function DropdownSelector({
  items,
  selectedKey,
  onSelectionChange,
  onOpenChange,
  ariaLabel,
}: DropdownSelectorProps): ReactNode {
  const { formatMessage } = useIntl();

  const selectedItem = items.find(item => item.key === selectedKey);
  const selectedLabel = selectedItem ? formatMessage({ id: selectedItem.labelId }) : '';

  return (
    <Select
      aria-label={ariaLabel ?? selectedLabel}
      className={styles.select}
      data-testid="dropdown-selector"
      onOpenChange={onOpenChange}
      onSelectionChange={key => {
        onSelectionChange(key as string);
      }}
      selectedKey={selectedKey}
    >
      <Button className={cx('title-4', styles['popover-trigger'])} data-testid="trigger">
        {selectedLabel}
        <IconChevronBottomOutlined aria-hidden="true" className={styles['arrow-icon']} />
      </Button>
      <Popover>
        <ListBox className={styles['list-box']} data-testid="dropdown-options" items={items}>
          {item => (
            <ListBoxItem
              className={cx('body-2', styles['list-item'])}
              data-testid={item.key}
              key={item.key}
            >
              {formatMessage({ id: item.dropdownId })}
            </ListBoxItem>
          )}
        </ListBox>
      </Popover>
    </Select>
  );
}
