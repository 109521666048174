interface Unit {
  unitCode: string;
  unitDisplayName: string;
  keywords: string[];
  unitName: string;
  isService?: boolean;
  unitLongName?: string;
}

export const decimalSeparator = (locale: string): string =>
  new Intl.NumberFormat(locale).formatToParts(0.5).find(({ type }) => type === 'decimal')?.value ??
  '.';

export const valuePercentage = (value: string, locale: string): string | null | undefined => {
  if (!value) {
    return undefined;
  }

  return String(Number(value) * 100).replace(/[,.]/g, decimalSeparator(locale));
};

export function getSupportedUnits(
  formatMessage: ({ id }: { id: string }, params: { number: number; language: string }) => string,
  quantity = 1,
  language = 'en'
): Unit[] {
  const t = (id: string): string =>
    formatMessage({ id }, { number: quantity === 0 ? 1 : quantity, language });

  // order needs to be respected as per list https://docs.google.com/spreadsheets/d/17Kf8ABSKmwRJZatz4SE3rWGvficMEPiUcJGDT5wSA2M/edit#gid=1978512896
  return [
    {
      unitCode: 'unit',
      unitDisplayName: t('pdf-preview.unit.unit'),
      keywords: ['unit', t('pdf-preview.unit.unit')],
      unitName: t('pdf-preview.unit.unit'),
      isService: true,
    },
    {
      unitCode: 'hour',
      unitDisplayName: t('pdf-preview.unit.hour'),
      keywords: ['hour', t('pdf-preview.unit.hour')],
      unitName: t('pdf-preview.unit.hour'),
      isService: true,
    },
    {
      unitCode: 'day',
      unitDisplayName: t('pdf-preview.unit.day'),
      keywords: ['day', t('pdf-preview.unit.day')],
      unitName: t('pdf-preview.unit.day'),
      isService: true,
    },
    {
      unitCode: 'month',
      unitDisplayName: t('pdf-preview.unit.month'),
      keywords: ['month', t('pdf-preview.unit.month')],
      unitName: t('pdf-preview.unit.month'),
      isService: true,
    },
    {
      unitCode: 'gram',
      unitDisplayName: `${t('pdf-preview.unit.gram')} - ${t('pdf-preview.unit.long-form.gram')}`,
      keywords: ['gram', t('pdf-preview.unit.gram'), t('pdf-preview.unit.long-form.gram')],
      unitName: t('pdf-preview.unit.gram'),
      unitLongName: t('pdf-preview.unit.long-form.gram'),
    },
    {
      unitCode: 'kilogram',
      unitDisplayName: `${t('pdf-preview.unit.kilogram')} - ${t('pdf-preview.unit.long-form.kilogram')}`,
      keywords: [
        'kilogram',
        t('pdf-preview.unit.kilogram'),
        t('pdf-preview.unit.long-form.kilogram'),
      ],
      unitName: t('pdf-preview.unit.kilogram'),
      unitLongName: t('pdf-preview.unit.long-form.kilogram'),
    },
    {
      unitCode: 'liter',
      unitDisplayName: `${t('pdf-preview.unit.liter')} - ${t('pdf-preview.unit.long-form.liter')}`,
      keywords: ['liter', t('pdf-preview.unit.liter'), t('pdf-preview.unit.long-form.liter')],
      unitName: t('pdf-preview.unit.liter'),
      unitLongName: t('pdf-preview.unit.long-form.liter'),
    },
    {
      unitCode: 'meter',
      unitDisplayName: `${t('pdf-preview.unit.meter')} - ${t('pdf-preview.unit.long-form.meter')}`,
      keywords: ['meter', t('pdf-preview.unit.meter'), t('pdf-preview.unit.long-form.meter')],
      unitName: t('pdf-preview.unit.meter'),
      unitLongName: t('pdf-preview.unit.long-form.meter'),
    },
    {
      unitCode: 'square_meter',
      unitDisplayName: `${t('pdf-preview.unit.square_meter')} - ${t('pdf-preview.unit.long-form.square_meter')}`,
      keywords: [
        'square_meter',
        t('pdf-preview.unit.square_meter'),
        t('pdf-preview.unit.long-form.square_meter'),
      ],
      unitName: t('pdf-preview.unit.square_meter'),
      unitLongName: t('pdf-preview.unit.long-form.square_meter'),
    },
    {
      unitCode: 'cubic_meter',
      unitDisplayName: `${t('pdf-preview.unit.cubic_meter')} - ${t('pdf-preview.unit.long-form.cubic_meter')}`,
      keywords: [
        'cubic_meter',
        t('pdf-preview.unit.cubic_meter'),
        t('pdf-preview.unit.long-form.cubic_meter'),
      ],
      unitName: t('pdf-preview.unit.cubic_meter'),
      unitLongName: t('pdf-preview.unit.long-form.cubic_meter'),
    },
    {
      unitCode: 'ampere',
      unitDisplayName: t('pdf-preview.unit.ampere'),
      keywords: ['ampere', t('pdf-preview.unit.ampere')],
      unitName: t('pdf-preview.unit.ampere'),
    },
    {
      unitCode: 'number_of_articles',
      unitDisplayName: t('pdf-preview.unit.number_of_articles'),
      keywords: ['number_of_articles', t('pdf-preview.unit.number_of_articles')],
      unitName: t('pdf-preview.unit.number_of_articles'),
    },
    {
      unitCode: 'centimeter',
      unitDisplayName: `${t('pdf-preview.unit.centimeter')} - ${t('pdf-preview.unit.long-form.centimeter')}`,
      keywords: [
        'centimeter',
        t('pdf-preview.unit.centimeter'),
        t('pdf-preview.unit.long-form.centimeter'),
      ],
      unitName: t('pdf-preview.unit.centimeter'),
      unitLongName: t('pdf-preview.unit.long-form.centimeter'),
    },
    {
      unitCode: 'cubic_meter_per_hour',
      unitDisplayName: `${t('pdf-preview.unit.cubic_meter_per_hour')} - ${t('pdf-preview.unit.long-form.cubic_meter_per_hour')}`,
      keywords: [
        'cubic_meter_per_hour',
        t('pdf-preview.unit.cubic_meter_per_hour'),
        t('pdf-preview.unit.long-form.cubic_meter_per_hour'),
      ],
      unitName: t('pdf-preview.unit.cubic_meter_per_hour'),
      unitLongName: t('pdf-preview.unit.long-form.cubic_meter_per_hour'),
    },
    {
      unitCode: 'gigajoule',
      unitDisplayName: t('pdf-preview.unit.gigajoule'),
      keywords: ['gigajoule', t('pdf-preview.unit.gigajoule')],
      unitName: t('pdf-preview.unit.gigajoule'),
    },
    {
      unitCode: 'gigawatt',
      unitDisplayName: t('pdf-preview.unit.gigawatt'),
      keywords: ['gigawatt', t('pdf-preview.unit.gigawatt')],
      unitName: t('pdf-preview.unit.gigawatt'),
    },
    {
      unitCode: 'gigawatt_hour',
      unitDisplayName: t('pdf-preview.unit.gigawatt_hour'),
      keywords: ['gigawatt_hour', t('pdf-preview.unit.gigawatt_hour')],
      unitName: t('pdf-preview.unit.gigawatt_hour'),
    },
    {
      unitCode: 'half_year',
      unitDisplayName: t('pdf-preview.unit.half_year'),
      keywords: ['half_year', t('pdf-preview.unit.half_year')],
      unitName: t('pdf-preview.unit.half_year'),
      isService: true,
    },
    {
      unitCode: 'joule',
      unitDisplayName: t('pdf-preview.unit.joule'),
      keywords: ['joule', t('pdf-preview.unit.joule')],
      unitName: t('pdf-preview.unit.joule'),
    },
    {
      unitCode: 'kilojoule',
      unitDisplayName: t('pdf-preview.unit.kilojoule'),
      keywords: ['kilojoule', t('pdf-preview.unit.kilojoule')],
      unitName: t('pdf-preview.unit.kilojoule'),
    },
    {
      unitCode: 'kilovar',
      unitDisplayName: t('pdf-preview.unit.kilovar'),
      keywords: ['kilovar', t('pdf-preview.unit.kilovar')],
      unitName: t('pdf-preview.unit.kilovar'),
    },
    {
      unitCode: 'kilowatt',
      unitDisplayName: t('pdf-preview.unit.kilowatt'),
      keywords: ['kilowatt', t('pdf-preview.unit.kilowatt')],
      unitName: t('pdf-preview.unit.kilowatt'),
    },
    {
      unitCode: 'kilowatt_hour',
      unitDisplayName: t('pdf-preview.unit.kilowatt_hour'),
      keywords: ['kilowatt_hour', t('pdf-preview.unit.kilowatt_hour')],
      unitName: t('pdf-preview.unit.kilowatt_hour'),
    },
    {
      unitCode: 'megajoule',
      unitDisplayName: t('pdf-preview.unit.megajoule'),
      keywords: ['megajoule', t('pdf-preview.unit.megajoule')],
      unitName: t('pdf-preview.unit.megajoule'),
    },
    {
      unitCode: 'megawatt',
      unitDisplayName: t('pdf-preview.unit.megawatt'),
      keywords: ['megawatt', t('pdf-preview.unit.megawatt')],
      unitName: t('pdf-preview.unit.megawatt'),
    },
    {
      unitCode: 'megawatt_hour',
      unitDisplayName: t('pdf-preview.unit.megawatt_hour'),
      keywords: ['megawatt_hour', t('pdf-preview.unit.megawatt_hour')],
      unitName: t('pdf-preview.unit.megawatt_hour'),
    },
    {
      unitCode: 'milligram',
      unitDisplayName: `${t('pdf-preview.unit.milligram')} - ${t('pdf-preview.unit.long-form.milligram')}`,
      keywords: [
        'milligram',
        t('pdf-preview.unit.milligram'),
        t('pdf-preview.unit.long-form.milligram'),
      ],
      unitName: t('pdf-preview.unit.milligram'),
      unitLongName: t('pdf-preview.unit.long-form.milligram'),
    },
    {
      unitCode: 'milliliter',
      unitDisplayName: `${t('pdf-preview.unit.milliliter')} - ${t('pdf-preview.unit.long-form.milliliter')}`,
      keywords: [
        'milliliter',
        t('pdf-preview.unit.milliliter'),
        t('pdf-preview.unit.long-form.milliliter'),
      ],
      unitName: t('pdf-preview.unit.milliliter'),
      unitLongName: t('pdf-preview.unit.long-form.milliliter'),
    },
    {
      unitCode: 'millimeter',
      unitDisplayName: `${t('pdf-preview.unit.millimeter')} - ${t('pdf-preview.unit.long-form.millimeter')}`,
      keywords: [
        'millimeter',
        t('pdf-preview.unit.millimeter'),
        t('pdf-preview.unit.long-form.millimeter'),
      ],
      unitName: t('pdf-preview.unit.millimeter'),
      unitLongName: t('pdf-preview.unit.long-form.millimeter'),
    },
    {
      unitCode: 'minute',
      unitDisplayName: t('pdf-preview.unit.minute'),
      keywords: ['minute', t('pdf-preview.unit.minute')],
      unitName: t('pdf-preview.unit.minute'),
      isService: true,
    },
    {
      unitCode: 'pair',
      unitDisplayName: t('pdf-preview.unit.pair'),
      keywords: ['pair', t('pdf-preview.unit.pair')],
      unitName: t('pdf-preview.unit.pair'),
    },
    {
      unitCode: 'quarter',
      unitDisplayName: t('pdf-preview.unit.quarter'),
      keywords: ['quarter', t('pdf-preview.unit.quarter')],
      unitName: t('pdf-preview.unit.quarter'),
      isService: true,
    },
    {
      unitCode: 'second',
      unitDisplayName: t('pdf-preview.unit.second'),
      keywords: ['second', t('pdf-preview.unit.second')],
      unitName: t('pdf-preview.unit.second'),
      isService: true,
    },
    {
      unitCode: 'set',
      unitDisplayName: t('pdf-preview.unit.set'),
      keywords: ['set', t('pdf-preview.unit.set')],
      unitName: t('pdf-preview.unit.set'),
    },
    {
      unitCode: 'ton',
      unitDisplayName: `${t('pdf-preview.unit.ton')} - ${t('pdf-preview.unit.long-form.ton')}`,
      keywords: ['ton', t('pdf-preview.unit.ton'), t('pdf-preview.unit.long-form.ton')],
      unitName: t('pdf-preview.unit.ton'),
      unitLongName: t('pdf-preview.unit.long-form.ton'),
    },
    {
      unitCode: 'two_week',
      unitDisplayName: t('pdf-preview.unit.two_week'),
      keywords: ['two_week', t('pdf-preview.unit.two_week')],
      unitName: t('pdf-preview.unit.two_week'),
      isService: true,
    },
    {
      unitCode: 'watt_hour',
      unitDisplayName: t('pdf-preview.unit.watt_hour'),
      keywords: ['watt_hour', t('pdf-preview.unit.watt_hour')],
      unitName: t('pdf-preview.unit.watt_hour'),
    },
    {
      unitCode: 'week',
      unitDisplayName: t('pdf-preview.unit.week'),
      keywords: ['week', t('pdf-preview.unit.week')],
      unitName: t('pdf-preview.unit.week'),
      isService: true,
    },
    {
      unitCode: 'year',
      unitDisplayName: t('pdf-preview.unit.year'),
      keywords: ['year', t('pdf-preview.unit.year')],
      unitName: t('pdf-preview.unit.year'),
      isService: true,
    },
  ];
}
