import { useState, type ReactNode } from 'react';
import { clsx } from 'clsx';
import type { BadgeType } from '@repo/design-system-kit';
import { Badge, Button, LottiePlayer } from '@repo/design-system-kit';
import { EmptyStatesDivider } from '../empty-states-divider/empty-states-divider.tsx';
import styles from './empty-states-discover.strict-module.css';

export interface EmptyStatesDiscoverProps {
  title: string;
  subtitle: ReactNode;
  ctaText?: string;
  children?: ReactNode;
  onCtaClick?: () => void;
  illuSrc?: string;
  lottieSrc?: object;
  badgeText: string;
  badgeType: BadgeType;
  secondaryCtaText?: string;
  onSecondaryCtaClick?: () => void;
  align?: 'center' | 'start';
}

export function EmptyStatesDiscover({
  subtitle,
  title,
  onCtaClick,
  ctaText,
  illuSrc,
  lottieSrc,
  badgeText,
  badgeType,
  children,
  secondaryCtaText,
  onSecondaryCtaClick,
  align = 'start',
  ...props
}: EmptyStatesDiscoverProps): ReactNode {
  const [animationReady, setAnimationReady] = useState(false);

  return (
    <div
      className={clsx(
        styles.wrapper,
        align === 'center' && styles['align-center'],
        align === 'start' && styles['align-start']
      )}
      {...props}
    >
      <div className={styles.content}>
        {illuSrc || lottieSrc ? (
          <div className={styles.image}>
            {illuSrc ? (
              <img alt="" data-test-empty-state-discover-image role="none" src={illuSrc} />
            ) : null}
            {!illuSrc && lottieSrc ? (
              <LottiePlayer
                animationData={lottieSrc}
                className={clsx(styles.animation, !animationReady && 'hidden')}
                data-test-empty-state-discover-animation={lottieSrc}
                onDataReady={() => {
                  setAnimationReady(true);
                }}
              />
            ) : null}
          </div>
        ) : null}
        <Badge className="mb-16" text={badgeText} type={badgeType} />

        <h1
          className={clsx(styles.title, 'title-2')}
          dangerouslySetInnerHTML={{ __html: title }}
          data-test-empty-state-discover-title
        />
        <p
          className={clsx(styles.subtitle, 'body-2', 'mb-32')}
          data-test-empty-state-discover-subtitle
        >
          {subtitle}
        </p>
        <div className={styles.buttons}>
          {children}
          {!children && ctaText ? (
            <Button
              data-test-empty-state-discover-primary-button
              onPress={onCtaClick}
              variant="primary"
            >
              {ctaText}
            </Button>
          ) : null}
          {!children && secondaryCtaText ? (
            <>
              <EmptyStatesDivider />
              <Button
                data-test-empty-state-discover-secondary-button
                onPress={onSecondaryCtaClick}
                variant="tertiary"
              >
                {secondaryCtaText}
              </Button>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
