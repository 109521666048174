import { type ReactNode } from 'react';
import cx from 'clsx';
import { IconChevronUpDownOutlined } from '@repo/design-system-kit/assets/icons';
import { IconArrowBottomOutlined } from '@repo/monochrome-icons';
import styles from './styles.strict-module.css';

interface SortIconProps extends React.HTMLAttributes<HTMLSpanElement> {
  isActive?: boolean;
  isAscending?: boolean;
  className?: string;
}

export function SortIcon({ isActive, isAscending, className, ...props }: SortIconProps): ReactNode {
  function getIcon(): ReactNode {
    if (isActive && isAscending) {
      return <IconArrowBottomOutlined className={cx(styles.active, styles.ascending)} />;
    }

    if (isActive) {
      return <IconArrowBottomOutlined className={styles.active} />;
    }

    return <IconChevronUpDownOutlined data-testid="sort-default" />;
  }

  return (
    <span aria-hidden="true" className={cx(styles.icon, className)} {...props}>
      {getIcon()}
    </span>
  );
}
