import type { SVGProps, ReactElement } from 'react';

export function IconWarningSignFilled(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="currentColor"
      height={16}
      viewBox="0 0 16 16"
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          clipRule="evenodd"
          d="M15.85 13.49 8.95 1.55a1.09 1.09 0 0 0-1.9 0L.15 13.49c-.42.74.1 1.65.95 1.65h13.8c.85 0 1.37-.91.95-1.65ZM7.5 5.84c0-.27.22-.5.5-.5s.5.23.5.5v4.56c0 .27-.22.5-.5.5s-.5-.23-.5-.5V5.84Zm.5 7.32c-.36 0-.65-.29-.65-.65 0-.36.29-.65.65-.65.36 0 .65.29.65.65 0 .36-.29.65-.65.65Z"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
