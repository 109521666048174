import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@repo/design-system-kit';
import { useSegment } from '@repo/poly-hooks/segment';
import { os } from '../../../utils/device';
import { Steps } from '../steps';

export interface UnsupportedProps {
  fourthlineRedirectUrl: string;
  mode: string;
}

export function Unsupported({ fourthlineRedirectUrl, mode }: UnsupportedProps): ReactNode {
  const intl = useIntl();
  const segment = useSegment();
  const isIOS = os?.name === 'iOS';

  const handleClick = (): void => {
    void window.navigator.clipboard.writeText(fourthlineRedirectUrl);
  };

  useEffect(() => {
    segment.track(`${mode}_fourthline_unsupported_browser_screen_loaded`);
  }, [mode, segment]);

  return (
    <>
      <h1
        className="title-2 mb-16"
        {...{
          [`data-test-unsupported-${isIOS ? 'ios' : 'android'}-title`]: true,
        }}
      >
        <FormattedMessage id={isIOS ? 'unsupported.title-ios' : 'unsupported.title-android'} />
      </h1>
      <p className="body-2 mb-32">
        <FormattedMessage id="unsupported.subtitle" />
      </p>
      <Steps
        className="mb-32"
        steps={
          isIOS
            ? [
                intl.formatMessage({ id: 'unsupported.steps.update-os' }),
                intl.formatMessage({
                  id: 'unsupported.steps.switch-to-safari',
                }),
                intl.formatMessage({
                  id: 'unsupported.steps.copy-verification-link-ios',
                }),
              ]
            : [
                intl.formatMessage({ id: 'unsupported.steps.update-chrome' }),
                intl.formatMessage({ id: 'unsupported.steps.install-chrome' }),
                intl.formatMessage({
                  id: 'unsupported.steps.copy-verification-link-android',
                }),
              ]
        }
      />
      <Button onPress={handleClick} stretch>
        <FormattedMessage id="unsupported.copy-link" />
      </Button>
    </>
  );
}
