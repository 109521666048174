import cx from 'clsx';
import type { ReactElement } from 'react';
import { IconCheckmarkOutlined } from '@repo/monochrome-icons';
import styles from './card.strict-module.css';

export interface CardProps {
  index: number;
  type: string;
  illustration: ReactElement | string;
  title: string;
  list: string[];
}

export function Card({ index, type, title, illustration, list }: CardProps): ReactElement {
  return (
    <div className={cx(styles.card, styles[type])} data-testid={`empty-state-card-${index}`}>
      <div className={cx('mb-16', styles['illustration-wrapper'])}>
        {typeof illustration === 'string' ? (
          <img
            alt=""
            className={styles.illustration}
            data-testid="empty-state-card-illustration"
            src={illustration}
          />
        ) : (
          illustration
        )}
      </div>
      <h2 className="title-2 mb-16" data-testid="empty-state-card-title">
        {title}
      </h2>
      <ul className={cx('body-1', styles.list)}>
        {list.map((item, i) => (
          <li data-testid={`empty-state-card-list-item-${i}`} key={item}>
            <IconCheckmarkOutlined className={styles['mr-12']} />
            <span>{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
