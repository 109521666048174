import { useId } from 'react';
import type { HTMLProps, PropsWithChildren, ReactNode } from 'react';
import cx from 'clsx';
import { IconCrossRoundedFilled } from '@repo/monochrome-icons';
import styles from './collapse-option.strict-module.css';

export interface Option {
  value: unknown;
  clearable?: boolean;
}

interface CollapseOptionProps<T> extends Omit<HTMLProps<HTMLButtonElement>, 'onSelect' | 'type'> {
  option: T;
  isSelected?: boolean;
  isClearable?: boolean;
  isCollapsed?: boolean;
  onSelect: (option: T) => Promise<void> | void;
  onClear?: () => Promise<void> | void;
}

export function CollapseOption<T extends Option>({
  option,
  isSelected,
  isClearable,
  isCollapsed,
  onSelect,
  onClear,
  children,
  ...props
}: PropsWithChildren<CollapseOptionProps<T>>): ReactNode {
  const optionId = useId();

  const stringValue = String(option.value);
  const isHidden = isCollapsed && !isSelected;
  const showClearButton = isClearable && isSelected && !props.disabled;

  function handleSelect(): void {
    if (props.disabled) return;

    if (!isSelected && !showClearButton) {
      void onSelect(option);
    } else {
      void onClear?.();
    }
  }

  return (
    <button
      aria-hidden={isHidden ? 'true' : 'false'}
      aria-labelledby={optionId}
      aria-selected={isSelected ? 'true' : 'false'}
      className={cx(
        styles.chip,
        isClearable && styles.chipClearable,
        isCollapsed && styles.chipCollapsed,
        isHidden && styles.chipHidden,
        isSelected && styles.chipSelected
      )}
      data-collapse-option={stringValue}
      onClick={handleSelect}
      role="option"
      type="button"
      {...props}
    >
      <div className={styles.wrapper}>
        <div className={styles.content} id={optionId}>
          {children}
        </div>
        {showClearButton ? (
          <div className={styles.clear}>
            <div className={styles.clearIcon}>
              <IconCrossRoundedFilled aria-hidden="true" />
            </div>
          </div>
        ) : null}
      </div>
    </button>
  );
}
