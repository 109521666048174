import type { ButtonProps } from '@repo/design-system-kit';
import { Button } from '@repo/design-system-kit';
import type { ReactNode } from 'react';
import { QontoIntelligenceIcon } from '../../assets/icons/icon-qonto-intelligence';

type QontoIntelligenceButtonProps = Omit<ButtonProps, 'children'>;

export function QontoIntelligenceButton(props: QontoIntelligenceButtonProps): ReactNode {
  return (
    <Button {...props} iconOnly>
      <QontoIntelligenceIcon />
    </Button>
  );
}
