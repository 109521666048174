import type { CipherSuite, SenderContext } from 'hpke-js';

interface EncryptedData {
  enc: ArrayBuffer;
  encryptedData: ArrayBuffer;
}

interface EncryptParams {
  data: string;
  plainTextAad: string;
  plainTextInfo: string;
  recipientPublicKey: CryptoKey;
  senderKey: CryptoKeyPair;
  suite: CipherSuite;
}

export async function encrypt({
  data,
  plainTextAad,
  plainTextInfo,
  recipientPublicKey,
  senderKey,
  suite,
}: EncryptParams): Promise<EncryptedData> {
  const sc: SenderContext = await suite.createSenderContext({
    info: new TextEncoder().encode(plainTextInfo),
    recipientPublicKey,
    senderKey,
  });

  const aad: ArrayBuffer = new TextEncoder().encode(plainTextAad);
  const encryptedData: ArrayBuffer = await sc.seal(new TextEncoder().encode(data), aad);

  return { enc: sc.enc, encryptedData };
}
