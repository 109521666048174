import type { ComponentProps } from 'react';
import { Mfa, Sca } from '../popup';
import { useUser, type MfaMethod } from '../../providers/user-provider';

export const MFA_STATUSES = {
  NOT_STARTED: 'not_started',
  VERIFY_NUMBER: 'verify_number',
  FAILURE: 'failure',
  CONFIRMATION: 'confirmation',
} as const;

export type MfaStatus = (typeof MFA_STATUSES)[keyof typeof MFA_STATUSES];

interface MfaModalProps {
  status: MfaStatus;
  method: MfaMethod;
  onClose: () => void;
  onGetStarted: ComponentProps<typeof Mfa.Introduction>['onGetStarted'];
  onVerifyNumber: ComponentProps<typeof Mfa.PhoneNumber>['onVerifyNumber'];
  onSendSMS: ComponentProps<typeof Mfa.Confirmation>['onSendSMS'];
  onConfirmOtp: ComponentProps<typeof Mfa.Confirmation>['onConfirmOtp'];
}

export function MfaModal({
  status,
  method,
  onClose,
  onGetStarted,
  onVerifyNumber,
  onSendSMS,
  onConfirmOtp,
}: MfaModalProps): React.ReactNode {
  const user = useUser();

  switch (status) {
    case MFA_STATUSES.NOT_STARTED:
      return <Mfa.Introduction onClose={onClose} onGetStarted={onGetStarted} />;
    case MFA_STATUSES.VERIFY_NUMBER:
      return (
        <Mfa.PhoneNumber onVerifyNumber={onVerifyNumber} phoneNumber={user.phoneNumber || ''} />
      );
    case MFA_STATUSES.CONFIRMATION:
      return (
        <Mfa.Confirmation
          mfa={{
            phoneNumber: user.phoneNumber || '',
            email: user.email || '',
            method,
          }}
          onCancel={onClose}
          onClose={onClose}
          onConfirmOtp={onConfirmOtp}
          onSendSMS={onSendSMS}
        />
      );
    case MFA_STATUSES.FAILURE:
      return <Sca.GenericError onClose={onClose} />;
  }
}
