import * as React from 'react';
import { clsx } from 'clsx';
import { ProductBadge } from '../product-badge/index.ts';
import { Divider } from '../divider.tsx';
import styles from './add-on-detail.strict-module.css';

interface BaseProps {
  name: string;
  subtitle?: string;
  traits: { name: 'active' | 'popular' | 'free_trial' | 'recommended'; title: string }[];
  price: string;
  pricePeriod: string;
  featuresTitle: string;
  features: string[];
  comingSoonFeaturesTitle?: string;
  comingSoonFeatures?: string[];
  mainButton?: React.ReactNode;
  index?: number;
}

export interface MonthlyProps extends BaseProps {
  recurrence: 'monthly';
  annualSwitch: React.ReactNode;
  pricePrefix?: string;
}

export interface AnnualProps extends BaseProps {
  recurrence: 'annual';
  priceMonthly: string;
  billingRecurrence: string;
  pricePrefix?: string;
}

export type AddOnCardProps = MonthlyProps | AnnualProps;

export function AddOnDetail({
  name,
  subtitle,
  traits,
  price,
  pricePeriod,
  mainButton,
  featuresTitle,
  features,
  comingSoonFeaturesTitle,
  comingSoonFeatures,
  index,
  ...props
}: AddOnCardProps): React.JSX.Element {
  return (
    <div
      className={styles.container}
      data-test-add-on-detail={index}
      data-test-addon-detail-card={name}
    >
      <div className={styles['subgrid-section']}>
        <div className="mb-8">
          <ProductBadge className={styles.badge} traits={traits} />
        </div>
        <div className="mb-16">
          <h2 className={clsx(styles.heading, 'title-2')} data-test-addon-detail-card-title>
            {name}
          </h2>

          {subtitle !== undefined && (
            <span className="caption" data-test-addon-detail-card-subtitle>
              {subtitle}
            </span>
          )}
        </div>

        <div className={styles['price-info']} data-test-addon-detail-card-priceinfo>
          {props.pricePrefix ? (
            <>
              <span className="title-4">{props.pricePrefix}</span>{' '}
            </>
          ) : null}
          {props.recurrence === 'annual' && (
            <>
              <span className={clsx(styles['price-monthly'], 'title-4')}>{props.priceMonthly}</span>{' '}
            </>
          )}
          <span className="title-4">{price}</span>
          <span className="caption">{pricePeriod}</span>
        </div>

        <div className="mb-16">
          {props.recurrence === 'monthly' && props.annualSwitch}
          {props.recurrence === 'annual' && (
            <span
              className={clsx(styles.billing, 'caption')}
              data-test-addon-detail-card-billing-recurrence
            >
              {props.billingRecurrence}
            </span>
          )}
        </div>
        <div className={styles.button}>{mainButton}</div>

        <h3
          className={clsx(styles['feature-heading'], 'title-4')}
          data-test-addon-detail-card-feature-title
        >
          {featuresTitle}
        </h3>
      </div>
      <div>
        <ul className={styles.list}>
          {features.map(feature => (
            <li
              className="mb-8 body-2"
              data-test-addon-detail-card-feature-item={feature}
              key={feature}
            >
              {feature}
            </li>
          ))}
        </ul>

       {Boolean(comingSoonFeatures) && (
         <>
           <Divider className={styles.divider} />

           <h4
             className={clsx(styles['soon-list'], 'body-2', 'mb-8')}
             data-test-addon-detail-card-soon-feature-title
           >
             {comingSoonFeaturesTitle}
           </h4>

           <ul className={clsx(styles['soon-list'], styles.list)}>
             {comingSoonFeatures?.map((feature) => (
               <li
                 className="mb-8 body-2"
                 data-test-addon-detail-card-soon-feature-item={feature}
                 key={feature}
               >
                 {feature}
               </li>
             ))}
           </ul>
         </>
       )}
      </div>
    </div>
  );
}
