import type { Meta, StoryObj } from '@storybook/react';
import React from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import { Counterparty } from '../../shared/table/body-cells/counterparty';
import { ExpectedDate } from '../../shared/table/body-cells/expected-date';
import { BalanceCell } from '../../shared/table/body-cells/balance-cell';
import { TypeCell } from './type-cell';
import styles from './transaction-row.strict-module.css';

interface TransactionRowProps {
  isLoading?: boolean;
}

export function LoadingTransactionRow(): React.ReactNode {
  return (
    <tr className={styles.row}>
      <td />
      <td className={styles.cell}>
        <div className={styles.counterPartyContainer}>
          <SkeletonLoader.Avatar height="32px" width="32px" />
          <div>
            <SkeletonLoader.Line
              className={styles.counterPartyNameSkeleton}
              height="12px"
              width="140px"
            />
            <SkeletonLoader.Line height="12px" width="108px" />
          </div>
        </div>
      </td>
      <td className={styles.cell}>
        <SkeletonLoader.Line height="12px" width="100px" />
      </td>
      <td className={styles.cell}>
        <SkeletonLoader.Line height="12px" width="90px" />
      </td>
      <td className={styles.cell}>
        <div className={styles.balanceSkeletonContainer}>
          <SkeletonLoader.Line height="12px" width="100px" />
        </div>
      </td>
      <td />
    </tr>
  );
}

export function TransactionRow({ isLoading }: TransactionRowProps): React.ReactNode {
  return (
    <tr className={styles.row}>
      {isLoading ? (
        <LoadingTransactionRow />
      ) : (
        <>
          <td className={styles.cell}>
            <Counterparty subtitle="INV-2025-120" title="Dundler Mifflin Inc." />
          </td>
          <td className={styles.cell}>
            <TypeCell type="Client invoice" />
          </td>
          <td className={styles.cell}>
            <ExpectedDate date="2025-02-06" />
          </td>
          <td className={styles.cell}>
            <BalanceCell
              amount={{ value: 100, currency: 'USD' }}
              displayCurrencySymbol
              side="credit"
            />
          </td>
        </>
      )}
    </tr>
  );
}

const meta = {
  title: 'Shared/Transaction Row',
  tags: ['autodocs'],
  parameters: {
    layout: 'padded',
  },
} satisfies Meta;

export default meta;
type Story = StoryObj<typeof meta>;

// Individual component stories
export const TypeCellComponent: Story = {
  render: () => <TypeCell type="Client invoice" />,
};

export const ExpectedDateComponent: Story = {
  render: () => <ExpectedDate date="3025-02-06" />,
};

export const ExpectedDateDueComponent: Story = {
  render: () => <ExpectedDate date="2025-02-06" />,
};

export const BalanceCellComponent: Story = {
  render: () => <BalanceCell amount={{ value: 100, currency: 'USD' }} side="credit" />,
};

export const FullRow: Story = {
  render: () => <TransactionRow />,
};

export const LoadingRow: Story = {
  render: () => <TransactionRow isLoading />,
};
