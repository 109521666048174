import { parseDate } from '@internationalized/date';
import {
  Button,
  DatePicker,
  dateToken,
  Disclaimer,
  Form,
  Select,
  SelectOption,
} from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { useMemo, type ReactNode } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as v from 'valibot';
import { FormattedMessage, useIntl } from 'react-intl';
import { valibotResolver } from '@hookform/resolvers/valibot';
import { STANDING_FREQUENCIES } from '../constants/frequencies';
import { type SchedulingOptions, StandingFrequency } from '../types';
import { capitalize } from '../../../utils';

const dateStringSchema = v.pipe(v.string(), v.isoDate('The date string is badly formatted.'));

interface ScheduleFormProps {
  onSubmit: (data: SchedulingOptions) => void;
  schedule: SchedulingOptions | null;
  amount?: number;
  currency?: string;
  editingTransfer?: boolean;
  isLoading?: boolean;
  removeSchedule?: () => void;
}

export function ScheduleForm({
  onSubmit,
  schedule,
  amount,
  currency,
  editingTransfer = false,
  isLoading = false,
  removeSchedule,
}: ScheduleFormProps): ReactNode {
  const { formatMessage, formatNumber, locale } = useIntl();

  const dateAfterTodaySchema = v.pipe(
    dateStringSchema,
    v.check(
      date => !dayjs(date).isBefore(dayjs(), 'day') || editingTransfer,
      formatMessage(
        {
          id: 'datepicker-error-minimum-date',
        },
        {
          date: dayjs().format('DD/MM/YYYY'),
        }
      )
    )
  );

  const schema = v.required(
    v.object({
      startDate: dateAfterTodaySchema,
      standingFrequency: v.enum(StandingFrequency),
      endDate: v.nullable(dateAfterTodaySchema),
    }),
    ['startDate']
  );

  const today = dayjs().format('YYYY-MM-DD');

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: valibotResolver(schema),
    defaultValues: {
      startDate: schedule?.startDate ?? today,
      standingFrequency: schedule?.standingFrequency ?? StandingFrequency.None,
      endDate: schedule?.endDate ?? null,
    },
  });

  const watchStartDate = watch('startDate');
  const watchStandingFrequency = watch('standingFrequency');
  const watchEndDate = watch('endDate');

  const isFrequencyReadOnly = editingTransfer && watchStandingFrequency === StandingFrequency.None;
  const isStartDatePast = dayjs(watchStartDate).isBefore(dayjs(), 'day');

  const occurrences = useMemo(() => {
    if (watchStandingFrequency === StandingFrequency.None || !watchEndDate) return undefined;

    const start = dayjs(watchStartDate);
    const end = dayjs(watchEndDate);

    switch (watchStandingFrequency) {
      case StandingFrequency.Weekly:
        return end.diff(start, 'week') + 1;
      case StandingFrequency.Monthly:
        return end.diff(start, 'month') + 1;
      case StandingFrequency.Quarterly:
        return end.diff(start, 'quarter') + 1;
      case StandingFrequency.HalfYearly:
        return end.diff(start, 'year') * 2 + 1;
      case StandingFrequency.Yearly:
        return end.diff(start, 'year') + 1;
    }
  }, [watchStandingFrequency, watchEndDate, watchStartDate]);

  return (
    <Form
      onSubmit={async e => {
        e.preventDefault();
        e.stopPropagation();
        await handleSubmit(({ startDate, endDate, standingFrequency }) => {
          onSubmit({ startDate, endDate, standingFrequency });
        })();
      }}
    >
      <Controller
        control={control}
        name="startDate"
        render={(
          { field: { ref: _, ...field } } // ? Remove ref to avoid "Function components cannot be given refs." warning
        ) => (
          <DatePicker
            className="mb-16"
            data-testid="start-date-picker"
            errorMessage={errors.startDate?.message}
            isDisabled={isLoading || (isStartDatePast && editingTransfer)}
            label={formatMessage({
              id: 'transfers.international-out.schedule-form.label.date',
            })}
            minValue={isStartDatePast ? parseDate(watchStartDate) : parseDate(today)}
            minYear={isStartDatePast ? parseDate(watchStartDate).year : parseDate(today).year}
            {...field}
            onChange={value => {
              if (value === null) {
                field.onChange(today);
              } else {
                field.onChange(value.toString());
              }
            }}
            value={
              v.safeParse(dateStringSchema, field.value).success
                ? parseDate(field.value)
                : parseDate(today)
            }
          />
        )}
      />

      <Controller
        control={control}
        name="standingFrequency"
        render={(
          { field: { ref: _, ...field } } // ? Remove ref to avoid "Function components cannot be given refs." warning
        ) => (
          <Select
            className={watchStandingFrequency !== StandingFrequency.None ? 'mb-16' : 'mb-32'}
            data-testid="frequency-select"
            errorMessage={errors.standingFrequency?.message}
            isDisabled={isLoading}
            isReadOnly={isFrequencyReadOnly}
            label={formatMessage({
              id: 'transfers.international-out.schedule-form.label.frequency',
            })}
            onSelectionChange={field.onChange}
            placeholder={capitalize(
              formatMessage({
                id: 'transfers.international-out.schedule-form.frequency.none',
              })
            )}
            selectedKey={field.value}
            {...field}
          >
            {(Object.entries(STANDING_FREQUENCIES) as [StandingFrequency, string][]).map(
              ([key, value]) => (
                <SelectOption
                  id={key}
                  isDisabled={editingTransfer ? key === StandingFrequency.None : false}
                  key={key}
                  value={{
                    id: key,
                    label: capitalize(
                      formatMessage({
                        id: `transfers.international-out.schedule-form.frequency.${value}`,
                      })
                    ),
                  }}
                >
                  {capitalize(
                    formatMessage({
                      id: `transfers.international-out.schedule-form.frequency.${value}`,
                    })
                  )}
                </SelectOption>
              )
            )}
          </Select>
        )}
      />

      {watchStandingFrequency !== StandingFrequency.None ? (
        <Controller
          control={control}
          name="endDate"
          render={(
            { field: { ref: _, ...field } } // ? Remove ref to avoid "Function components cannot be given refs." warning
          ) => (
            <DatePicker
              className="mb-16"
              data-testid="end-date-picker"
              errorMessage={errors.endDate?.message}
              isDisabled={isLoading}
              label={formatMessage({
                id: 'transfers.international-out.schedule-form.label.end-date',
              })}
              minValue={isStartDatePast ? parseDate(today) : parseDate(watchStartDate)}
              minYear={isStartDatePast ? parseDate(today).year : parseDate(watchStartDate).year}
              {...field}
              onChange={value => {
                if (value === null) {
                  field.onChange(null);
                } else {
                  field.onChange(value.toString());
                }
              }}
              value={
                v.safeParse(dateStringSchema, field.value).success
                  ? parseDate(field.value ?? watchStartDate)
                  : null
              }
            />
          )}
        />
      ) : null}

      {watchStandingFrequency !== StandingFrequency.None ? (
        <Disclaimer.Inline className="mb-32" data-testid="recurring-disclaimer">
          <FormattedMessage
            id="transfers.international-out.schedule-form.disclaimer.recurring"
            values={{
              transferAmount: amount
                ? formatNumber(amount, {
                    currency,
                    style: 'currency',
                  })
                : undefined,
              frequency: formatMessage({
                id: `transfers.international-out.schedule-form.frequency.${STANDING_FREQUENCIES[watchStandingFrequency]}`,
              }),
              startDate: dateToken({ date: watchStartDate, locale }),
              endDate: watchEndDate ? dateToken({ date: watchEndDate, locale }) : undefined,
              occurrences,
            }}
          />
        </Disclaimer.Inline>
      ) : null}

      <div>
        <Button
          className="mr-16"
          data-testid="schedule-button"
          isDisabled={isLoading}
          isLoading={isLoading}
          type="submit"
          variant="primary"
        >
          <FormattedMessage
            id={
              editingTransfer
                ? 'transfers.international-out.schedule-form.button.confirm'
                : 'transfers.international-out.schedule-form.button.schedule'
            }
          />
        </Button>
        {removeSchedule ? (
          <Button data-testid="remove-button" onPress={removeSchedule} variant="secondary">
            <FormattedMessage id="transfers.international-out.schedule-form.button.remove" />
          </Button>
        ) : null}
      </div>
    </Form>
  );
}
