import { Menu, MenuItem, Separator } from 'react-aria-components';
import type { ReactNode } from 'react';
import { clsx } from 'clsx';
import styles from './actions-menu.strict-module.css';

export interface MenuItemType {
  label: string;
  onPress?: () => void;
  testId: string;
  isDestructive?: boolean;
  icon?: ReactNode;
}

export interface ActionsMenuProps {
  firstSection: MenuItemType[];
  secondSection: MenuItemType[];
}

export function ActionsMenu({ firstSection, secondSection }: ActionsMenuProps): ReactNode {
  const showSeparator = firstSection.length > 0 && secondSection.length > 0;

  const renderMenuItem = ({
    label,
    icon,
    onPress,
    testId,
    isDestructive,
  }: MenuItemType): ReactNode => (
    <MenuItem
      className={clsx(styles.menuItem, isDestructive && styles.menuDelete)}
      data-testid={testId}
      key={testId}
      onAction={onPress}
    >
      {icon ? icon : null} <span className={styles.menuItemText}>{label}</span>
    </MenuItem>
  );

  return (
    <Menu className={styles.menu}>
      {firstSection.map(renderMenuItem)}
      {showSeparator ? <Separator className={styles.menuSeparator} /> : null}
      {secondSection.map(renderMenuItem)}
    </Menu>
  );
}
