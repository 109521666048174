import type { ReactElement } from 'react';
import cx from 'clsx';
import type { ButtonProps } from 'react-aria-components';
import { Button } from 'react-aria-components';
import styles from './trigger.strict-module.css';

interface TriggerProps extends ButtonProps {
  isInvalid?: boolean;
  isReadOnly?: boolean;
}

export function Trigger({
  className,
  isInvalid,
  isReadOnly,
  ...props
}: TriggerProps): ReactElement {
  return (
    <Button
      className={cx(styles.trigger, className, isReadOnly && styles['trigger--readonly'])}
      data-invalid={isInvalid}
      data-readonly={isReadOnly}
      {...props}
    />
  );
}
