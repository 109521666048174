export interface TranslationsJson {
  [key: string]: string | TranslationsJson;
}

export function flattenTranslations(
  obj: TranslationsJson,
  prefix = '',
  result: Record<string, string> = {}
): Record<string, string> {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = prefix ? `${prefix}.${key}` : key;
      const value = obj[key];
      if (typeof value === 'object' && !Array.isArray(value)) {
        flattenTranslations(value, newKey, result);
      } else if (typeof value === 'string') {
        result[newKey] = value;
      }
    }
  }
  return result;
}
