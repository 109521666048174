import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import type { BasketHeaderProps } from '../types';
import styles from './basket-header.strict-module.css';

export function BasketHeader({ isPreview = false }: BasketHeaderProps): ReactNode {
  return (
    <thead className={cx(styles['basket-header'], isPreview && styles.preview)}>
      <tr>
        <th className={cx(styles['item-header'], 'body-1')} scope="col">
          <FormattedMessage id="payment-link.itemisation.header.items" />
        </th>
        <th className={cx(styles['quantity-header'], 'body-1')} scope="col">
          <FormattedMessage id="payment-link.itemisation.header.quantity" />
        </th>
        <th className={cx(styles['vat-header'], 'body-1')} scope="col">
          <FormattedMessage id="payment-link.itemisation.header.vat-rate" />
        </th>
        <th className={cx(styles['total-header'], 'body-1')} scope="col">
          <FormattedMessage id="payment-link.itemisation.header.total" />
        </th>
      </tr>
    </thead>
  );
}
