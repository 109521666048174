import { createContext, useContext } from 'react';

export type MfaMethod = 'by_sms' | 'by_email' | 'by_app';

export interface UserData {
  email?: string;
  phoneNumber?: string;
  mfaEnabled?: boolean;
  mfaMethod?: MfaMethod;
}

const UserContext = createContext<UserData | null>(null);

export function useUser(): UserData {
  const user = useContext(UserContext);
  if (!user) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return user;
}

interface UserProviderProps {
  user?: UserData;
  children: React.ReactNode;
}

export function UserProvider({ children, user }: UserProviderProps): React.ReactNode {
  return <UserContext.Provider value={user || {}}>{children}</UserContext.Provider>;
}
