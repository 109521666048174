import type { ReactElement } from 'react';
import type { TabListProps as AriaTabListProps } from 'react-aria-components';
import { TabList as AriaTabList } from 'react-aria-components';
import cx from 'clsx';
import type { TabProps } from '../tab/tab';
import { Tab } from '../tab/tab';
import styles from './tab-list.strict-module.css';

export interface TabListProps extends AriaTabListProps<object> {
  tabs: TabProps[];
}

export function TabList({ tabs, className, ...props }: TabListProps): ReactElement {
  return (
    <AriaTabList className={cx(styles['tab-list'], className)} {...props}>
      {tabs.map(
        ({ id, title, icon, hasNotifications, hasDivider, count, maxCount, isDisabled }, index) => (
          <Tab
            count={count}
            data-test-tabs-nav-item={index}
            data-test-tabs-nav-item-value={id}
            hasDivider={hasDivider}
            hasNotifications={hasNotifications}
            icon={icon}
            id={id}
            isDisabled={isDisabled}
            key={id}
            maxCount={maxCount}
            title={title}
          />
        )
      )}
    </AriaTabList>
  );
}
