import type { ReactNode } from 'react';
import cx from 'clsx';
import styles from './timeline.strict-module.css';
import type { TimelineItemProps } from './internal/timeline-item';
import { TimelineItem } from './internal/timeline-item';
import { LoadingTimelineItem } from './internal/loading-timeline-item';

interface TimelineProps {
  list: TimelineItemProps[];
  showLastLine?: boolean;
  isLoading?: boolean;
  loadingOptions?: {
    itemsCount?: number;
  };
  className?: string;
}

export function Timeline({
  list,
  className,
  showLastLine = false,
  isLoading = false,
  loadingOptions = {},
  ...rest
}: TimelineProps): ReactNode {
  if (isLoading) {
    const { itemsCount = 3 } = loadingOptions;

    return (
      <ol className={cx(styles.timeline, className)} {...rest}>
        {new Array(itemsCount).fill(null).map((_, index) => (
          <LoadingTimelineItem
            data-testid={`loading-timeline-item-${String(index)}`}
            hideLine={index === itemsCount - 1}
            // eslint-disable-next-line react/no-array-index-key -- array is immutable
            key={String(index)}
          />
        ))}
      </ol>
    );
  }

  return (
    <ol
      className={cx(styles.timeline, className)}
      data-test-show-last-line={showLastLine}
      {...rest}
    >
      {list.map((item, index) => (
        <TimelineItem
          data-testid={`timeline-item-${String(index)}`}
          hideLine={index === list.length - 1 && !showLastLine}
          id={item.id}
          key={item.id}
          lineVariant={item.lineVariant}
          slots={item.slots}
        />
      ))}
    </ol>
  );
}
