import { type RefObject, useCallback } from 'react';

interface ChartRef {
  container: HTMLElement;
}

export const useHighlightIndex = (
  chartRef: RefObject<ChartRef>,
  highlightedIndex?: number
): (() => void) => {
  return useCallback((): void => {
    // Get the chart container
    if (!chartRef.current?.container) return;

    const container = chartRef.current.container;

    const barElement = container.querySelectorAll('.recharts-bar-rectangle > rect')[
      highlightedIndex ?? 0
    ];

    let event = null;

    if (barElement) {
      // Get the bounding box of the element
      const rect = barElement.getBoundingClientRect();

      // Create a synthetic mouse event

      event = new MouseEvent('mousemove', {
        bubbles: true,
        cancelable: true,
        clientX: rect.left + rect.width / 2,
        clientY: rect.top + rect.height / 2,
      });
    } else {
      event = new MouseEvent('mousemove', {
        bubbles: true,
        cancelable: true,
        clientX: -1,
        clientY: -1,
      });
    }

    // Avoid race condition between the regular tooltip and the highlighted index tooltip due to trailing pointer
    requestAnimationFrame(() => {
      // Dispatch event to trigger tooltip
      container.dispatchEvent(event);
    });
  }, [chartRef, highlightedIndex]);
};
