import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { DeviceConfirmedSvg } from '../../svgs/device-confirmed';
import { emailVerificationChannel } from '../../utils/broadcast-channel';
import { EmailVerificationGeneric } from './generic';

export function DeviceConfirmed(): React.ReactNode {
  const { formatMessage } = useIntl();
  useEffect(() => {
    emailVerificationChannel.confirmDevice();
  }, []);
  return (
    <EmailVerificationGeneric
      data-testid="device-confirmed"
      illustration={<DeviceConfirmedSvg />}
      subtitle={formatMessage({
        id: 'sca.sensitive-actions.accordion.confirm-device.validate.subtitle',
      })}
      title={formatMessage({
        id: 'sca.sensitive-actions.accordion.confirm-device.validate.title',
      })}
    />
  );
}
