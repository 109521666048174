import { useCallback, useEffect, useMemo, useState, type ReactElement } from 'react';
import type {
  NumberFieldProps as AriaNumberFieldProps,
  ValidationResult,
} from 'react-aria-components';
import { NumberField as AriaNumberField } from 'react-aria-components';
import cx from 'clsx';
import { Description, Error, Input, Label } from '../form-elements';
import styles from './number-field.strict-module.css';

interface NumberFieldProps extends Omit<AriaNumberFieldProps, 'onChange' | 'value'> {
  label: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  placeholder: string;
  value?: number | string | undefined | null;
  formatOptions?: Intl.NumberFormatOptions;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  onFocusChange?: (isFocused: boolean) => void;
  onChange?: (value: string | null) => void;
}

const parseValue = (value: number | string | undefined | null): number => {
  if (value === null || value === undefined) return NaN;
  const numericValue = Number(value);
  return isNaN(numericValue) ? NaN : numericValue;
};

export function NumberField({
  label,
  description,
  errorMessage,
  placeholder,
  className,
  onChange,
  onFocusChange,
  value,
  formatOptions,
  minimumFractionDigits,
  maximumFractionDigits,
  ...props
}: NumberFieldProps): ReactElement {
  // We parse the incoming value to number to ensure internal consistency
  // note: react-aria NumberField uses NaN to represent absence of a valid number.
  const [parsedValue, setParsedValue] = useState<number | undefined>(parseValue(value));

  // Sync the internal parsedValue with the incoming value to handle updates from external sources.
  useEffect(() => {
    setParsedValue(parseValue(value));
  }, [value, formatOptions, minimumFractionDigits, maximumFractionDigits]);

  const handleFocusChange = (isFocused: boolean): void => {
    if (onFocusChange) {
      onFocusChange(isFocused);
    }
  };

  // The handleChange function ensures that the onChange callback is compatible with ember app by converting numbers to strings or using null for absence of a valid number.
  const handleChange = useCallback(
    (numberValue?: number): void => {
      if (typeof numberValue !== 'number' || isNaN(numberValue)) {
        setParsedValue(NaN);
        onChange?.(null);
      } else {
        setParsedValue(numberValue);
        onChange?.(numberValue.toString());
      }
    },
    [onChange]
  );

  const isInvalid = Boolean(errorMessage);

  const memoizedFormatOptions = useMemo(
    () => ({
      minimumFractionDigits,
      maximumFractionDigits,
      ...formatOptions,
    }),
    [formatOptions, minimumFractionDigits, maximumFractionDigits]
  );

  return (
    <AriaNumberField
      className={cx(className, styles.container)}
      formatOptions={memoizedFormatOptions}
      isInvalid={isInvalid}
      onChange={handleChange}
      onFocusChange={handleFocusChange}
      value={parsedValue}
      {...props}
    >
      {Boolean(label) && <Label>{label}</Label>}
      <Input placeholder={placeholder} />
      <Error>{errorMessage}</Error>
      {Boolean(description) && <Description>{description}</Description>}
    </AriaNumberField>
  );
}
