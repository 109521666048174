import type { PropsWithChildren, ReactNode } from 'react';
import cx from 'clsx';
import { Badge, type BadgeType } from '@repo/design-system-kit';
import styles from './section.strict-module.css';

interface SectionProps extends PropsWithChildren {
  action?: ReactNode;
  badgeType?: BadgeType;
  title: string;
}

export function Section({ action, badgeType, children, title }: SectionProps): ReactNode {
  return (
    <section className={styles.section}>
      <div className={cx('mb-16', styles.header)}>
        <div className={styles['title-wrapper']}>
          <h3 className={cx('caption-bold', styles.title)}>{title}</h3>
          {badgeType ? <Badge type={badgeType} /> : null}
        </div>
        {action}
      </div>
      {children}
    </section>
  );
}
