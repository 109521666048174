import type { ReactElement, SVGProps } from 'react';

export function IconLockFilled(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      aria-hidden="true"
      fill="currentColor"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_locked_filled_svg__a)">
        <path d="M13 6h-1V4C12 1.66 9.99-.21 7.61.02 5.53.22 4 2.08 4 4.17V6H2.82c-.45 0-.82.37-.82.82v8.36c0 .45.37.82.82.82H13c.55 0 1-.45 1-1V7c0-.55-.45-1-1-1zm-4.5 6.5c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-2c0-.28.22-.5.5-.5s.5.22.5.5v2zM11 6H5V4c0-1.66 1.34-3 3-3s3 1.34 3 3v2z" />
      </g>
      <defs>
        <clipPath id="icon_locked_filled_svg__a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
