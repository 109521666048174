import { PolymorphicSegmentProvider, type PolymorphicSegment } from '@repo/poly-hooks/segment';
import { analytics } from '../utils/segment';

const segment = {
  track: (...args) => {
    analytics.track(...args);
  },
  identify: (...args) => {
    analytics.identify(...args);
  },
  reset: () => {
    /**/
  },
  group: () => {
    /**/
  },
} satisfies PolymorphicSegment;
export function SegmentProvider({ children }: React.PropsWithChildren): React.ReactNode {
  return <PolymorphicSegmentProvider segment={segment}>{children}</PolymorphicSegmentProvider>;
}
