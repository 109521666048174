import { Popup } from '@repo/design-system-kit';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'clsx';
import { ConfirmDeviceSvg } from '../../../svgs/confirm-device';
import { faqService } from '../../../utils/faq-composer';
import styles from './risky-login.strict-module.css';

interface VerifyDeviceProps {
  onClose?: () => void;
}

export function VerifyDevice({ onClose }: VerifyDeviceProps): React.ReactNode {
  const [isOpen, setIsOpen] = useState(true);
  const { formatMessage } = useIntl();
  const handleClose = (): void => {
    onClose?.();
    setIsOpen(false);
  };

  return (
    <Popup.Root
      aria-label={formatMessage({ id: 'sca.sensitive-actions.accordion.confirm-device.title' })}
      isOpen={isOpen}
      role="dialog"
    >
      <Popup.CloseButton onPress={handleClose} />
      <Popup.Header>
        <figure className={styles.illustrationWrap}>
          <ConfirmDeviceSvg />
        </figure>
        <Popup.Title>
          <FormattedMessage id="sca.sensitive-actions.accordion.confirm-device.title" />
        </Popup.Title>
        <Popup.Body>
          <p className={cx('body-2 ', styles.faqLink)}>
            <FormattedMessage
              id="sca.sensitive-actions.accordion.confirm-device.subtitle"
              values={{
                // eslint-disable-next-line react/no-unstable-nested-components -- This is a valid case since enforce the usage of the <a> tag in react-intl
                a: (chunks: React.ReactNode) => (
                  <a
                    href={faqService.getLocalizedArticle(8925590)}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </p>
        </Popup.Body>
      </Popup.Header>
    </Popup.Root>
  );
}
